import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TeacherDetailComponent } from '../teacher-detail/teacher-detail.component';

@Component({
  selector: 'teacher-list',
  templateUrl: './teacher-list.component.html',
  styleUrls: ['./teacher-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeacherListComponent implements OnInit {
  @Input() teacherList: any;
  @Output() onSaveTeacher = new EventEmitter<any>();

  displayedColumns = [ 'profile-image', 'name', 'email', 'phone_no', 'action' ]

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  viewTeacher(teacher: any) {
    const dialogRef = this.dialog.open(TeacherDetailComponent, {
      data: {
        teacher: teacher
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onSaveTeacher.emit(result);
    });
  }

}
