import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-session-management',
  templateUrl: './session-management.component.html',
  styleUrls: ['./session-management.component.sass']
})
export class SessionManagementComponent implements OnInit {
  selectedCourse: any = null;
  sessions: any[] = [];

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  constructor(
    private layoutServiceService: LayoutServiceService,
    private sessionService: SessionService
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    this.getAllSession();
  }

  changeCourseHandler(courseObj: any) {
    this.selectedCourse = courseObj;

    if (this.selectedCourse != null) {
      this.getAllSession(this.selectedCourse.id);
    } else {
      this.getAllSession();
    }
  }

  getAllSession(courseId?: any) {
    let prepareObj = courseId ? {course_id: courseId} : {};
    
    this.sessionService.getAllSession(prepareObj).subscribe( (data) => {
      this.sessions = data.data;
    })
  }

  addSessionHandler(sessionObj: any) {
    if (this.selectedCourse != null) {
      this.getAllSession(this.selectedCourse.id);
    } else {
      this.getAllSession();
    }
  }

}
