<div class="body d-flex py-lg-3 py-md-2">
  <div class="container-xxl">
    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 mb-4 no-bg">
          <div class="card-header py-3 px-0 d-flex align-items-center  justify-content-between border-bottom">
            <h3 class=" fw-bold flex-fill mb-0" style="color: #000;">Dependents</h3>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row g-3 row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-deck py-3 fixed-height-overflow">
      <div class="col" *ngFor="let dependent of dependents; let i = index;">
        <div class="card teacher-card shadow" style="display: flex;
        align-items: center;">
          <div class="card-body p-4 d-flex" style="width:100%;display: flex;
          align-items: center;justify-content: center;">
            <div class="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 text-center">
              <a>
                <img *ngIf="dependent.profile_image_path" [src]="dependent.profile_image_path" alt=""
                  class="avatar teacher shadow-sm pointer"
                  (click)="sharedService.viewImage(dependent.profile_image_path)">
                <img *ngIf="!dependent.profile_image_path" src="assets/images/default-profile.jpg" alt=""
                  class="avatar teacher shadow-sm">
              </a>
              <div class="details-dep">
                <span class="dep-name"><b>{{ dependent.name }}</b></span>
                <span>{{ dependent.school }}</span>
              </div>
              <div>
                <button class="btn bg-info btn-sm text-white rounded full-width select-button" (click)="selectDependent(i)">
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>