<div fxLayout="column wrap" fxLayoutAlign="space-around start">
  <div class="card-title" fxFlex="100">Change Password</div>

  <div fxFlex="100">
    <form [formGroup]="form" (ngSubmit)="submitHandler()">
      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="plain_password"
            placeholder="First Name" />
          <mat-error *ngIf="f['plain_password'].errors">Minimum 8 characters</mat-error>
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="outline" class="form-field__extend">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="confirm_password" />
          <mat-error *ngIf="f['confirm_password'].errors">Repeat password</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <button type="submit" mat-raised-button class="full-width">
          Update Password
        </button>
      </div>
    </form>
  </div>
</div>