import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { 
    
  }

  getCart(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/cart`, requestObj, this.httpOptions);
  }

  addCart(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/addtocart`, requestObj, this.httpOptions);
  }

  removeCart(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/removecart`, requestObj, this.httpOptions);
  }

  submitCartPurchase(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/addtransaction`, requestObj, this.httpOptions);
  }
}
