import { Component, OnInit } from '@angular/core';
import { LayoutServiceService } from 'src/app/services/layout-service.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public isLoggedIn: boolean = false;
  
  constructor(private layoutServiceService: LayoutServiceService) {
    let stringValue = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false;
    this.isLoggedIn = (stringValue == "true");

    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
        this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
  }

}
