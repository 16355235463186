import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  public signatureEndpoint = '';
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app

  public apiKey = '';
  public apiSecretKey = '';
  public meetingNumber = '';
  public role = '0';
  public leaveUrl = ''; // LAST URL
  public userName = '';
  public userEmail = '';
  public password = '';
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  public registrantToken = '';

  public isLoggedIn: boolean = false;
  public tokens: any = null;
  public dependentTokens: any = null;
  public isLoading: boolean = false;

  constructor() {
    this.apiKey = environment.apiKey;
    this.apiSecretKey = environment.apiSecretKey;
  }
}
