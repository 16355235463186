import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getAllUser(object?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/user`, object, this.httpOptions);
  }

  getUserByType(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/user`, reqBody, this.httpOptions);
  }

  saveUser(reqBody: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/save-user`,
      reqBody,
      this.httpOptions
    );
  }

  loginUser(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/login`, reqBody, this.httpOptions);
  }

  loginAdmin(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/login-admin`, reqBody, this.httpOptions);
  }
  
  checkEmailExist(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/check-email`, reqBody, this.httpOptions);
  }

  checkTokenExpiry(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/check-token`, reqBody, this.httpOptions);
  }

  saveForgotPassword(reqBody: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/forgot-password`,
      reqBody,
      this.httpOptions
    );
  }

  updatePassword(reqBody: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/update-password`,
      reqBody,
      this.httpOptions
    );
  }

  updateAccessToken(reqBody: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/update-token`,
      reqBody,
      this.httpOptions
    );
  }

  validateAccessToken(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/validate-access-token`, reqBody, this.httpOptions);
  }

  removeUser(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/remove-user`, reqBody, this.httpOptions);
  }
}
