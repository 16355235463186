import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../services/global.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  levels: any = [];
  sublevels: any = [];

  public subscriptionHeaderTitle: any = new BehaviorSubject(null);
  public subscriptionRefreshContent: any = new Subject();

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) {
    // if( this.globalService.isLoggedIn == true ){
    //   this.setLevels();
    // }
  }

  showSnackBar(text?: any) {
    this.snackBar.open(text, 'dismiss', { duration: 3000 });
  }

  getCourseLevel(): Observable<any> {
    return this.http.post(`${environment.apiURL}/course-level`, this.httpOptions);
  }

  getCourseSubLevel(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/course-sublevel`, reqBody, this.httpOptions);
  }

  getCourseTypes(): Observable<any> {
    return this.http.post(`${environment.apiURL}/course-types`, this.httpOptions);
  }

  getCurrentUser(): any {
    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    return requested_by;
  }

  // setLevels(): any {
  //   this.getCourseLevel().subscribe( (data) => {
  //     this.levels = data[0];
  //   })
  //   this.getCourseSubLevel({level_id: null}).subscribe( (data) => {
  //     this.sublevels = data[0];
  //   })
  // }

  getLevels(): any {
    return this.levels;
  }

  getSublevels(): any {
    return this.sublevels;
  }

  setSubscriptionHeaderTitle(pageTitle: any) {
    this.subscriptionHeaderTitle.next(pageTitle);
  }

  getSubscriptionHeaderTitle() {
    return this.subscriptionHeaderTitle;
  }

  setSubscriptionRefreshContent(refresh: any) {
    this.subscriptionRefreshContent.next(refresh);
  }

  getSubscriptionRefreshContent() {
    return this.subscriptionRefreshContent;
  }

  viewImage(imagePath: any, fileMime: any = null) {

    if(fileMime){
      let splitArray = fileMime.split('/');
      if( splitArray[0] == "application" ){
        window.open(imagePath,"_blank");
        return;
      }
    }
    
    if( !imagePath ){
      return;
    }
    
    const dialogRef = this.dialog.open(ImageViewerComponent, {
      data: {
        imagePath: imagePath
      },
      panelClass: 'image-viewer__panel'
    });
  }
}
