<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

<div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar *ngIf="isLoggedIn"></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
        <!-- Body: Header -->
        <app-header *ngIf="isLoggedIn"></app-header>

        <nav *ngIf="!isLoggedIn" class="navbar">
            <a class="navbar-brand back-button" [routerLink]="'/auth/sign-in'"><i class="icofont-square-left fs-5"></i>&nbsp;&nbsp;Back</a>
        </nav>

        <!-- Body: Body -->
        <router-outlet></router-outlet>
    </div>

</div>
