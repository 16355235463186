import { Directive } from '@angular/core';

@Directive({
  selector: '[appShared]'
})
export class SharedDirective {

  constructor() { }

}
