import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { FileUpload } from '../../../file-upload/models/file-upload.model';
import { NgProgressComponent } from 'ngx-progressbar';
import * as moment from 'moment-timezone';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from '../../../shared/services/shared.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrls: ['./content-form.component.scss']
})
export class ContentFormComponent implements OnInit {

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  @Output() sendContent = new EventEmitter<Object>();
  public typeName: any = '';

  public contentId: any = null;
  public contentFile: any = null;

  public form: FormGroup = new FormGroup({
    contentName: new FormControl(''),
    contentDesc: new FormControl(''),
    typeName: new FormControl(''),
    contentDate: new FormControl(''),
    contentStartDateTime: new FormControl(''),
    contentEndDateTime: new FormControl(''),
    isImportant: new FormControl('')
  });

  public actualPercentage: any = 0;
  public lastPercentage: any = 0;
  public currentIndex: any = 0;
  public count: any = 1;
  public stopContent: boolean = false;

  public isSubmit: boolean = false;
  public selectedFiles: any = []; //STORE SELECTED FILES
  public currentFileContent: FileUpload;
  public uploadedFile: any = []; //GET FILE STORED IN DB
  public deletedFile: any = []; //STORE FILE TO BE DELETED
  public percentage: number = 0;
  public stopUpload: boolean = false;

  public selectedDate: any = null;
  public selectedTypeId: any = null;

  public userDetail = <String>localStorage.getItem("userDetail");
  public requested_by = this.userDetail ? JSON.parse(this.userDetail.toString()) : null;

  public basePath = 'content-info';
  public subscriptionCloseModal: Subscription;
  public loading: boolean = false;

  constructor(
    private fileUploadService: FileUploadService,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ContentFormComponent>,
    private sanitizer: DomSanitizer
  ) {
    this.typeName = this.data.typeName;
  }

  ngOnInit() {
    this.fileUploadService.setSubscriptionCloseModal(false);
    this.subscriptionCloseModal = this.fileUploadService.getSubscriptionCloseModal().subscribe((value: any) => {
      if (value) {
        this.close(value);
      }
    }, (error: any) => {

    });
    this.form = this.formBuilder.group({
      contentName: [null, [Validators.required]],
      contentDesc: [null],
      typeName: [this.typeName, [Validators.required]],
      contentDate: [null, [Validators.required]],
      contentStartDateTime: [null],
      contentEndDateTime: [null],
      isImportant: [false]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  selectFile(event: any): void {
    this.selectedFiles = Array.from(event.target.files);
    if (this.selectedFiles) {
      this.deleteFile();
    }
    this.contentFile = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(event.target.files[0])
    );
    this.stopUpload = false;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      let currentdatetime = moment().utc().format('YYYYMMDDHHmmss');
      this.selectedFiles[i] = new File([this.selectedFiles[i]], `${currentdatetime}_${this.selectedFiles[i].name}`, { type: this.selectedFiles[i].type });
    }
  }

  submitHandler() {
    this.isSubmit = true;
    let formValue = this.form.value;
    this.loading = true;
    if (this.form.valid) {
      
      let contentStartDateTimeUTC = null;
      let contentEndDateTimeUTC = null;

      if (
        formValue.contentStartDateTime &&
        formValue.contentEndDateTime
      ) {
        contentStartDateTimeUTC = moment(formValue.contentStartDateTime).utc().format('YYYY-MM-DD HH:mm:ss');
        contentEndDateTimeUTC = moment(formValue.contentEndDateTime).utc().format('YYYY-MM-DD HH:mm:ss');
      }

      let payloadObject = {
        contentId: '',
        contentName: formValue.contentName,
        contentDesc: formValue.contentDesc,
        typeName: formValue.typeName,
        contentDate: formValue.contentDate,
        contentStartDateTime: contentStartDateTimeUTC,
        contentEndDateTime: contentEndDateTimeUTC,
        contentFileName: null,
        contentFileSize: null,
        contentFileBasePath: this.basePath,
        contentFilePath: null,
        contentFileMime: null,
        requesterId: this.sharedService.getCurrentUser().id,
        updateFile: false,
        isImportant: formValue.isImportant
      }

      if (
        this.uploadedFile &&
        this.uploadedFile.name
      ) {
        payloadObject.contentFileName = this.uploadedFile.name;
        payloadObject.contentFilePath = this.uploadedFile.path;
        payloadObject.contentFileMime = this.uploadedFile.mime;
        payloadObject.contentFileSize = this.uploadedFile.size;
        payloadObject.updateFile = false;
      }

      if (
        this.deletedFile &&
        this.deletedFile[0] &&
        this.deletedFile[0].name
      ) {
        payloadObject.contentFileName = null;
        payloadObject.contentFilePath = null;
        payloadObject.contentFileMime = null;
        payloadObject.contentFileSize = null;
        payloadObject.updateFile = true;
        this.fileUploadService.deleteFileStorage(this.deletedFile[0].name, this.basePath);
      }

      if (
        this.selectedFiles &&
        this.selectedFiles.length > 0
      ) {
        this.upload(payloadObject);
      } else {
        this.fileUploadService.manageContent(null, null, payloadObject, this.loading);
      }
    } else {
      this.loading = false;
    }
  }

  upload(payloadObject: any): void {
    if (this.form.valid) {
      this.isSubmit = true;

      if (this.stopUpload == false) {
        const totalFilesUpload = this.selectedFiles.length;
        const file: any = this.selectedFiles[this.currentIndex];
        this.currentFileContent = new FileUpload(file);
        let basePath = this.basePath;
        let subscribe = this.fileUploadService.pushFileToStorage(
          this.currentFileContent,
          basePath,
          null,
          null,
          payloadObject,
          null,
          null,
          null,
          this.loading
        ).subscribe(
          percentage => {
            this.percentage = (this.actualPercentage + Math.round(percentage)) / totalFilesUpload;

            if (
              percentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = percentage;
              this.progressBar.set(this.percentage);
            }

            if (
              percentage == 100 &&
              this.lastPercentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = 100;
              this.actualPercentage += 100;
              if (this.count == totalFilesUpload) {
                this.stopUpload = true;
                this.selectedFiles = null;
                this.count = 1;
                this.currentIndex = 0;
                this.actualPercentage = 0;
                this.percentage = 0;
                this.progressBar.complete();
                this.isSubmit = false;
              } else {
                this.currentIndex++;
                this.count++;
                subscribe.unsubscribe();
              }
            }
          },
          error => {
            this.loading = false;
            console.log(error);
          }
        );
      }
    } else {
      this.loading = false;
    }
  }

  removeFile(i: any) {
    this.selectedFiles.splice(i, 1);
    if (this.selectedFiles.length == 0) {
      this.selectedFiles = [];
      this.contentFile = 'assets/images/default-image.png';
    }
  }

  trimFileName(fileName: any) {
    return fileName.substr(15);
  }

  deleteFile() {
    if (
      this.uploadedFile &&
      this.uploadedFile.base_path == this.basePath
    ) {
      this.deletedFile.push(this.uploadedFile);
      this.uploadedFile = [];
      if (this.selectedFiles.length == 0) {
        this.selectedFiles = undefined;
      }
    }
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }
}
