<div class="overflow-custom">
  <div fxLayout="columns wrap" fxLayoutAlign="space-around center" *ngIf="!selectedDependent">
    <div class="content full-width dependent-card">
      <h1>Choose Account</h1>
    </div>
    <div class="custom-card dependent-card" (click)="checkPasscode(requested_by)">
      <img *ngIf="requested_by.profile_image_path" class="dependent__image profile-picture shadow" [src]="requested_by.profile_image_path" />
      <img *ngIf="!requested_by.profile_image_path" class="dependent__image profile-picture shadow" src="assets/images/default-profile.jpg" />
      <div class="full-width">
        <span class="dependent__name">{{requested_by.name_first.concat(" ", requested_by.name_last)}} (Parent)</span>
      </div>
    </div>
    <div *ngFor="let dependent of dependents" class="custom-card dependent-card"
      (click)="checkPasscode(dependent)">
      <img class="dependent__image profile-picture" src="assets/images/default-profile.jpg" />
      <div class="full-width">
        <span class="dependent__name">{{dependent.name}}</span>
      </div>
    </div>
  </div>

  <!-- <div fxLayout="columns wrap" fxLayoutAlign="space-around center" *ngIf="selectedDependent">
    <div fxFlex="100" class="dependent-card text-with-icon" (click)="selectedDependent = null; passcode = null">
      <mat-icon>keyboard_arrow_left</mat-icon> Cancel
      <h1 style="margin: auto;">Enter Passcode</h1>
    </div>

    <div fxFlex="100" class="dependent-card text-with-icon">
      <div class="form-group full-width">
        <div class="input-code-wrapper">
          <code-input [isCodeHidden]="true" [codeLength]="6" [initialFocusField]="0" [isFocusingOnLastByClickIfFilled]="true" (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>
      </div>
    </div>

    <div fxFlex="100" class="dependent-card text-with-icon" *ngIf="isWrongPasscode">
      <div class="full-width">
        <span style="color: brown;">Passcode is incorrect</span>
      </div>
    </div>

    <div fxFlex="100" class="dependent-card text-with-icon">
      <div class="form-group full-width">
        <div class="input-code-wrapper">
          <button mat-raised-button  class="full-width" (click)="checkPasscode()">Confirm</button>
        </div>
      </div>
    </div>
  </div> -->
</div>