import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartProcessComponent } from './pages/cart-process/cart-process.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  {path: 'cart/cart-process', component: CartProcessComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartRoutingModule { }
