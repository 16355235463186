import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileUploadRoutingModule } from './file-upload-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UploadFormComponent } from './components/upload-form/upload-form.component';
import { UploadListComponent } from './components/upload-list/upload-list.component';
import { UploadManagementComponent } from './pages/upload-management/upload-management.component';
import { UploadDetailsComponent } from './components/upload-details/upload-details.component';
import { MatTableModule } from '@angular/material/table';
import { NgProgressModule } from 'ngx-progressbar';
import { AdminModule } from '../../admin/admin.module';
import { NgxLoadingModule } from "ngx-loading";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  declarations: [
    UploadFormComponent,
    UploadListComponent,
    UploadDetailsComponent,
    UploadManagementComponent
  ],
  imports: [
    CommonModule,
    FileUploadRoutingModule,
    SharedModule,
    MatCardModule,
    MatTabsModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    NgProgressModule,
    AdminModule,
    NgxLoadingModule.forRoot({}),
    MatPaginatorModule,
    MatTableExporterModule
  ],
  exports: [
    UploadDetailsComponent,
  ]
})
export class FileUploadModule { }
