import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { TeacherProfileComponent } from '../../components/teacher-profile/teacher-profile.component';
import { TeacherService } from '../../services/teacher.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-teacher-showcase',
  templateUrl: './teacher-showcase.component.html',
  styleUrls: ['./teacher-showcase.component.scss']
})
export class TeacherShowcaseComponent implements OnInit {
  teachers: any[] = [];

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  constructor(private layoutServiceService: LayoutServiceService, private teacherService: TeacherService, public sharedService: SharedService, private dialog: MatDialog) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
        this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
    this.sharedService.setSubscriptionHeaderTitle('Teachers');
  }

  ngOnInit(): void {
    let prepareObj = {
      id: null
    }
    this.teacherService.getTeacher(prepareObj).subscribe( (data) => {
      this.teachers = data.data;
    })
  }

  showProfile(teacher: any) {
    const dialogRef = this.dialog.open(TeacherProfileComponent, {
      data: {
        teacher: teacher
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.onAddSessionEvent.emit(result);
    });
  }

}
