<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div class="body d-flex py-lg-3 py-md-2">
          <div class="container-xxl" style="min-height: 600px;">
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0 mb-4 no-bg">
                  <div class="card-header py-3 px-0 d-flex align-items-center  justify-content-between border-bottom">
                    <h3 class=" fw-bold flex-fill mb-0"
                      *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'">Card Enrollment</h3>
                    <h3 class=" fw-bold flex-fill mb-0" *ngIf="currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER'">Card Enrollment</h3>
                  </div>
                  <!-- <button mat-fab  class="dependent__add-icon" >
                    <mat-icon class="dependent__add-text" >add</mat-icon>
                  </button> -->
                </div>
              </div>
            </div>
            <div class="row g-3 row-cols-4 row-cols-4 row-cols-md-4 row-cols-lg-4 row-cols-xl-3 row-deck py-3"
              *ngIf="currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER'">
              <div class="col" (click)="addDependent()" style="min-width: 200px;">
                <div class="card">
                  <div class="card-body d-flex" style="justify-content: center;">
                    <div class="profile-teacher text-center w220" style="display: flex;
                    align-items: center;
                    justify-content: center;">
                      <img class="pointer" src="assets/icons/dependents.svg" height="100" width="100" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col" *ngFor="let dependent of dependents" style="min-width: 200px;">
                <div class="card teacher-card shadow pointer" (click)="viewDependent(dependent)">
                  <div class="card-body d-flex" style="justify-content: center;">
                    <div class="profile-teacher text-center w220">
                      <a>
                        <img *ngIf="dependent.profile_image_path" class="avatar xl rounded-circle img-thumbnail shadow-sm"
                          [src]="dependent.profile_image_path" alt="profile">
                        <img *ngIf="!dependent.profile_image_path" class="avatar xl rounded-circle img-thumbnail shadow-sm"
                          src="assets/images/default-profile.jpg" alt="profile">
                      </a>
                      <a class="mb-0 mt-2 fw-bold d-block fs-6">{{dependent.name}}</a>
                      <a class="mb-0 mt-2 d-block fs-6">{{dependent.dependentId}}</a>
                      <div class="pill-global"
                      [ngClass]="{
                        'pill-rejected': dependent?.card_status == 'NOT ASSIGNED',
                        'pill-approved': dependent?.card_status == 'APPROVED',
                        'pill-pending': dependent?.card_status == 'MISSING'
                      }"
                      style="width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;">{{ dependent?.card_status }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-card" *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'">
              <div class="main-filter" *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'">
                <div class="sub-filter-1">
                  <div class="label-input">
                    <label>Card Status</label>
                    <select [(ngModel)]="filterStatus" (change)="filterTable()" class="form-select pointer custom-select">
                        <option value="">All</option>
                        <option value="12">Assigned</option>
                        <option value="13">Not Assigned</option>
                        <option value="11">Missing</option>
                    </select>
                  </div>
                  <div class="label-input">
                    <label>School</label>
                    <select [(ngModel)]="school" aria-placeholder="Choose School" class="form-select pointer custom-select" (change)="getClass()">
                      <option value="">All</option>
                      <option *ngFor="let school of schools" [value]="school['school_id']">
                        {{ school['school_name'] }}
                      </option>
                    </select>
                  </div>
                  <div class="label-input">
                    <label>Class</label>
                    <select [(ngModel)]="filterClass" aria-placeholder="Choose Class" class="form-select pointer custom-select">
                      <option value="">All</option>
                      <option *ngFor="let class of classes" [value]="class['class_id']">
                        {{ class['class_name'] }}
                      </option>
                    </select>
                  </div>
                  <div class="label-input flex-custom">
                    <label>Search</label>
                    <input [(ngModel)]="searchText" (keyup)="search($event)" type="text">
                  </div>
                  <div style="display: flex;
                  align-items: end;">
                      <button type="submit" (click)="filterTable()" class="filter"><i class="icofont-filter file-fixed-size excel"></i>Filter</button>
                  </div>
                  <div style="display: flex;
                  align-items: end;">
                      <button (click)="handleExport()" *ngIf="currentUserRole != 'PARENTS' && currentUserRole != 'TEACHER'"><i class="icofont-file-excel file-fixed-size excel"></i> Export to Excel</button>
                  </div>
                </div>
              </div>
              <table class="cart-table" *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'">
                <tr>
                  <th> ID </th>
                  <th> Name </th>
                  <th> School </th>
                  <th> Class </th>
                  <th> Parent Phone No. </th>
                  <th> Status </th>
                  <th> Manage </th>
                </tr>
                <tr *ngFor="let dependent of dependents" (click)="viewDependent(dependent)">
                  <td> {{dependent?.dependentId}} </td>
                  <td> {{dependent?.name}} </td>
                  <td> {{dependent?.school}} </td>
                  <td> {{dependent?.class_name}} </td>
                  <td> {{dependent?.phone_no}} </td>
                  <td>
                    <div class="pill-global"
                    [ngClass]="{
                      'pill-rejected': dependent?.card_status == 'NOT ASSIGNED',
                      'pill-approved': dependent?.card_status == 'APPROVED',
                      'pill-pending': dependent?.card_status == 'MISSING'
                    }"
                    style="width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;">{{ dependent?.card_status }}</div>
                  </td>
                  <td *ngIf="!dependent?.card_no">
                    <button (click)="assignCard(dependent?.name, dependent?.parent_id, dependent?.id, $event)"
                      class="btn bg-warning btn-sm text-white" title="delete-button"><i class="icofont-card fs-6"></i>
                      Assign Card </button>
                      <button style="margin-left: 5px;" (click)="downloadImage(dependent, $event)"
                    class="btn btn-sm text-white pill-download" title="download-button"><i class="icofont-download fs-6"></i> Download Image </button>
                  </td>
                  <td *ngIf="dependent?.card_no">
                    <!-- <button (click)="openAction(dependent?.name, dependent?.parent_id, dependent?.id, $event)"
                      class="btn bg-primary btn-sm text-white" title="action-button"><i class="icofont-card fs-6"></i>Action</button> -->
                    <button (click)="assignNewCard(dependent?.name, dependent?.parent_id, dependent?.id, $event)"
                      class="btn bg-primary btn-sm text-white" title="delete-button"><i class="icofont-card fs-6"></i> New
                      Card </button>
                    <button (click)="replaceDamagedCard(dependent?.name, dependent?.parent_id, dependent?.id, $event)"
                      class="btn bg-primary btn-sm text-white" title="delete-button"><i class="icofont-card fs-6"></i> New
                      Card (Damaged)</button>
                    <button style="margin-left: 5px;" (click)="downloadImage(dependent, $event)"
                    class="btn btn-sm text-white pill-download" title="download-button"><i class="icofont-download fs-6"></i> Download Image </button>
                  </td>
                </tr>
              </table>
              <mat-paginator 
                (page)="handlePageEvent($event)" 
                [length]="totalResult" 
                [pageSize]="limit"
                [showFirstLastButtons]="true" 
                [pageSizeOptions]="getPageSizeOptions()" 
                [pageIndex]="pageIndex"
                aria-label="Select page"
                #paginator *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'">
              </mat-paginator>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>
</body>