import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CartListComponent } from '../../../cart/components/cart-list/cart-list.component';
import { CartHandlerService } from '../../../cart/services/cart-handler.service';
import { CartService } from '../../../cart/services/cart.service';
import { SharedService } from '../../../shared/services/shared.service';
import { PackageService } from '../../services/package.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransactionService } from '../../../transaction/services/transaction.service';
import { PendingTransactionComponent } from '../../../transaction/components/pending-transaction/pending-transaction.component';
import { MatTabGroup } from '@angular/material/tabs';
import * as Moment from "moment-timezone";
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
import { SessionService } from '../../../session/services/session.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-package-store',
  templateUrl: './package-store.component.html',
  styleUrls: ['./package-store.component.scss'],
})
export class PackageStoreComponent implements OnInit {
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  cart: any[] = [];
  packages: any[] = [];
  packagesSubscribed: any[] = [];
  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();
  public pendingTransaction: any = {};
  public timezone: any = null;
  public overlapScheduleObj: any = null;

  public limit: number = 30;
  public defaultLimit: number = 30;
  public offset: number = 0;
  public pageIndex: number = 0;
  public prevPage: number = 0;
  public totalResult: number = 0;
  public pageSizeOptions = [10, 20, 30, 50];
  public totalSubscribed: number = 0;
  public totalNonSubscribed: number = 0;
  public firstTimeLoad: boolean = false;

  constructor(
    private layoutServiceService: LayoutServiceService,
    public dialog: MatDialog,
    private packageService: PackageService,
    private cartService: CartService,
    private sharedService: SharedService,
    private cartHandler: CartHandlerService,
    private transactionService: TransactionService,
    private sessionService: SessionService
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
    // this.sharedService.setSubscriptionHeaderTitle('Packages');
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngAfterViewInit() {
    let userDetail = <String>localStorage.getItem('userDetail') ? JSON.parse(<string>localStorage.getItem('userDetail')) : null;
    let dependentDetail = localStorage.getItem('dependentDetail') ? JSON.parse(<string>localStorage.getItem('dependentDetail')) : null;

    if (
      userDetail.usertype_name == "PARENTS" &&
      dependentDetail == null
    ) {
      this.tabGroup.selectedIndex = 1;
    }
  }

  ngOnInit(): void {
    this.getCart();
    this.getPackages();
    this.getPendingTransaction();

    this.cartHandler.clearSelectedItems();
  }

  addToCartHandler(packageObj: any) {
    let prepareObj = {
      user_id: this.sharedService.getCurrentUser().id,
      package_id: packageObj.id
    }

    let isOverlapSchedule = false;

    const days = packageObj.sessionDays;
    const startDate = moment.utc(packageObj.start_date).tz(this.timezone).format('YYYY-MM-DD');
    const dueDate = moment.utc(packageObj.due_date).tz(this.timezone).format('YYYY-MM-DD');
    const courseSubLevel = packageObj.sublevel;

    const userDetail = <String>localStorage.getItem('userDetail') ? JSON.parse(<string>localStorage.getItem('userDetail')) : null;

    const sessionObject = {
      userId: userDetail.id,
      courseSubLevel: courseSubLevel,
      startDate: startDate,
      endDate: dueDate,
      days: days
    }

    this.sessionService.getOverlapSubscribedSession(sessionObject).subscribe((sessionResponse) => {
      const overlapSessions = sessionResponse.data;

      if (
        overlapSessions &&
        overlapSessions.length > 0
      ) {
        let overlapDays = [];
        for (let i = 0; i < overlapSessions.length; i++) {
          overlapDays.push(overlapSessions[i].sessDay);
        }

        const sessionObject2 = {
          package_id: packageObj.id,
          days: overlapDays.join(',')
        }

        this.sessionService.getAllSession(sessionObject2).subscribe((response) => {
          const packageSessions = response.data;

          let overlapPackage = [];

          if (
            packageSessions &&
            packageSessions.length > 0
          ) {
            for (let i = 0; i < packageSessions.length; i++) {

              const startTime = moment.utc(`${startDate} ${packageSessions[i].start_time}`).tz(this.timezone).format('HH:mm');
              const endTime = moment.utc(`${dueDate} ${packageSessions[i].end_time}`).tz(this.timezone).format('HH:mm');
              const range1 = moment.range(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'));

              for (let j = 0; j < overlapSessions.length; j++) {
                const overlapStartTime = moment.utc(`${startDate} ${overlapSessions[j].startTime}`).tz(this.timezone).format('HH:mm');
                const overlapEndTime = moment.utc(`${dueDate} ${overlapSessions[j].endTime}`).tz(this.timezone).format('HH:mm');
                const range2 = moment.range(moment(overlapStartTime, 'HH:mm'), moment(overlapEndTime, 'HH:mm'));

                if (range1.overlaps(range2)) {
                  isOverlapSchedule = true;
                  overlapPackage.push({
                    currentPackageId: packageObj.id,
                    packageName: overlapSessions[j].packageTitle,
                    startTime: overlapStartTime[j],
                    endTime: overlapEndTime[j],
                    isSubscribed: overlapSessions[j].isSubscribed && overlapSessions[j].isSubscribed == 1 ? true : false
                  });
                  break;
                } else if (range1.adjacent(range2)) {
                  isOverlapSchedule = true;
                  overlapPackage.push({
                    currentPackageId: packageObj.id,
                    packageName: overlapSessions[j].packageTitle,
                    startTime: overlapStartTime[j],
                    endTime: overlapEndTime[j],
                    isSubscribed: overlapSessions[j].isSubscribed && overlapSessions[j].isSubscribed == 1 ? true : false
                  });
                  break;
                }
              }
            }
          }

          if (isOverlapSchedule) {
            this.overlapScheduleObj = overlapPackage;
            this.cartService.addCart(prepareObj).subscribe((data) => {

              this.getCart();
              this.getPackages();
              this.getPendingTransaction();
            }, (error) => {

            });
          } else {
            this.cartService.addCart(prepareObj).subscribe((data) => {

              this.getCart();
              this.getPackages();
              this.getPendingTransaction();
            }, (error) => {

            });
          }

        }, (error) => {

        });
      } else {
        if (isOverlapSchedule) {
          this.cartService.addCart(prepareObj).subscribe((data) => {

            this.getCart();
            this.getPackages();
            this.getPendingTransaction();
          }, (error) => {

          });
        } else {
          this.cartService.addCart(prepareObj).subscribe((data) => {

            this.getCart();
            this.getPackages();
            this.getPendingTransaction();
          }, (error) => {

          });
        }
      }

    }, (error) => {

    });
  }

  viewCart() {
    const dialogRef = this.dialog.open(CartListComponent, {
      data: { carts: this.cart },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCart();
      this.getPackages();
      this.getPendingTransaction();
    });
  }

  openPayNow() {
    const dialogRef = this.dialog.open(PendingTransactionComponent, {
      data: { pendingTransaction: this.pendingTransaction },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCart();
      this.getPackages();
      this.getPendingTransaction();
    });
  }

  getPackages(tabIndex?: number) {
    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    let prepareObj = {
      limit: this.limit,
      offset: this.offset,
      user_id: requested_by.id,
      sublevel_id: parseInt(<string>localStorage.getItem('global_sublevel'))
    }

    return this.packageService.getStorePackage(prepareObj).subscribe((data) => {
      this.packages = data.data[1];
      this.packagesSubscribed = data.data[0];

      this.totalSubscribed = data.data[2][0].totalSubscribed;
      this.totalNonSubscribed = data.data[3][0].totalAvailable;

      if( 
        this.offset == 0 &&
        this.defaultLimit == this.limit &&
        this.prevPage == this.pageIndex
      ) {
        if (tabIndex == 1) {
          this.totalResult = this.totalNonSubscribed;
        } else {
          this.totalResult = this.totalSubscribed;
        }
      }
      
      this.prevPage = this.pageIndex;
      this.defaultLimit = this.limit;
      
    });
  }

  getPendingTransaction() {
    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    let prepareObj = {
      user_id: requested_by.id,
      sublevel_id: parseInt(<string>localStorage.getItem('global_sublevel'))
    }

    return this.transactionService.getPendingTransaction(prepareObj).subscribe((response) => {
      this.pendingTransaction = response.data[0];
    }, (error) => {

    });
  }

  getCart() {
    let prepareObj = {
      user_id: this.sharedService.getCurrentUser().id,
      status: '2',
      sublevel_id: parseInt(<string>localStorage.getItem('global_sublevel'))
    }
    return this.cartService.getCart(prepareObj).subscribe((data) => {
      this.cart = data.data;
    });
  }

  getTotalPendingTransation() {
    let validateType = typeof this.pendingTransaction;
    let total = 0;

    if (validateType == 'object') {
      total = Object.keys(this.pendingTransaction).length;
    } else {
      total = this.pendingTransaction.length;
    }

    return total;
  }

  handlePageEvent(event: PageEvent) {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * this.limit;
    this.getPackages();
  }

  resetPackages(event: any) {
    if (this.firstTimeLoad == false) {
      this.limit = 30;
      this.defaultLimit = 30;
      this.pageIndex = 0;
      this.prevPage = 0;
      this.offset = 0;

      let tabIndex = event.index;

      if (tabIndex == 1) {
        this.totalResult = this.totalNonSubscribed;
      } else {
        this.totalResult = this.totalSubscribed;
      }

      this.getPackages(tabIndex);
    } else {
      this.firstTimeLoad = true;
    }
  }
}
