<div class="body d-flex py-lg-3 py-md-2">
  <div class="container-xxl">
    <div class="row g-3" class="web-view">
      <div class="col-xl-8 col-lg-12 col-md-12">
        <div class="card teacher-card  mb-3">
          <div class="card-body p-4 d-flex teacher-fulldeatil">
            <div class="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center" style="width: 100%">
              <a>
                <img [src]="teacherProfileImage" (click)="sharedService.viewImage(teacherProfileImage)" alt="" class="avatar teacher-full shadow-sm">
              </a>
              <ul class="social mb-0 list-inline d-flex mt-3">
                <!-- <li><a href="#" class="avatar btn btn-link"><i class="fa fa-facebook-f"></i></a></li>
                <li><a href="#" class="avatar btn btn-link"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#" class="avatar btn btn-link"><i class="fa fa-instagram"></i></a></li>
                <li><a href="#" class="avatar btn btn-link"><i class="fa fa-linkedin"></i></a></li> -->
              </ul>
            </div>
            <div class="teacher-info border-start ps-xl-4 ps-md-2 ps-sm-4 ps-4 w-100">
              <h6 class="mb-0 mt-2  fw-bold">{{ teacherObj.name_first }} {{ teacherObj.name_last }}</h6>
              <span class="text-uppercase small text-muted">{{ teacherObj.subject }}</span>
              <div class="video-setting-icon mt-3 pt-3 border-top" fxLayout="column">
                <div fxFlex="100" class="content__others">
                  <div fxFlex fxFill fxLayout="column" fxLayoutAlign="start">
                    <div fxFlex="100">
                      <b class="topic">Teaching Experience</b>
                    </div>
                    <br/>
                    <div fxFlex="100">
                      {{ teacherObj.total_experience }} Years
                    </div>
                  </div>
                </div>
                <div fxFlex="100" class="content__others">
                  <div fxFlex fxFill fxLayout="column" fxLayoutAlign="start">
                    <div fxFlex="100">
                      <b class="topic">Qualification</b>
                    </div>
                    <br/>
                    <div fxFlex="100">
                      {{ teacherObj.qualification }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h6 class="mb-0 fw-bold  py-3">{{ teacherObj.name_first }} {{ teacherObj.name_last }} Profile Video </h6>
        <div class="teachercourse-list mb-3">
          <video width='100%' height='315' [src]='teacherProfileVideo' controlsList="nodownload" controls loop muted
            playsinline></video>
        </div>
      </div>
      <div class="col-xl-4 col-lg-8 col-md-12">
        <h6 class="mb-0 fw-bold  py-3">{{ teacherObj.name_first }} {{ teacherObj.name_last }} Best Achievement</h6>
        <div class="teacherachevemnet-list">
          <div class="row g-3">
            <div class="col-lg-12" *ngFor="let achievement of achievements">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar lg rounded-circle no-thumbnail bg-lavender-purple text-light"><i
                        class="icofont-badge fs-4"></i></div>
                    <div class="flex-fill ms-3">
                      <p class="mb-0 ">{{achievement.achievement}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-3" *ngIf="achievements.length == 0"><i>No achievement</i></div>
        </div>
        <h6 class="mb-0 fw-bold  py-3">{{ teacherObj.name_first }} {{ teacherObj.name_last }} Best Experience</h6>
        <div class="teacherachevemnet-list">
          <div class="row g-3">
            <div class="col-lg-12" *ngFor="let experience of experiences">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="avatar lg rounded-circle no-thumbnail light-success-bg text-light"><i
                        class="icofont-award fs-5"></i></div>
                    <div class="flex-fill ms-3">
                      <p class="mb-0 ">{{experience.experience}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-3" *ngIf="experiences.length == 0"><i>No experiences</i></div>
        </div>
      </div>
    </div>

    <div class="mobile-view">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="100">
          <a>
            <img [src]="teacherProfileImage" (click)="sharedService.viewImage(teacherProfileImage)" alt="" class="avatar teacher-full shadow-sm">
          </a>
        </div>
        <div fxFlex="100" style="text-align: center; margin-bottom: 15px">
          <span class="name">{{ teacherObj.name_first.concat(" ", teacherObj.name_last) }}</span>
          <span class="text-uppercase small text-muted">{{ teacherObj.subject }}</span>
        </div>
        <div fxFlex="100" class="txt-centered">
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxFlex="100" class="subtitle__box">
              <span class="topic sub-title">Teaching Experience</span><br/>
              <span>{{ teacherObj.total_experience }} Years</span>
            </div>
            <div fxFlex="100" class="subtitle__box">
              <span class="topic sub-title">Qualification</span><br/>
              <span>{{ teacherObj.qualification == "null" ? "No qualification set" : teacherObj.qualification }}</span>
            </div>
            <div fxFlex="100" class="subtitle__box">
              <span class="topic sub-title">{{ teacherObj.name_first }} {{ teacherObj.name_last }} Profile Video</span><br/><br/>
              <video width='100%' height='315' [src]='teacherProfileVideo' controlsList="nodownload" controls loop muted
                playsinline></video>
            </div>
            <div fxFlex="100" class="subtitle__box">
              <span class="topic sub-title">{{ teacherObj.name_first }} {{ teacherObj.name_last }} Best Achievement</span><br/>
              <span *ngIf="achievements.length == 0"><i>No achievement</i></span>
              <div class="col-lg-12" *ngFor="let achievement of achievements">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="avatar lg rounded-circle no-thumbnail bg-lavender-purple text-light"><i
                          class="icofont-badge fs-4"></i></div>
                      <div class="flex-fill ms-3">
                        <p class="mb-0 ">{{achievement.achievement}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="100" class="subtitle__box">
              <span class="topic sub-title">{{ teacherObj.name_first }} {{ teacherObj.name_last }} Best Experience</span><br/>
              <span *ngIf="experiences.length == 0"><i>No experiences</i></span>
              <div class="col-lg-12" *ngFor="let experience of experiences">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex align-items-center">
                      <div class="avatar lg rounded-circle no-thumbnail bg-lavender-purple text-light"><i
                          class="icofont-badge fs-4"></i></div>
                      <div class="flex-fill ms-3">
                        <p class="mb-0 ">{{experience.experience}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>