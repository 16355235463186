import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BalanceReportListComponent } from './pages/balance-report-list/balance-report-list.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  { path: 'balance-report/list', component: BalanceReportListComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BalanceReportRoutingModule { }
