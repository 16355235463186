import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../services/course.service';
import { SharedService } from '../../../shared/services/shared.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-course-management',
  templateUrl: './course-management.component.html',
  styleUrls: ['./course-management.component.sass']
})
export class CourseManagementComponent implements OnInit {
  courses: any[] = [];

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  constructor(
    private layoutServiceService: LayoutServiceService,
    private courseService: CourseService,
    private sharedService: SharedService
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    this.getCourses();
  }

  openNewCourse() {

  }

  async getCourses() {

    const userDetail = <String>localStorage.getItem("userDetail");
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    const requestObj = {
      isPublished: null,
      schoolId: requestedBy?.schoolId
    }

    await this.courseService.getAllCourse(requestObj).subscribe((data) => {
      this.courses = data.data;
    })
  }

  addNewCourseHandler(eventObj: any) {
    this.getCourses();
  }

  updateCourseHandler(eventObj: any) {
    this.getCourses();
  }
}
