import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getAllPackage(packageObj: any = {}): Observable<any> {
    return this.http.post(`${environment.apiURL}/package`, packageObj, this.httpOptions);
  }

  getStorePackage(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/package-store`, packageObj, this.httpOptions);
  }
  
  saveMenu(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-menu`, packageObj, this.httpOptions);
  }

  getSubscribedPackageStatistic(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/statistic-package`, packageObj, this.httpOptions);
  }

  getUpcomingSchedulePackage(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/upcoming-schedule-package`, packageObj, this.httpOptions);
  }

  getUpcomingSchedulePackageTeacher(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/upcoming-schedule-package-teacher`, packageObj, this.httpOptions);
  }

  getAllPackagesByTeacher(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/packages-teacher`, packageObj, this.httpOptions);
  }
  getPackageCourse(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/package-courses`, packageObj, this.httpOptions);
  }

  managePublishPackage(packageObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/publish-packages`, packageObj, this.httpOptions);
  }
}
