<div class="row g-3 py-3">
    <div class="main-filter">
        <div class="sub-filter-1">
            <div class="label-input flex-custom">
                <label>Start Date</label>
                <input [(ngModel)]="filterStartDate" (change)="resetFilterEndDate()" type="date">
            </div>
            <div class="label-input flex-custom">
                <label>End Date</label>
                <input [(ngModel)]="filterEndDate" (change)="filterTable()" type="date">
            </div>
            <div class="label-input flex-custom">
                <label>Status</label>
                <select [(ngModel)]="filterStatus" (change)="filterTable()" class="form-select pointer custom-select">
                    <option value="">All</option>
                    <option value="7">Pending Approval</option>
                    <option value="8">Approved</option>
                    <option value="9">Rejected</option>
                    <option value="14">Card Payment</option>
                    <option value="16">Cashback</option>
                    <!-- <option value="10">TEMPORARY APPROVED</option> -->
                </select>
            </div>
            <div class="label-input flex-custom">
                <label>Search</label>
                <input [(ngModel)]="searchText" (keyup)="search($event)" type="text">
            </div>
            <div style="display: flex;
            align-items: end;">
                <button type="submit" (click)="filterTable()" class="filter"><i class="icofont-filter file-fixed-size excel"></i>Filter</button>
            </div>
            <div style="display: flex;
            align-items: end;">
                <button (click)="handleExport()" *ngIf="currentUserRole != 'PARENTS' && currentUserRole != 'TEACHER'" class="export"><i class="icofont-file-excel file-fixed-size excel"></i> Export to Excel</button>
            </div>
        </div>
    </div>
    <div style="height: 500px; overflow: auto;" *ngIf="fileUploadFilter?.length > 0">
        <div class="d-flex flex-column" *ngIf="currentTab == 'file'">
            <div *ngFor="let file of fileUploadFilter">
                <app-upload-details [fileUpload]='file' [currentTab]='currentTab'
                    [packageCourseData]="selectedPackageCourseData"></app-upload-details>
            </div>
        </div>
    </div>
    <div style="padding-top: 10px" *ngIf="!fileUploadFilter || (fileUploadFilter && fileUploadFilter.length == 0)">
        <div role="alert" class="alert alert-primary full-width"
            *ngIf="currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER'"><i
                class="icofont-info-circle"></i>&nbsp;&nbsp;No file
            uploaded. Please use filter above to display any available files.
        </div>
        <div role="alert" class="alert alert-primary full-width"
            *ngIf="currentUserRole == 'SUPER ADMIN' || currentUserRole == 'ADMIN'"><i
                class="icofont-info-circle"></i>&nbsp;&nbsp;No file
            uploaded.
        </div>
    </div>
    <div class="total-recharge" *ngIf="currentUserRole == 'SUPER ADMIN' || currentUserRole == 'ADMIN'">
        <span style="color: rgb(255, 165, 0)">Pending Approval (A)</span>
        <span style="color: rgb(0, 128, 0)">Approved (B)</span>
        <span style="color: rgb(2, 204, 22)">Cashback (C)</span>
        <span style="color: rgb(0, 179, 255)">Card Payment (CP)</span>
        <br/>
        <span *ngIf="!filterStatus">Total Topup (<span style="color: rgb(255, 165, 0)">A</span> + <span style="color: rgb(0, 128, 0)">B</span> + <span style="color: rgb(2, 204, 22)">C</span> - <span style="color: rgb(0, 179, 255)">D</span>): <b>RM {{totalRechargeAmount}}</b></span>
        <span *ngIf="filterStatus == 7">Total <b style="color: rgb(255, 165, 0)">Pending Approval</b> Topup: <b>RM {{totalRechargeAmount}}</b></span>
        <span *ngIf="filterStatus == 8">Total <b style="color: rgb(0, 128, 0)">Approved</b> Topup: <b>RM {{totalRechargeAmount}}</b></span>
        <span *ngIf="filterStatus == 9">Total <b style="color: rgb(252, 70, 70)">Rejected</b> Topup: <b>RM {{totalRechargeAmount}}</b></span>
        <span *ngIf="filterStatus == 14">Total <b style="color: rgb(0, 179, 255)">Card Payment</b>: <b>RM {{totalRechargeAmount}}</b></span>
        <span *ngIf="filterStatus == 16">Total <b style="color: rgb(2, 204, 22)">Cashback</b>: <b>RM {{totalRechargeAmount}}</b></span>
    </div>
    <mat-paginator 
        (page)="handlePageEvent($event)" 
        [length]="totalResult" 
        [pageSize]="limit"
        [showFirstLastButtons]="true" 
        [pageSizeOptions]="getPageSizeOptions()" 
        [pageIndex]="pageIndex"
        aria-label="Select page"
        #paginator>
    </mat-paginator>
</div>
