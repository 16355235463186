<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around start">
          <div class="custom-card content-container" fxFlex="80">
            <div class="content-area__main">
              <div>
                <h4 class=" fw-bold flex-fill title-left" *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN' || currentUserRole == 'STAFF'">Balance Report</h4>
                <div style="margin-top: 30px;">
                  <balance-report-table [balanceReportsList]="balanceReportList" (exportEvent)="exportHandler($event)" (balanceReportUpdatedEvent)="balanceReportUpdatedHandler($event)"></balance-report-table>
                </div>
                <mat-paginator 
                  (page)="handlePageEvent($event)" 
                  [length]="totalResult" 
                  [pageSize]="limit"
                  [showFirstLastButtons]="true" 
                  [pageSizeOptions]="getPageSizeOptions()" 
                  [pageIndex]="pageIndex"
                  aria-label="Select page"
                  #paginator>
                </mat-paginator>
                <ng-template #customLoadingTemplate>
                  <div class="custom-class">
                    <h3>Saving...</h3>
                  </div>
                </ng-template>
                <ngx-loading
                  [show]="loading"
                  [config]="{ backdropBorderRadius: '3px' }"
                  [template]="customLoadingTemplate"
                ></ngx-loading>
              </div>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>
</body>