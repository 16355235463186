import { Component, OnInit } from '@angular/core';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalService } from '../../../../services/global.service';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

import { ZoomMtg } from '@zoomus/websdk';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../../modules/session/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../../../../modules/shared/components/alert/alert.component';

// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.5/lib', '/av');

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();
// // // loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load('en-US');
// ZoomMtg.i18n.reload('en-US');

@Component({
  selector: 'app-course-meeting',
  templateUrl: './course-meeting.component.html',
  styleUrls: ['./course-meeting.component.scss']
})
export class CourseMeetingComponent implements OnInit {
  courses: any[] = [];

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  public signatureEndpoint = '';
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app

  public apiKey = '';
  public apiSecretKey = '';
  public meetingNumber = '';
  public role = '0';
  public leaveUrl = ''; // LAST URL
  public userName = '';
  public userEmail = '';
  public password = '';
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  public registrantToken = '';

  public userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));
  public dependentDetail = localStorage.getItem('dependentDetail') ? JSON.parse(<string>localStorage.getItem('dependentDetail')) : null;

  public action: any = null;
  
  constructor(
    private layoutServiceService: LayoutServiceService,
    private globalService: GlobalService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private sessionService: SessionService,
    private dialog: MatDialog
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params: any) => {
      if (
        params &&
        Object.keys(params).length > 0
      ) {

        if( params.data ){
          const encryptedData = decodeURIComponent(params.data);
          const decryptedData: any = CryptoAES.decrypt(encryptedData, "nahrasolution secret key").toString(CryptoENC);
          const meetingData = decryptedData.split(',');

          if( !meetingData[4] ){
            this.alertMeetingTokenParamsInstance();
          } else {
            const meetingRedirectObject = {
              joinedBy: meetingData[4],
              meetingToken: encryptedData
            };
  
            this.sessionService.validateMeetingRedirect(meetingRedirectObject).subscribe(( response ) => {
              if( 
                response &&
                response.data
              ){
                const countExist = response?.data?.countExist;
                if( countExist <= 0 ){
                  this.sessionService.saveMeetingRedirect(meetingRedirectObject).subscribe(( result ) => {
                    if( result ){
                      this.initializeMeetingData(meetingData);
                    }
                  }, (error) => {
        
                  });
                } else {
                  this.alertMeetingRedirectInstance();
                }
              } else {
              }
            }, (error) => {
  
            });
          }
        } else {
          this.action = params.action;
        }
        
      } else {
        this.initializeMeetingData();
      }
    });
  }

  initializeMeetingData(meetingData?: any){
    this.apiKey = this.globalService.apiKey;
    this.apiSecretKey = this.globalService.apiSecretKey;
    this.meetingNumber = meetingData ? meetingData[0] : this.globalService.meetingNumber;
    this.password = meetingData ? meetingData[1] : this.globalService.password;
    this.leaveUrl = meetingData ? "class/class-meeting?action=exit" : this.globalService.leaveUrl;

    if (this.dependentDetail == null) {
      this.userName = meetingData ? meetingData[2] : `${this.userDetail.name_first} ${this.userDetail.name_last}`;
    } else {
      this.userName = meetingData ? meetingData[2] : `${this.dependentDetail.name}`;
    }

    this.getSignature();
  }

  getSignature() {
    try{
      if ( this.meetingNumber ) {
        let signature = ZoomMtg.generateSignature({
          meetingNumber: this.meetingNumber,
          apiKey: this.apiKey,
          apiSecret: this.apiSecretKey,
          role: this.role,
          success: (res: any) => {
            this.startMeeting(res.result);
          }
        });
      } else {
        this.router.navigate(['schedule/list']);
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  startMeeting(signature: any) {

    let self = this;

    const zoomVideo = document.getElementById('zmmtg-root')

    if (zoomVideo != null) {
      zoomVideo.style.display = 'block';
      zoomVideo.style.zIndex = '2';
    }

    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      disableInvite: true,
      disableRecord: true,
      success: (success: any) => {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: self.meetingNumber,
          userName: self.userName,
          apiKey: self.apiKey,
          userEmail: self.userEmail,
          passWord: self.password,
          tk: self.registrantToken,
          success: (success: any) => {
            setTimeout(() => {
              ZoomMtg.getCurrentUser({
                success: (success: any) => {
                  const currentUserId = success.result.currentUser.userId;
                  const currentUserName = success.result.currentUser.userName;
                  const newUserName = self.userName;

                  ZoomMtg.rename({
                    userId: currentUserId,
                    oldName: currentUserName,
                    newName: newUserName
                  });
                },
                error: (error: any) => {
                  console.log(error)
                }
              });
            }, 2000);
          },
          error: (error: any) => {
            console.log(error)
          }
        })
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }

  ngOnDestroy() {
    const zoomVideo = document.getElementById('zmmtg-root')

    if (zoomVideo != null) {
      zoomVideo.style.display = 'none';
      zoomVideo.style.zIndex = '0';
    }
    ZoomMtg.leaveMeeting({
      success: (success: any) => {
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  alertMeetingRedirectInstance(){
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Unauthorized Access. Class link are only usable once. You are either trying to enter class for second time or using class link provided by other user.',
        type: 'warning'
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigateByUrl('class/class-meeting?action=exit');
    });
  }

  alertMeetingTokenParamsInstance(){
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Detected App that you are using old version. Please install new App from Nahra Solution Portal. You may try again after installed the latest version.',
        type: 'warning'
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigateByUrl('class/class-meeting?action=exit');
    });
  }
}
