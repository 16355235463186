import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UploadManagementComponent } from './pages/upload-management/upload-management.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  { path: 'file/file-management', component: UploadManagementComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FileUploadRoutingModule { }
