import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { SessionDetailComponent } from '../session-detail/session-detail.component';
import "moment-timezone";

@Component({
  selector: 'session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.sass']
})
export class SessionListComponent implements OnInit {
  @Input() course: any;
  @Input() sessionList: any[];
  @Output() onUpdateSession = new EventEmitter<any>();

  displayedColumns = [ 'day', 'start_time', 'end_time', 'duration', 'status', 'start_date', 'due_date', 'action' ]
  public timezone: any = null;

  constructor(public dialog: MatDialog) { 
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngOnInit(): void {
  }

  convertTime(time: any) {
    return moment.utc(time, 'HH:mm:ss').tz(this.timezone).format('hh:mm A');
  }

  convertDate(date: any) {
    return moment.utc(date).tz(this.timezone).format('DD MMMM YYYY');
  }

  getDuration(duration: any) {
    let d = moment.duration(duration);
    let hours = Math.floor(d.asHours());
    let mins = Math.floor(d.asMinutes()) - hours * 60;

    let str = hours == 0 ? `${mins} minute(s)` : ( mins == 0 ? `${hours} hour(s)` : `${hours} hour(s) ${mins} minute(s)` );

    return str;
  }

  showDay(day: any) {
    return environment.day[day];
  }

  openSession(sessionObj: any) {
    const dialogRef = this.dialog.open( SessionDetailComponent, {
      data: {
        course: this.course,
        session: sessionObj
      },
      panelClass: 'dialog-panel'
    } );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onUpdateSession.emit(result);
      }
    });
  }

}
