import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { DomSanitizer } from "@angular/platform-browser";
import { url } from 'inspector';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from '../../services/content.service';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-content-details',
  templateUrl: './content-details.component.html',
  styleUrls: ['./content-details.component.scss']
})
export class ContentDetailsComponent implements OnInit {
  @Input() fileContent: any;
  @Input() contentData: any;
  @Input() showDelete: boolean = true;
  @Input() currentTab: string = 'general';
  @Input() typeName: string = '';
  @Output() onManagePublishAnnouncement = new EventEmitter<any>();
  @Output() onDeleteAnnouncement = new EventEmitter<any>();
  public timezone: any = null;

  constructor(
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private contentService: ContentService,
    private sharedService: SharedService
  ) { 
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  confirmDeleteFileContent(fileContent: any): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Are you sure to delete this content?',
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if( result?.data?.confirm == true ){
        this.deleteFileContent(fileContent);
      }
    });
  }

  deleteFileContent(fileContent: any): void {
    let basePath = 'content-info';
    this.fileUploadService.deleteFileSQL(fileContent.contentFileName, fileContent.contentId, basePath, null, null);
  }
  trimFileName(fileName: any){
    return fileName.substr(15);
  }
  reFormatMime(fileMime: any) {
    const mimeArray = fileMime.split("/");
    return mimeArray[1].toUpperCase();
  }

  decodeDescription(description: any) {
    return decodeURI(description);
  }

  convertDateTime(datetime: any) {
    return moment.utc(datetime).tz(this.timezone).format('DD MMMM YYYY HH:mm A');
  }
  convertDate(date: any) {
    return moment.utc(date, 'YYYY-MM-DD').tz(this.timezone).format('DD MMMM YYYY');
  }
  viewFile(
    url: any,
    mime: any
  ){
    window.open(url, '_blank');
  }

  managePublishAnnouncement(contentData: any, isPublished){

    const prepareObj = {
      contentId: contentData?.contentId,
      isPublished: isPublished
    };

    this.contentService.managePublishContent(prepareObj).subscribe((response)=>{
      if( response ){
        this.onManagePublishAnnouncement.emit(response);
      }
    },(error)=>{
      this.sharedService.showSnackBar(error);
    });
  }

  deleteAnnouncement(announcementId: any){
    const prepareObj = {
      id: announcementId
    }

    this.contentService.removeContent(prepareObj).subscribe((data) => {
      this.sharedService.showSnackBar(data.message);
      this.onDeleteAnnouncement.emit(data.message);
    }, (error) => {
      this.sharedService.showSnackBar('Failed to delete announcement');
    });
  }
}