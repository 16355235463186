import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { FileUpload } from '../../../file-upload/models/file-upload.model';
import { DomSanitizer } from "@angular/platform-browser";
import { url } from 'inspector';
import { Router } from '@angular/router';
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { CourseService } from '../../../course/services/course.service';
import { SharedService } from '../../../shared/services/shared.service';
import moment from 'moment';

@Component({
  selector: 'app-upload-details',
  templateUrl: './upload-details.component.html',
  styleUrls: ['./upload-details.component.scss']
})
export class UploadDetailsComponent implements OnInit {
  @Input() fileUpload: any;
  @Input() packageCourseData: any;
  @Input() showDelete: boolean = true;
  @Input() currentTab: string = 'file';

  public currentUserRole: any = '';
  timezone: string;

  constructor(
    private uploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private courseService: CourseService,
    public sharedService: SharedService
  ) { 
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngOnInit(): void {
    let userDetail = <String>localStorage.getItem('userDetail');
    let userData = userDetail ? JSON.parse(userDetail.toString()) : null;
    this.currentUserRole = userData.usertype_name;
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  confirmDeleteFileUpload(fileContent: any): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Are you sure to delete this study material?',
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(
        result &&
        result.data &&
        result.data.confirm == true
      ){
        this.deleteFileUpload(fileContent);
      }
    });
  }

  deleteFileUpload(fileUpload: any): void {
    let basePath = 'recharge-file';
    this.uploadService.deleteFileSQL(fileUpload.fileName, fileUpload.fileId, basePath, this.packageCourseData.package_id, this.packageCourseData.course_id);
  }
  trimFileName(fileName: any){
    return fileName.substr(15);
  }
  reFormatMime(fileMime: any) {
    const mimeArray = fileMime.split("/");
    return mimeArray[1].toUpperCase();
  }
  viewFile(
    url: any,
    mime: any
  ){
    window.open(url, '_blank');
  }

  changeStatusRecharge(statusName: any, fileId: any, event: any){

    event.stopPropagation();
    
    const object = {
      status: statusName,
      id: fileId
    }

    this.courseService.changeRechargeStatus(object).subscribe((result) => {
      this.sharedService.showSnackBar(`${statusName} succesfully`);
      this.uploadService.setSubscriptionRefreshCourseFile(1,1);
    }, (error) => {
      this.sharedService.showSnackBar('Failed change status');
    });
  }

  convertDateTime(rechargeDate: any){
    // return moment.utc(rechargeDate).tz(this.timezone).format('DD MMM YYYY hh:mm A')
    return moment.utc(rechargeDate).format('DD MMM YYYY hh:mm A')
  }

  convertAbsoluteNumber(x: any){
    return Math.abs(x);
  }
}