import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getAllSession(sessionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/session`, sessionObj, this.httpOptions);
  }
  
  saveSession(sessionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-session`, sessionObj, this.httpOptions);
  }

  setPackageSession(sessionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/set-session`, sessionObj, this.httpOptions);
  }

  getOverlapSubscribedSession(sessionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/overlap-session`, sessionObj, this.httpOptions);
  }

  saveMeetingRedirect(meetingObject: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-meeting-redirect`, meetingObject, this.httpOptions);
  }

  validateMeetingRedirect(meetingObject: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/validate-meeting-redirect`, meetingObject, this.httpOptions);
  }
}
