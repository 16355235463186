<div class="row g-3 py-3">
  <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let course of courses">
    <div class="card">
      <div class="lesson_name">
        <div class="card-header text-white pointer" [ngClass]="{
          'bg-danger': course?.isPublished == 1,
          'bg-success': course?.isPublished == 0
        }">
          <span *ngIf="course?.isPublished == 0" (click)="managePublishCategory(course, true)">
            <i class="icofont-check-circled me-2"></i> Publish</span>
          <span *ngIf="course?.isPublished == 1" (click)="managePublishCategory(course, false)">
            <i class="icofont-close-circled me-2"></i> Unpublish</span>
        </div>
        <div
          class="bg-primary d-flex justify-content-center align-items-center flex-column position-relative img-overlay">
          <img src="assets/images/default-menu.gif" alt="course-img" class="img-fluid">
          <div class="position-absolute top-50 start-50 translate-middle">
            <h2 class="mb-0 fw-bold text-white fs-12 text-center mt-3">{{course.name}}</h2>
            <br/>
            <span *ngIf="currentUserRole == 'SUPER ADMIN'" class="mb-0 text-white text-center mt-3">{{course?.schoolName}}</span>
          </div>
        </div>
      </div>
      <div class="card-body">
      </div>
      <div class="card-footer" (click)="viewCourse(course)">
        <span>View Details&nbsp;&nbsp;&nbsp;</span><i class="icofont-simple-right"></i>
      </div>
    </div>
  </div>
</div>
