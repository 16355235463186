import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalService } from '../services/global.service';
import isJwtTokenExpired, { decode } from 'jwt-check-expiry';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
		public jwtHelper: JwtHelperService,
		public globalService: GlobalService
	) { }

	public isAuthenticated() {
		let self = this;
		let stringValue = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false;
		let accessToken = localStorage.getItem('token') ? localStorage.getItem('token') : false;
		let dependentAccessToken = localStorage.getItem('dependentToken') ? localStorage.getItem('dependentToken') : false;
		const isLoggedIn = (stringValue == "true");

		this.globalService.tokens = accessToken;
		this.globalService.dependentTokens = dependentAccessToken;
		this.globalService.isLoggedIn = isLoggedIn;

		if (isLoggedIn) {
			if (dependentAccessToken) {
				if (isJwtTokenExpired(dependentAccessToken) === false) {
					return true;
				} else {
					return false;
				}
			} else if (accessToken) {
				if (isJwtTokenExpired(accessToken) === false) {
					return true;
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	}
}