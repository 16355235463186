<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div class="body d-flex py-lg-3 py-md-2 web-container">
          <div class="container-xxl">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="web-view">
              <div fxLayout="row">
                <div class="card flex-row btn-group">
                  <button mat-flat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Previous
                  </button>
                  <button mat-stroked-button mwlCalendarToday [(viewDate)]="viewDate">
                    Today
                  </button>
                  <button mat-flat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Next
                  </button>
                </div>
              </div>
              <div fxLayout="row">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
              </div>
              <div fxLayout="row">
                <div class="card flex-row btn-group">
                  <button mat-flat-button (click)="setView(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month">
                    Month
                  </button>
                  <button mat-flat-button (click)="setView(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week">
                    Week
                  </button>
                  <button mat-flat-button (click)="setView(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day">
                    Day
                  </button>
                </div>
              </div>
            </div>

            <div fxLayout="column" class="mobile-view">
              <div fxFlex="100">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
              </div>
              <div fxFlex="100">
                <div fxLayout="row" fxLayoutAlign="space-between center" >
                  <div class="mobile-view">
                    <button mat-flat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                      (viewDateChange)="closeOpenMonthViewDay()">
                      <
                    </button>
                    <button mat-stroked-button mwlCalendarToday [(viewDate)]="viewDate" style="background-color: white;">
                      Today
                    </button>
                    <button mat-flat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                      (viewDateChange)="closeOpenMonthViewDay()">
                      >
                    </button>
                  </div>
                  <div class="mobile-view">
                    <button mat-flat-button (click)="setView(CalendarView.Month)"
                      [class.active]="view === CalendarView.Month">
                      M
                    </button>
                    <button mat-flat-button (click)="setView(CalendarView.Week)"
                      [class.active]="view === CalendarView.Week">
                      W
                    </button>
                    <button mat-flat-button (click)="setView(CalendarView.Day)"
                      [class.active]="view === CalendarView.Day">
                      D
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div class="container-xxl">
            <div [ngSwitch]="view" style="margin-bottom: 10px;">
              <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
              </mwl-calendar-month-view>
              <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [tooltipTemplate]="tooltipTemplate"
                (eventTimesChanged)="eventTimesChanged($event)">
              </mwl-calendar-week-view>
              <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
                [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [tooltipTemplate]="tooltipTemplate"
                (eventTimesChanged)="eventTimesChanged($event)">
              </mwl-calendar-day-view>
            </div>
            <div class="mobile-view">
              <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                <div fxFlex="100" class="event-list__box" *ngFor="let eventItem of eventsList" (click)="handleEvent('Clicked', eventItem)">
                  <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                    <div fxFlex="70">
                      {{ eventItem.title_tooltip }}
                      <br/>
                      <span style="color: green;">{{ eventItem.start|date: 'hh:mm a' }}</span> - 
                      <span style="color: red;">{{ eventItem.end|date: 'hh:mm a' }}</span>
                    </div>
                    <div fxFlex="30">
                      <div [innerHtml]="eventItem.message" class="class-pill-status"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #tooltipTemplate let-event="event">
              <div class="tooltip-schedule" fxLayout="row">
                <div fxFlex="70">
                  <span>{{ event.title_tooltip }}</span>
                  <br/>
                  <span style="color: green;">{{ event.start|date: 'hh:mm a' }}</span> - 
                  <span style="color: red;">{{ event.end|date: 'hh:mm a' }}</span>
                </div>
                <div fxFlex="30" style="text-align: right">
                  <div [innerHtml]="event.message" class="class-pill-status"></div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </router-outlet>
      <br /><br />
    </div>
  </div>
</body>