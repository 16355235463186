import { ChangeDetectorRef, Component, Inject, Input, OnInit, Sanitizer } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { TransactionService } from '../../services/transaction.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-transaction-info',
  templateUrl: './transaction-info.component.html',
  styleUrls: ['./transaction-info.component.scss'],
})
export class TransactionInfoComponent implements OnInit {

  public transactionInfo: any[] = [];
  public paymentInvoice: any = '';
  public checkedCount: number = 0;
  public FPXPaymentURL: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ownDialogRef: MatDialogRef<TransactionInfoComponent>,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    private transactionService: TransactionService,
    private sanitizer: DomSanitizer
  ) {
    this.FPXPaymentURL = environment.FPXPaymentURL;
  }

  ngOnInit(): void {
    this.transactionInfo = this.data.transactionInfo;
    const billCode = this.data.billCode;
    this.paymentInvoice = this.sanitizeURL(`${this.FPXPaymentURL}${billCode}`);
    this.ref.detectChanges();
  }

  sanitizeURL(oldURL: any ): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  close(data?: any) {
    this.ownDialogRef.close();
  }
}
