import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDirective } from './directives/shared.directive';
import { ButtonComponent } from './components/button/button.component';
import { FooterComponent } from './components/footer/footer.component';
import { ModalComponent } from './components/modal/modal.component';
// import { NavbarComponent } from './components/navbar/navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CarouselComponent } from './components/carousel/carousel.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTreeModule } from '@angular/material/tree';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { CreditComponent } from './components/credit/credit.component';
import { AlertComponent } from './components/alert/alert.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { NgxLoadingModule } from "ngx-loading";

@NgModule({
  declarations: [
    SharedDirective,
    ButtonComponent,
    FooterComponent,
    ModalComponent,
    // NavbarComponent,
    CarouselComponent,
    CreditComponent,
    AlertComponent,
    ImageViewerComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatTreeModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    NgxLoadingModule.forRoot({})
  ],
  exports: [
    ButtonComponent,
    FooterComponent,
    ModalComponent,
    // NavbarComponent,
    CarouselComponent,
  ],
})
export class SharedModule {}
