<div fxLayout="column wrap" fxLayoutAlign="space-around center">
  <div class="card-title" fxFlex="100"> {{ package.title }}</div>

  <div fxFlex="100" class="full-width" *ngIf="!isStore">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b>Selected Sessions</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column wrap" fxLayoutAlign="space-around start">
          <div fxFlex="100" class="card-gap" *ngFor="let day of sessionToDay; let i = index">
            <div class="full-width day-title"><b>{{ getDay(i) }}</b></div>
            <div *ngIf="day.sessions.length == 0 && i != 0">
              <i class="greyed-text">No Session For This Day</i>
            </div>
            <div *ngFor="let session of day.sessions" class="text-with-icon">
              <span class="subject-text">{{session.course_name}}</span> : {{ convertTime(session.start_time) }} - {{
              convertTime(session.end_time) }}
              <span class="custom-button" (click)="deleteConfirm(session)">Remove</span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div fxFlex="100" class="divider full-width" *ngIf="!isStore">
    <mat-divider></mat-divider>
  </div>

  <div fxFlex="100" class="divider full-width" *ngIf="!isStore">
    <span class="card-subtitle">Add New Session</span>
  </div>

  <div fxFlex="100" class="full-width" *ngIf="!isStore">
    <div class="form-group">
      <mat-label>Subject</mat-label>

      <div class="input-group mb-3">
        <select [(ngModel)]="selectedCourseId" (change)="courseChangeHandler()" class="form-select pointer">
          <option value=""></option>
          <option *ngFor="let course of courses" [value]="course.id">{{
            course["name"]
            }}</option>
        </select>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" [disabled]="isDisabled" (click)="setPackageSession()"
            type="button"><i class="icofont-plus-circle fs-4"></i></button>
        </div>
      </div>
    </div>
  </div>

  <div fxFlex="100" class="full-width file-list" *ngIf="!isStore">
    <span class="">Subject's Available Sessions</span>
    <div fxLayout="column wrap" fxLayoutAlign="space-around start">
      <div fxFlex="100" class="full-width" *ngIf="sessions.length == 0">
        <br /><span style="color: brown;">No sessions available</span>
      </div>
      <div fxFlex="100" class="custom-card full-width card-gap" *ngFor="let session of sessions; let i = index">
        <div fxLayout="row wrap" fxLayoutAlign="space-around center">
          <div fxFlex="90">
            <span class="">{{ getDay(session.day) }}</span><br>
            <span>{{ session.start_time }} - {{ session.end_time }}</span>
          </div>
          <div fxFlex="10">
            <input type="checkbox" (click)="selectedSessionHandler(i)" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="100" class="full-width" *ngIf="isStore && isStudyMaterial">
    <h4>
      Study Materials
    </h4>
    <div class="row g-3 py-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link pointer" [ngClass]="{
				'active': currentTab == 'file'
			}" aria-current="page" (click)="changeTab('file')">File</a>
        </li>
        <li class="nav-item">
          <a class="nav-link pointer" [ngClass]="{
				'active': currentTab == 'image'
			}" (click)="changeTab('image')">Image</a>
        </li>
        <li class="nav-item">
          <a class="nav-link pointer" [ngClass]="{
				'active': currentTab == 'video'
			}" (click)="changeTab('video')">Video</a>
        </li>
      </ul>
      <div *ngIf="fileUploadFilter && fileUploadFilter.length > 0">
        <div class="d-flex p-2 col-lg-3 col-md-6 col-sm-6" *ngIf="currentTab != 'file'">
          <div *ngFor="let file of fileUploadFilter">
            <app-upload-details [fileUpload]='file' [currentTab]='currentTab' [showDelete]="false"></app-upload-details>
          </div>
        </div>
        <div class="d-flex align-items-center flex-column" *ngIf="currentTab == 'file'">
          <div *ngFor="let file of fileUploadFilter" class="w-100">
            <app-upload-details [fileUpload]='file' [currentTab]='currentTab' [showDelete]="false"></app-upload-details>
          </div>
        </div>
      </div>
      <div style="padding-top: 10px" *ngIf="!fileUploadFilter || (fileUploadFilter && fileUploadFilter.length == 0)">
        <div role="alert" class="alert alert-primary full-width"><i class="icofont-info-circle"></i>&nbsp;&nbsp;Study
          materials are not available.</div>
      </div>
    </div>
  </div>
  <div fxFlex="100" class="full-width" *ngIf="isStore && isTimetable">
    <h4>
      Schedule
    </h4>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="web-view">
      <div fxLayout="row">
        <div class="btn-group shadow">
          <button mat-flat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            Previous
          </button>
          <button mat-stroked-button mwlCalendarToday [(viewDate)]="viewDate">
            Today
          </button>
          <button mat-flat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            Next
          </button>
        </div>
      </div>
      <div fxLayout="row">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
      </div>
      <div fxLayout="row">
        <div class="btn-group shadow">
          <button mat-flat-button (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
            Month
          </button>
          <button mat-flat-button (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
            Week
          </button>
          <button mat-flat-button (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
            Day
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="mobile-view">
      <div fxFlex="100">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
      </div>
      <div fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="space-between center" >
          <div class="mobile-view">
            <button mat-flat-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              <
            </button>
            <button mat-stroked-button mwlCalendarToday [(viewDate)]="viewDate" style="background-color: white;">
              Today
            </button>
            <button mat-flat-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              >
            </button>
          </div>
          <div class="mobile-view">
            <button mat-flat-button (click)="setView(CalendarView.Month)"
              [class.active]="view === CalendarView.Month">
              M
            </button>
            <button mat-flat-button (click)="setView(CalendarView.Week)"
              [class.active]="view === CalendarView.Week">
              W
            </button>
            <button mat-flat-button (click)="setView(CalendarView.Day)"
              [class.active]="view === CalendarView.Day">
              D
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div [ngSwitch]="view" style="margin-bottom: 10px;">
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [tooltipTemplate]="tooltipTemplate"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" [tooltipTemplate]="tooltipTemplate"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-day-view>
    </div>
    <div class="mobile-view">
      <div fxLayout="row wrap" fxLayoutAlign="space-around center">
        <div fxFlex="100" class="event-list__box" *ngFor="let eventItem of eventsList" (click)="handleEvent('Clicked', eventItem)">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <div fxFlex="70">
              {{ eventItem.title_tooltip }}
              <br/>
              <span style="color: green;">{{ eventItem.start|date: 'hh:mm a' }}</span> - 
              <span style="color: red;">{{ eventItem.end|date: 'hh:mm a' }}</span>
            </div>
            <div fxFlex="30">
              <div [innerHtml]="eventItem.message" class="class-pill-status"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #tooltipTemplate let-event="event">
      <div class="tooltip-schedule">
        <div>
          {{ event.title_tooltip }}
          <br/>
          <span style="color: green;">{{ event.start|date: 'hh:mm a' }}</span> - 
          <span style="color: red;">{{ event.end|date: 'hh:mm a' }}</span>
        </div>
        <div style="text-align: right" *ngIf="event.isSubscribed" class="class-pill-status">
          <div [innerHtml]="event.message"></div>
        </div>
      </div>
    </ng-template>
  </div>
  <div fxFlex="100" class="full-width" *ngIf="isStore && isInfo">
    <h4>
      Package Information
    </h4>
    <div class="row g-3 py-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link pointer" [ngClass]="{
				'active': currentInfoTab == 'description'
			}" aria-current="page" (click)="changeInfoTab('description')">Description</a>
        </li>
        <li class="nav-item">
          <a class="nav-link pointer" [ngClass]="{
				'active': currentInfoTab == 'courses'
			}" (click)="changeInfoTab('courses')">Courses</a>
        </li>
      </ul>
      <div *ngIf="currentInfoTab == 'description'">
        <div class="d-flex col-lg-12 col-md-12 col-sm-12">
          <div class="card teacher-card mb-3  w-100">
            <div class="card-body p-4 d-flex teacher-fulldeatil">
              <div class="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center w220">
                <img *ngIf="package.image_path" [src]="package.image_path" alt="package-img"
                  class="img-fluid custom-height">
                <img *ngIf="!package.image_path" src="assets/images/video-img/video1.jpg" alt="package-img"
                  class="img-fluid custom-height">
              </div>
              <div class="teacher-info border-start ps-xl-4 ps-md-2 ps-sm-4 ps-4 w-100">
                <h6 class="mb-0 mt-2  fw-bold">{{data.package.title}}</h6>
                <br />
                <span class="text-uppercase small pill-global">{{data.package.levelName}}</span> &nbsp;&nbsp;
                <span class="text-uppercase small pill-global">{{data.package.subLevelName}}</span>
                <br />
                <br />
                <p class="large">
                  {{decodeDescription(data.package.description)}}
                </p>
                <div class="d-flex flex-column video-setting-icon mt-3 pt-3 border-top">
                  <div class="d-flex align-items-center row">
                    <div class="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 pill-global bg-black">
                      <i class="icofont-clock-time "></i>&nbsp; Duration
                    </div>
                    <!-- <span class="web-view">&nbsp;&nbsp;</span> -->
                    <div class="p-2 col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      {{convertDate(data.package.start_date) }} - {{convertDate(data.package.due_date)}}
                    </div>
                  </div>
                  <div class="d-flex align-items-center row">
                    <div class="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 pill-global bg-black">
                      <i class="icofont-price "></i>&nbsp; Fee
                    </div>
                    <!-- <span class="web-view">&nbsp;&nbsp;</span> -->
                    <div class="col-12 p-2 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <span style="font-weight: bold;">
                        <span style="text-decoration: line-through;color: red; margin-right: 10px;" *ngIf="package.fee === 0">RM 99</span>
                        <span style="color: green;" *ngIf="package.fee !== 0">RM {{package.fee}}</span>
                        <span style="color: green;" *ngIf="package.fee === 0">FREE</span>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center row">
                    <div class="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 pill-global bg-black">
                      <i class="icofont-ui-calendar"></i>&nbsp; Total Session
                    </div>
                    <!-- <span class="web-view">&nbsp;&nbsp;</span> -->
                    <div class="col-12 p-2 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      {{data.package.totalSession}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-top: 10px" *ngIf="!data.package || data.package.length == 0">
          <mat-card class="file-list">
            <mat-card-content>
              Information are not available
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div *ngIf="currentInfoTab == 'courses'">
        <div class="d-flex col-lg-12 col-md-12 col-sm-12 w-100">
          <div class="accordion w-100" id="accordionfaq">
            <div class="accordion-item" *ngFor="let course of courseDataGroup; let i = index;">
              <h2 class="accordion-header" [id]="'collapse-heading'+i">
                <button class="accordion-button web-view" type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                  <strong>{{course.courseName}}</strong>
                  <div class="pill-global centered-right">
                    {{course.totalSession}} Session
                  </div>
                </button>
                <button class="accordion-button mobile-view" type="button" data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                  <div class="row">
                    <div class="col-10 col-md-10 col-sm-10">
                      <strong>{{course.courseName}}</strong>
                    </div>
                    <div class="col-1 col-md-1 col-sm-1">
                      <div class="pill-global centered-right-mobile">
                        {{course.totalSession}}
                      </div>
                    </div>
                    <div class="col-1 col-md-1 col-sm-1"></div>
                  </div>
                </button>
              </h2>
              <div [id]="'collapse'+i" class="accordion-collapse collapse show"
                [attr.aria-labelledby]="'collapse-heading'+i">
                <div class="accordion-body">
                  <div>
                    <p>
                      {{ decodeDescription(course.courseDescription) }}
                    </p>
                  </div>
                  <div class="col" *ngFor="let teacher of course.teachers">
                    <div class="card teacher-card shadow">
                      <div class="card-body p-4 d-flex">
                        <div class="profile-teacher pe-xl-4 pe-lg-4 pe-md-0 pe-sm-0 pe-0 text-center w220">
                          <a>
                            <img *ngIf="teacher.profileImage" [src]="teacher.profileImage" alt=""
                              class="avatar xl rounded-circle img-thumbnail shadow-sm">
                            <img *ngIf="!teacher.profileImage" src="assets/images/default-profile.jpg" alt=""
                              class="avatar xl rounded-circle img-thumbnail shadow-sm">
                          </a>
                        </div>
                        <div class="teacher-info border-start ps-xl-4 ps-md-2 ps-sm-4 ps-4 w-100">
                          <a class="mb-0 mt-2 fw-bold d-block fs-6 pointer">{{ teacher.name }}</a>
                          <span class="text-uppercase small text-muted ">{{ teacher.subject }}</span>
                          <div class="video-setting-icon mt-3 pt-3 border-top web-view">
                            <div fxFlex="100" class="content__others">
                              <div fxFlex fxFill fxLayout="column" fxLayoutAlign="start">
                                <div fxFlex="40">
                                  <b class="topic">Teaching Experience</b>
                                </div>
                                <div fxFlex="70">
                                  {{ teacher.totalExperience }} Years
                                </div>
                              </div>
                            </div>
                            <div fxFlex="100" class="content__others">
                              <div fxFlex fxFill fxLayout="column" fxLayoutAlign="start">
                                <div fxFlex="40">
                                  <b class="topic">Qualification</b>
                                </div>
                                <div fxFlex="70">
                                  {{ teacher.qualification }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="border-top mobile-view margin-top-10">
                            <div class="row">
                              <div class="col-12">
                                <div class="row">
                                  <div class="col-12 pill-global">
                                    <b class="topic">Teaching Experience</b>
                                  </div>
                                  <div class="col-12 small">
                                    {{ teacher.totalExperience }} Years
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="row">
                                  <div class="col-12 pill-global">
                                    <b class="topic">Qualification</b>
                                  </div>
                                  <div class="col-12 small">
                                    <span *ngIf="teacher.qualification != 'null' && teacher.qualification != null && teacher.qualification.trim().length != 0 ">
                                      {{ teacher.qualification }}
                                    </span>
                                    <span *ngIf="teacher.qualification == 'null' || teacher.qualification == null || teacher.qualification.trim().length == 0 ">
                                      <i>No Qualification Set</i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding-top: 10px" *ngIf="!courseDataGroup || courseDataGroup.length == 0">
          <mat-card class="file-list">
            <mat-card-content>
              Courses are not available
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>