import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { GlobalService } from '../services/global.service';
import { DependentService } from '../modules/dependent/services/dependent.service';
import { UserService } from '../modules/user/services/user.service';
import { AlertComponent } from '../modules/shared/components/alert/alert.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {

  public intervalValidateToken: any = null;
  private timeout: number = 15000;

  constructor(
    public auth: AuthService, 
    public router: Router,
    private globalService: GlobalService,
    private userService: UserService,
		private dependentService: DependentService,
    private dialog: MatDialog
  ) {}
  
  canActivate(): boolean {
    let self = this;
    
    const userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));
    const accessToken = localStorage.getItem('token') ? localStorage.getItem('token') : false;
		const dependentAccessToken = localStorage.getItem('dependentToken') ? localStorage.getItem('dependentToken') : false;

    if (!this.auth.isAuthenticated()) {
      this.signout();
      return false;
    }

    if( dependentAccessToken ){
      if( this.intervalValidateToken ){
        clearInterval(this.intervalValidateToken);
      }
      this.intervalValidateToken = setInterval(()=>{
      	self.validateAccessToken(true, dependentAccessToken);
      }, this.timeout);
    } else if ( accessToken ){
      if( this.intervalValidateToken ){
        clearInterval(this.intervalValidateToken);
      }
      
      if( userDetail.usertype_name == "PARENTS" ){
        this.intervalValidateToken = setInterval(()=>{
          self.validateAccessToken(false, accessToken);
        }, this.timeout);
      }
    }
    
    return true;
  }

  validateAccessToken(
    isDependent: boolean = false,
    accessToken: any = null
  ){

		const userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));
		const dependentDetail = localStorage.getItem('dependentDetail') ? JSON.parse(<string>localStorage.getItem('dependentDetail')) : null;

		let prepareObj: any = {
			token: accessToken
		};

		if( isDependent ){
			prepareObj['id'] = dependentDetail.id;
			this.dependentService.validateDependentAccessToken(prepareObj).subscribe((response) => {
				if( 
					response &&
					response.data
				){
          const totalCount = response.data.totalCount ? response.data.totalCount : 0;

          if( totalCount == 0 ){
            this.alertLoginInstance();
          }
				}
			},(error) => {

			});
		} else {
			prepareObj['email'] = userDetail.email;
			this.userService.validateAccessToken(prepareObj).subscribe((response) => {
				if( 
					response &&
					response.data
				){
          const totalCount = response.data.totalCount ? response.data.totalCount : 0;

          if( totalCount == 0 ){
            this.alertLoginInstance();
          }
				}
			},(error) => {

			});
		}
	}

  alertLoginInstance(){
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Detected new device login. Please login back to use this ID on this device.',
        type: 'warning'
      },
      panelClass: 'dialog-panel'
    });

    this.signout();

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  signout() {
    if( this.intervalValidateToken ){
      clearInterval(this.intervalValidateToken);
    }
    localStorage.clear();
    // localStorage.removeItem('userDetail');
    // localStorage.removeItem('isLoggedIn');
    // localStorage.removeItem('dependentDetail');
    // localStorage.removeItem('token');
    // localStorage.removeItem('dependentToken');
    this.globalService.isLoggedIn = false;
    this.globalService.tokens = null;
    this.globalService.dependentTokens = null;
    this.router.navigate(['auth/sign-in']);
  }
}