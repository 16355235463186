import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CourseService } from 'src/app/modules/course/services/course.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionDetailComponent } from '../session-detail/session-detail.component';

@Component({
  selector: 'session-filter',
  templateUrl: './session-filter.component.html',
  styleUrls: ['./session-filter.component.scss']
})
export class SessionFilterComponent implements OnInit {
  @Output() onChangeCourse = new EventEmitter<any>();
  @Output() onAddSessionEvent = new EventEmitter<any>();

  filter_search: any = null;
  filter_course: any = null;

  courses: any[] = [];
  public filterCourseName: any = null;

  constructor(private courseService: CourseService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCourses();
  }

  addNewSession() {
    const dialogRef = this.dialog.open(SessionDetailComponent, {
      data: { course: this.filter_course },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onAddSessionEvent.emit(result);
    });
  }

  getCourses() {
    const userDetail = <String>localStorage.getItem("userDetail");
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    const requestObj = {
      isPublished: null,
      schoolId: requestedBy?.schoolId
    }

    this.courseService.getAllCourse(requestObj).subscribe( (data) => {
      this.courses = data.data;
    })
  }

  updateCourse(course: any) {
    this.filter_course = course;
    this.filterCourseName = course.name;
    this.onChangeCourse.emit(this.filter_course);
  }
}
