import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionListComponent } from './pages/transaction-list/transaction-list.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  { path: 'transaction/list', component: TransactionListComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionRoutingModule { }
