<div class="body d-flex py-lg-3 py-md-2">
  <div class="container-xxl">
      <div class="row clearfix">
          <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 order-lg-1  order-sm-2 order-2">
              <div class="card border-0">
                  <div class="card-body">
                      <div class="accordion" id="accordionfaq" *ngIf="pendingTransaction && pendingTransaction.length > 0">
                          <div class="accordion-item" *ngFor="let pending of pendingTransaction; let i = index;">
                              <h2 class="accordion-header web-view" [id]="'collapse-heading'+i">
                                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                  [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                                    <strong>{{ pending.transactionReference }}</strong>
                                    <button type="button" class="btn btn-info shadow centered-right" (click)="checkNow(pending.FPXPermalink, i)" *ngIf="!pending.isChecked">
                                      Check Status
                                    </button>
                                    <button type="button" class="btn btn-info shadow centered-right-2" (click)="payNow(pending.FPXPermalink)" *ngIf="pending.isChecked">
                                      Pay Now
                                    </button>
                                    <button type="button" class="btn btn-danger shadow centered-right" (click)="cancelTransaction(pending.transactionId, pending.cartIds, i)" *ngIf="pending.isChecked">
                                      Cancel
                                    </button>
                                  </button>
                              </h2>
                              <h2 class="accordion-header mobile-view" [id]="'collapse-heading'+i">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                  [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                                  <strong>{{ pending.transactionReference }}</strong>
                                  <button mat-mini-fab type="button" matBadgeColor="primary" (click)="checkNow(pending.FPXPermalink, i)" *ngIf="!pending.isChecked"
                                    class="btn btn-primary floating-fab__refresh" style="margin-left: 10px">
                                    <i class="icofont-refresh"></i>
                                  </button>
                                  <button mat-mini-fab type="button" matBadgeColor="success" (click)="payNow(pending.FPXPermalink)" *ngIf="pending.isChecked"
                                    class="btn btn-success floating-fab__pay" style="margin-left: 10px">
                                    <i class="icofont-credit-card"></i>
                                  </button>
                                  <button mat-mini-fab type="button" matBadgeColor="danger" (click)="cancelTransaction(pending.transactionId, pending.cartIds, i)" *ngIf="pending.isChecked"
                                    class="btn btn-danger floating-fab__remove" style="margin-left: 10px">
                                    <i class="icofont-bin"></i>
                                  </button>
                                </button>
                              </h2>
                              <div [id]="'collapse'+i" class="accordion-collapse collapse show"
                                  [attr.aria-labelledby]="'collapse-heading'+i">
                                  <div class="accordion-body" *ngFor="let package of pendingTransaction[i].packages">
                                      <p>
                                        <b>{{package.packageTitle}}</b>
                                        <br/>
                                        {{ decodeDescription(package.packageDescription) }}
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="p-10" *ngIf="!pendingTransaction || pendingTransaction.length == 0">
                        <div role="alert" class="alert alert-primary full-width"><i class="icofont-info-circle fs-5"></i>&nbsp;&nbsp;No pending payment. You may browse <b>Available Packages</b> to subscribe.</div>
                      </div>
                  </div>
              </div>
          </div>
      </div><!-- Row End -->
  </div>
  <ng-template #customLoadingTemplate>
    <div class="custom-class">
      <h3>Checking status...</h3>
    </div>
  </ng-template>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
    [template]="customLoadingTemplate"
  ></ngx-loading>
</div>