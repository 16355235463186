<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around start">
          <div class="custom-card content-container" fxFlex="80">
            <div class="content-area__main">
              <div class="action-area">
                <h4 class=" fw-bold flex-fill title-left" style="position: absolute;">User Management</h4>
                <button class="btn btn-custom" (click)="openNewUser()">Add New User
                  <i class="icofont-plus-circle"></i>
                </button>
              </div>
              <div>
                <user-table [usersList]="userList" (userUpdatedEvent)="userUpdatedHandler($event)"></user-table>
              </div>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>
</body>