import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourseListComponent } from './pages/course-list/course-list.component';
import { CourseManagementComponent } from './pages/course-management/course-management.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';
import { CourseMeetingComponent } from './pages/course-meeting/course-meeting.component';

const routes: Routes = [
  { path: 'class/class-list', component: CourseListComponent, canActivate: [AuthGuard]},
  { path: 'class/class-management', component: CourseManagementComponent, canActivate: [AuthGuard]},
  { path: 'class/class-meeting', component: CourseMeetingComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseRoutingModule { }
