
<div class="table-balanceReport">
  <div class="main-filter">
    <div class="sub-filter-1">
      <div class="label-input">
        <label>Start Date</label>
        <input [(ngModel)]="filterStartDate" (change)="resetFilterEndDate()" type="date">
      </div>
      <div class="label-input"> 
        <label>End Date</label>
        <input [(ngModel)]="filterEndDate" (change)="filterTable()" type="date">
      </div>
      <div class="label-input flex-custom">
        <label>Search</label>
        <input [(ngModel)]="searchText" (keyup)="search($event)" type="text">
      </div>
      <div style="display: flex;
        align-items: end;">
          <button type="submit" (click)="filterTable()" class="filter"><i class="icofont-filter file-fixed-size excel"></i>Filter</button>
      </div>
      <div style="display: flex;
        align-items: end;margin-top: 10px;">
          <button (click)="exportTable()"><i class="icofont-file-excel file-fixed-size excel"></i> Export to Excel</button>
      </div>
    </div>
  </div>
  <table mat-table matTableExporter [dataSource]="balanceReportsList" class="mat-elevation-z8 table" matSort #exporter="matTableExporter">
  
    <ng-container matColumnDef="parentName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Parent Name</th>
      <td mat-cell *matCellDef="let element">{{ element.parentName }}</td>
    </ng-container>

    <ng-container matColumnDef="parentPhoneNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Phone No.</th>
      <td mat-cell *matCellDef="let element">{{ element.parentPhoneNo }}</td>
    </ng-container>
  
    <!-- <ng-container matColumnDef="totalRecharge">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Total Recharge</th>
      <td mat-cell *matCellDef="let element">
        <span class="inline-block menu">{{ element.totalRecharge }} times</span>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="totalRechargeAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Total Recharge Amount</th>
      <td mat-cell *matCellDef="let element">RM {{ setPriceDecimal(element.totalRechargeAmount) }}</td>
    </ng-container>
  
    <ng-container matColumnDef="totalTransaction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Total Transaction</th>
      <td mat-cell *matCellDef="let element">
        <span class="inline-block menu">{{ element.totalTransaction }} times</span>
      </td>
    </ng-container> -->
  
    <ng-container matColumnDef="totalTransactionAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Total Transaction Amount</th>
      <td mat-cell *matCellDef="let element">RM {{ setPriceDecimal(element.totalTransactionAmount) }}</td>
    </ng-container>

    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Balance</th>
      <td mat-cell *matCellDef="let element" [ngClass]="{
				'below': element.balance <= 5,
        'above': element.balance > 5
			}">
        <span class="inline-block menu">RM {{ setPriceDecimal(element.balance) }}</span>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div id="pdf"></div>
