import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { CourseService } from '../../services/course.service';
import { DependentService } from '../../../dependent/services/dependent.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.sass'],
})
export class CourseDetailComponent implements OnInit {
  form: FormGroup = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
    schoolId: new FormControl(0)
  });

  course_id: any = null;

  types: any[] = [];
  subtypes: any[] = [];

  levels: any[] = [];
  sublevels: any[] = [];
  public teachers: any[] = [];
  public selectedTeachers: any = [];
  public removedTeachers: any = [];

  public schools: any = [];
  public currentUserRole: any = '';

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CourseDetailComponent>,
    private courseService: CourseService,
    private sharedService: SharedService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private dependentService: DependentService
  ) {
    this.sharedService.getCourseTypes().subscribe((data) => {
      if (data && data.length > 0) {
        this.types = [...data[0]];
        this.subtypes = [...data[1]];
      }
    });

    this.sharedService.getCourseLevel().subscribe((data) => {
      this.levels = data[0];
    });

    this.userService.getUserByType({ type: 3 }).subscribe((data) => {
      this.teachers = data;
    })
  }

  ngOnInit(): void {

    const userDetail = <String>localStorage.getItem('userDetail');
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requestedBy?.usertype_name;

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      schoolId: [0],
    });

    if(this.currentUserRole == 'SUPER ADMIN'){
      this.getSchool();
    }

    if (this.data.course) {
      let tempUserObj = this.data.course;
      this.course_id = tempUserObj.id;
      this.form.controls['name'].setValue(tempUserObj.name);
      this.form.controls['description'].setValue(tempUserObj.description);
      this.form.controls['schoolId'].setValue(tempUserObj?.school_id);
    }
  }

  getSchool(){
    let obj = {
      schoolId: null
    }

    this.dependentService.getSchool(obj).subscribe((response)=> {
      if(response){
        this.schools = response?.data?.result?.filter((item) => { return item?.school_id != 0 });
      }
    },(error)=>{

    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  // changeLevelHandler(): void {
  //   this.sharedService
  //     .getCourseSubLevel({ level_id: this.form.value.level })
  //     .subscribe((data) => {
  //       this.sublevels = data[0];
  //     });
  // }

  submitHandler() {
    let formValue = this.form.value;
    let userDetail = <String>localStorage.getItem("userDetail");
    let requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;
    let prepareObj = {
      id: this.course_id ? this.course_id : null,
      name: formValue.name,
      description: formValue.description,
      requested_by: requestedBy.id,
      schoolId: formValue.schoolId ? formValue?.schoolId : requestedBy.schoolId
    }

    this.courseService.saveCategory(prepareObj).subscribe(async (data) => {

      for (let i = 0; i < this.selectedTeachers.length; i++) {
        if( this.selectedTeachers[i].id == null ){
          let prepareTeachersObj = {
            course_id: data.data[0].id,
            teacher_id: this.selectedTeachers[i].teacher.id,
            requested_id: requestedBy.id
          }
  
          await this.courseService.saveCourseTeacher(prepareTeachersObj).subscribe((dataTeachers) => {
            this.sharedService.showSnackBar(dataTeachers.message);
          }, (error) => {
            this.sharedService.showSnackBar(error);
          });
        }
      }

      for (let i = 0; i < this.removedTeachers.length; i++) {
        let prepareTeachersObj = {
          teacher_id: this.removedTeachers[i].id,
          course_id: data.data[0].id
        }

        await this.courseService.removeCourseTeacher(prepareTeachersObj).subscribe((dataTeachers) => {
          this.sharedService.showSnackBar(dataTeachers.message);
        }, (error) => {
          this.sharedService.showSnackBar(error);
        });
      }

      this.sharedService.showSnackBar(data.message);
      this.close(data);
    });
  }

  getTeachers() {
    let prepareObj = {
      course_id: this.course_id
    }

    this.courseService.getAllCourseTeacherByCourseId(prepareObj).subscribe((data) => {
      let teacherData = data.data ? data.data : [];

      for (let j = 0; j < teacherData.length; j++) {
        let teacher = {
          id: teacherData[j].id,
          teacher: teacherData[j],
          status: 2,
          operation: 'add'
        }
        this.selectedTeachers.push(teacher);
        for (let i = 0; i < this.teachers.length; i++) {
          if (teacherData[j].id == this.teachers[i].id) {
            this.teachers.splice(i, 1);
          }
        }
      }
    }, (error) => {

    });
  }

  addTeachers() {
    let teacherIndex = this.form.controls['teacher_id'].value;
    let teacherData = this.teachers[teacherIndex];
    if (
      teacherData &&
      Object.keys(teacherData).length > 0
    ) {

      let teacherId = null;

      if( 
        this.removedTeachers.length && 
        this.removedTeachers.length > 0
      ){
        for (let i = 0; i < this.removedTeachers.length; i++) {
          if (teacherData.id == this.removedTeachers[i].id) {
            this.removedTeachers.splice(i, 1);
            teacherId = teacherData.id;
          }
        }
      }
      
      let teacher = {
        id: teacherId,
        teacher: teacherData,
        status: 2,
        operation: 'add'
      }

      this.selectedTeachers.push(teacher);
      this.teachers.splice(teacherIndex, 1);

      this.form.controls['teacher_id'].setValue(null);
    }
  }

  removeTeachers(index: number) {

    if (this.selectedTeachers[index].id != null) {
      // this.selectedTeachers[index].status = 1;
      this.removedTeachers.push(this.selectedTeachers[index].teacher);
      this.teachers.push(this.selectedTeachers[index].teacher);
      this.selectedTeachers.splice(index, 1);
    } else {
      this.teachers.push(this.selectedTeachers[index].teacher)
      this.selectedTeachers.splice(index, 1);
    }
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }
}
