<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

    <div id="elearn-layout" class="theme-black">
        <!-- sidebar -->
        <app-leftbar id='leftbar'></app-leftbar>

        <!-- main body area -->
        <div class="main px-lg-4 px-md-4">
            <!-- Body: Header -->
            <app-header></app-header>

            <!-- Body: Body -->
            <router-outlet>
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around start">
                    <div class="custom-card content-container" fxFlex="100">
                        <!-- <div class="content-area__title">
                            <span class="title-font">Study Materials</span>
                        </div> -->
                        <div class="content-area__main">
                            <div>
                                <h4 class=" fw-bold flex-fill title-left">Announcement</h4>
                                <app-content-list></app-content-list>
                            </div>
                        </div>
                    </div>
                </div>
            </router-outlet>
        </div>
    </div>
</body>