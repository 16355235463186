import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PackageManagementComponent } from './pages/package-management/package-management.component';
import { PackageStoreComponent } from './pages/package-store/package-store.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  { path: 'menu/menu-list', component: PackageStoreComponent, canActivate: [AuthGuard]},
  { path: 'menu/menu-management', component: PackageManagementComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PackageRoutingModule { }
