<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

    <div id="elearn-layout" class="theme-black">
        <!-- sidebar -->
        <app-leftbar id='leftbar'></app-leftbar>

        <!-- main body area -->
        <div class="main px-lg-4 px-md-4">
            <!-- Body: Header -->
            <app-header>Topup Your Balance</app-header>

            <!-- Body: Body -->
            <router-outlet>
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around start">
                    <!-- <div class="row" style="width: 100%;">
                        <div class="col-md-12">
                            <div class="card border-0 mb-4 no-bg">
                                
                            </div>
                        </div>
                    </div> -->
                    <div fxFlex="100">
                        <div class="" style="padding-left: 6px;
                        padding-right: 6px;">
                            <div
                                class="card-header py-3 px-0 d-flex align-items-center  justify-content-between border-bottom">
                                <h3 class=" fw-bold flex-fill mb-0"
                                    *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'">
                                    Topup</h3>
                                <h3 class=" fw-bold flex-fill mb-0"
                                    *ngIf="currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER'">Topup
                                </h3>
                            </div>
                            <div class="wrapper">
                                <div *ngIf="currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER'"
                                    class="custom-card upload-form">
                                    <div style="display: flex;
                                    justify-content: flex-end;
                                    margin-bottom: 10px;">
                                        <button *ngIf="!showForm" type="button" class="upload-btn" mat-raised-button (click)="handleForm('open')">Add Topup</button>
                                        <button *ngIf="showForm" type="button" class="cancel-btn" mat-raised-button (click)="handleForm('close')">Cancel</button>
                                    </div>
                                    
                                    <app-upload-form (sendPackageCourse)="getPackageCourseData($event)" *ngIf="showForm"></app-upload-form>
                                    <br />
                                    <div>
                                        <div style="display: flex;"><b>TOTAL TOPUP</b>: RM {{ rechargeTotal ?
                                            convertAmount(rechargeTotal) : 0 }}</div>
                                        <div style="display: flex;"><b>BALANCE AVAILABLE</b>: RM {{
                                            convertAmount(rechargeTotal - totalSpent) }}</div>
                                    </div>
                                </div>
                                <div class="custom-card upload-list">
                                    <app-upload-list [packageCourseData]="packageCourseData"></app-upload-list>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-outlet>
        </div>
    </div>
</body>