import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '../../../../modules/shared/services/shared.service';
import { DependentService } from '../../services/dependent.service';
import { FileUpload } from '../../../../modules/file-upload/models/file-upload.model';
import { FileUploadService } from '../../../../modules/file-upload/services/file-upload.service';
import { NgProgressComponent } from 'ngx-progressbar';
import * as moment from 'moment-timezone';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CourseService } from '../../../course/services/course.service';
import { AlertComponent } from '../../../../modules/shared/components/alert/alert.component';

@Component({
  selector: 'app-dependent-detail',
  templateUrl: './dependent-detail.component.html',
  styleUrls: ['./dependent-detail.component.scss'],
})
export class DependentDetailComponent implements OnInit {

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;
  
  public form: any = null;

  userDetail = <String>localStorage.getItem("userDetail");
  requested_by = this.userDetail ? JSON.parse(this.userDetail.toString()) : null;

  public isSubmitted: boolean = false;
  public schools: any = [];

  public classes: any = [];

  public selectedFiles: any = []; //STORE SELECTED FILES
  public currentFileUpload: FileUpload;
  public percentage: number = 0;
  public isSelectedNewImage: boolean = false;

  public actualPercentage: any = 0;
  public lastPercentage: any = 0;
  public currentIndex: any = 0;
  public count: any = 1;
  public stopUpload: boolean = false;
  public profileImage: any = "assets/images/default-profile.jpg";
  public subscriptionCloseModal: Subscription;

  public loading: boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private dependentService: DependentService,
    private dialogRef: MatDialogRef<DependentDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private courseService: CourseService,
    private dialog: MatDialog
  ) { 
    if( this.data.dependent ) {
      this.form = new FormGroup({
        id: new FormControl(null),
        name: new FormControl(''),
        ic: new FormControl(null),
        school: new FormControl(null),
        class: new FormControl(null),
        cardLimit: new FormControl(5)
      });
    } else if ( 
      this.data?.isAssignCard && 
      this.data?.dependentId 
    ) {
      this.form = new FormGroup({
        id: new FormControl(null),
        card_no: new FormControl(null)
      });
    } else {
      this.form = new FormGroup({
        id: new FormControl(null),
        name: new FormControl(''),
        status: new FormControl(2),
        parent_id: new FormControl(null),
        ic: new FormControl(null),
        school: new FormControl(null),
        class: new FormControl(null),
        cardLimit: new FormControl(5)
      });
    }
    
  }

  ngOnInit(): void {
    this.uploadService.setSubscriptionCloseModal(false);
    this.subscriptionCloseModal = this.uploadService.getSubscriptionCloseModal().subscribe((value: any)=> {
      if(value){
        this.close(value);
      }
    }, (error : any) => {

    });
    if (this.data.dependent) {
      this.form = this.formBuilder.group({
        id: [null],
        name: ['', Validators.required],
        ic: [null, Validators.required],
        school: [null, Validators.required],
        class: [null, Validators.required],
        cardLimit: [5, Validators.required]
      });
    } else if ( 
      this.data?.isAssignCard && 
      this.data?.dependentId 
    ) {
      this.form = this.formBuilder.group({
        id: [this.data?.dependentId, Validators.required],
        card_no: [null, Validators.required]
      });
    } else {
      this.form = this.formBuilder.group({
        id: [null],
        name: ['', Validators.required],
        status: [2, Validators.required],
        parent_id: [null, Validators.required],
        ic: [null, Validators.required],
        school: [null, Validators.required],
        class: [null, Validators.required],
        cardLimit: [5, Validators.required]
      });
      this.form.controls['parent_id'].setValue(this.requested_by.id);
    }

    if (this.data.dependent) {
      let tempUserObj = this.data.dependent;
      this.form.controls['id'].setValue(tempUserObj.id);
      this.form.controls['name'].setValue(tempUserObj.name);
      this.form.controls['ic'].setValue(tempUserObj.ic);
      this.form.controls['school'].setValue(tempUserObj.schoolId);
      this.form.controls['class'].setValue(tempUserObj.class_id);
      this.form.controls['cardLimit'].setValue(tempUserObj.spend_limit);
      this.profileImage = this.data?.dependent?.profile_image_path;
      this.getClass();
    }

    this.getSchool();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  public fileData: any = null;

  onUploadComplete(event: any){
    this.fileData = event;
    console.log(this.fileData)
  }

  submitHandler() {
    this.isSubmitted = true;

    if( 
      !this.data?.isAssignCard &&
      !this.data?.isEdit
    ){
      const dialogRef = this.dialog.open(AlertComponent, {
        data: {
          message: `
          NOTE : ENROLLMENT FOR DEPENDENT IS FOR THE CARD PURPOSES. ONE(1) DEPENDENT ENROLLED WILL BE GETTING ONE CARD. PLEASE FIND THE DISCLOSURE BELOW.
          <br/><br/><br/>
          - PLEASE BE AWARE THAT <b>RM25 WILL BE DEDUCTED</b> FROM YOUR BALANCE FOR CARD PAYMENT.
          <br/><br/>
          - RM25 IS SOLELY FOR CARD PAYMENT ONLY. NOT APPLICABLE FOR ANY TRANSACTION.
          <br/><br/>
          - PLEASE MAKE SURE YOUR DEPENDENT ENROLLED ONLY ONCE FOR EACH CHILD. 
          <br/><br/>
          - YOU MAY ENROLLED MORE THAN ONE(1) DEPENDENT IF YOU HAVE TWO(2) OR MORE CHILDS.
          <br/><br/>
          - PLEASE NOTE THAT A FEE OF RM25 PER STUDENT/ID WILL BE CHARGED FOR THE REPLACEMENT OF ANY CARD THAT IS MISSING OR DAMAGED DUE TO USER ERROR
          <br/><br/>
          - PLEASE DO NOT ENROLLED SAME CHILD. 
          <br/><br/><br/>
          CLICK <b>CONFIRM</b> IF YOU WANT TO PROCEED
          <br/><br/>`,
          type: 'info'
        },
        panelClass: 'dialog-panel'
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (
          result?.data?.confirm == true
        ) {
          if (this.form.valid) {
            this.submitForm();
          }
        } else {
          this.isSubmitted = false;
        }
      });
    } else {
      if (this.form.valid) {
        this.submitForm();
      }
    }
  }

  async submitForm() {
    let formValue = this.form.value;
    
    let prepareObj = null;

    this.loading = true;

    if( 
      this.data?.isAssignCard && 
      this.data?.dependentId 
    ){
      const object = {
        id: this.data?.dependentId,
        card_no: formValue.card_no,
        parent_id: this.data?.parentId,
        name: this.data?.dependentName,
        new_card: this.data?.isNewCard
      }

      if( this.data?.isNewCard ){
        const validateDeposit = await this.validateDeposit();

        if( !validateDeposit ){
          this.loading = false;
          return;
        }
      }

      this.dependentService.assignCardDependent(object).subscribe( (data) => {
        this.loading = false;
        this.sharedService.showSnackBar(data.message);
        this.close(data);
      }, (error) =>{
        this.loading = false;
        this.sharedService.showSnackBar(error);
      });
      
    } else {
      if (this.data.dependent) {

        if( 
          this.selectedFiles?.length == 0 &&
          this.isSelectedNewImage
        ){
          this.sharedService.showSnackBar('Dependent picture is required.');
          this.loading = false;
          return;
        }

        prepareObj = {
          id: formValue.id,
          name: formValue.name,
          requested_by: this.requested_by.id,
          ic: formValue.ic,
          school: Number(formValue.school),
          class: Number(formValue.class),
          card_limit: formValue?.cardLimit,
          profile_image_name: this.data?.dependent?.profile_image_name,
          profile_image_base_path: 'dependent-image',
          profile_image_path: this.data?.dependent?.profile_image_path,
          profile_image_mime: this.data?.dependent?.profile_image_mime,
          profile_image_size: this.data?.dependent?.profile_image_size
        }
      } else {

        const validateDeposit = await this.validateDeposit();

        if( !validateDeposit ){
          this.loading = false;
          return;
        }

        if( this.selectedFiles?.length == 0 ){
          this.sharedService.showSnackBar('Dependent picture is required.');
          this.loading = false;
          return;
        }

        prepareObj = {
          id: formValue.id,
          name: formValue.name,
          status: formValue.status,
          parent_id: formValue.parent_id,
          requested_by: this.requested_by.id,
          ic: formValue.ic,
          school: Number(formValue.school),
          class: Number(formValue.class),
          card_limit: formValue?.cardLimit,
          profile_image_name: null,
          profile_image_base_path: 'dependent-image',
          profile_image_path: null,
          profile_image_mime: null,
          profile_image_size: null
        }
      }

      if (
        this.selectedFiles &&
        this.selectedFiles.length > 0
      ) {
        this.upload(prepareObj);
      } else {
        this.uploadService.registerDependent(null, null, prepareObj);
      }
    }
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }

  selectFile(event: any): void {
    this.isSelectedNewImage = true;
    this.selectedFiles = Array.from(event.target.files);
    this.stopUpload = false;
    const validImageFiles = [];

    for (const file of this.selectedFiles) {
      if (file.type.startsWith('image/')) {
        // Check if the file name has a file extension
        if (!(/\.[^/]+$/.test(file.name))) {
          alert(`File ${file.name} doesn't have a file extension.`);
          continue; // Skip this file
        }
        const currentDatetime = moment().utc().format('YYYYMMDDHHmmss');
        const renamedFile = new File([file], `${currentDatetime}_${file.name}`, { type: file.type });
        validImageFiles.push(renamedFile);
      } else {
        alert(`File ${file.name} is not an allowed image type.`);
      }
    }

    if (validImageFiles.length > 0) {
      this.profileImage = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(validImageFiles[0])
      );
    } else {
      this.profileImage = "assets/images/default-profile.jpg";
    }

    this.selectedFiles = validImageFiles;
  } 
  
  upload(
    payloadObject: any
  ): void {
    if (this.form.valid) {
      this.isSubmitted = true;

      if (this.stopUpload == false) {
        const totalFilesUpload = this.selectedFiles.length;
        const file: any = this.selectedFiles[this.currentIndex];
        this.currentFileUpload = new FileUpload(file);
        let basePath = 'dependent-image';
        let subscribe = this.uploadService.pushFileToStorage(
          this.currentFileUpload,
          basePath,
          null,
          null,
          payloadObject,
          null,
          null
        ).subscribe(
          (percentage: any) => {
            this.percentage = (this.actualPercentage + Math.round(percentage)) / totalFilesUpload;

            if (
              percentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = percentage;
              this.progressBar.set(this.percentage);
            }

            if (
              percentage == 100 &&
              this.lastPercentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = 100;
              this.actualPercentage += 100;
              if (this.count == totalFilesUpload) {
                this.stopUpload = true;
                this.selectedFiles = null;
                this.count = 1;
                this.currentIndex = 0;
                this.actualPercentage = 0;
                this.percentage = 0;
                this.progressBar.complete();
                this.isSubmitted = false;
                this.loading = false;
              } else {
                this.currentIndex++;
                this.count++;
                subscribe.unsubscribe();
              }
            }
          },
          (error: any) => {
            this.loading = false;
            console.log(error);
          }
        );
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  removeFile(i: any) {
    this.selectedFiles.splice(i, 1);
    if (this.selectedFiles.length == 0) {
      this.selectedFiles = undefined;
    }
  }

  trimFileName(fileName: any) {
    return fileName.substr(15);
  }

  getClass(){
    let formValue = this.form.value;

    formValue.class = null;

    const obj = {
      classId: null,
      schoolId: formValue?.school ? Number(formValue?.school) : null
    }

    this.dependentService.getClass(obj).subscribe((response)=> {
      if(response){
        this.classes = response?.data?.result;
      }
    },(error)=>{

    });
  }

  getSchool(){
    let obj = {
      schoolId: null
    }

    this.dependentService.getSchool(obj).subscribe((response)=> {
      if(response){
        this.schools = response?.data?.result?.filter((item) => { return item?.school_id != 0 });
      }
    },(error)=>{

    });
  }

  validateDeposit(){
    let promise = new Promise((resolve, reject) => {
      const statisticObj = {
        parentId: this.data?.parentId ? this.data?.parentId : this.sharedService.getCurrentUser().id
      }
  
      this.courseService.getStatistic(statisticObj).subscribe( (res) => {
        if( res?.status?.code == 200 ){
          if( res?.data?.balance >= 25 ) {
            resolve(true);
          } else {
            this.sharedService.showSnackBar('Topup balance low. Minimum RM 25 for new card payment required.');
            resolve(false);
          }
        } else {
          this.sharedService.showSnackBar(res.message);
          resolve(false);
        }
      }, (error) => {
        resolve(false);
      });
    });

    return promise;
  }
}
