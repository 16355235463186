import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserFormComponent } from '../../components/user-form/user-form.component';
import { UserService } from '../../services/user.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  userList: any;

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  constructor(
    private layoutServiceService: LayoutServiceService,
    public dialog: MatDialog,
    private userService: UserService
  ) { 
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    this.getUserList();
  }

  getUserList() {
    this.userService.getAllUser().subscribe( data => {
      this.userList = data;
    } );
  }

  openNewUser() {
    const dialogRef = this.dialog.open( UserFormComponent, {
      data: {
        fromLogin: false
      },
      panelClass: 'dialog-panel'
    } );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUserList();
      }
    });
  }

  userUpdatedHandler(eventObj: any) {
    this.getUserList();
  }
}
