import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { TeacherService } from '../../services/teacher.service';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { FileUpload } from '../../../file-upload/models/file-upload.model';
import { NgProgressComponent } from 'ngx-progressbar';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-teacher-detail',
  templateUrl: './teacher-detail.component.html',
  styleUrls: ['./teacher-detail.component.scss']
})
export class TeacherDetailComponent implements OnInit {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  public form: FormGroup = new FormGroup({
    user_id: new FormControl(null),
    subject: new FormControl(null),
    experience_year: new FormControl(null),
    qualification: new FormControl(null),
    achievements: new FormControl(null),
    experiences: new FormControl(null),
    classification: new FormControl(2)
  });

  public teacherId: any = null;

  public teacherObj: any = null;

  public achievements: any[] = [];
  public experiences: any[] = [];

  public isSubmit: boolean = false;
  public selectedFiles: any = []; //STORE SELECTED FILES
  public currentFileUpload: FileUpload;
  public uploadedFile: any = []; //GET FILE STORED IN DB
  public deletedFile: any = []; //STORE FILE TO BE DELETED
  public percentage: number = 0;

  public actualPercentage: any = 0;
  public lastPercentage: any = 0;
  public currentIndex: any = 0;
  public count: any = 1;
  public stopUpload: boolean = false;

  public currentUserRole: any = null;
  public teachers: any = null;

  public subscriptionCloseModal: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TeacherDetailComponent>,
    private sharedService: SharedService,
    private teacherService: TeacherService,
    private uploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    this.uploadService.setSubscriptionCloseModal(false);
    this.subscriptionCloseModal = this.uploadService.getSubscriptionCloseModal().subscribe((value: any)=> {
      if(value){
        this.close(value);
      }
    }, (error : any) => {

    });
    this.teacherObj = this.data && this.data.teacher ? this.data.teacher : null;
    this.currentUserRole = this.sharedService.getCurrentUser().usertype_name;

    if (this.teacherObj) {
      let tempTeacherObj = this.teacherObj;

      this.teacherId = tempTeacherObj.id;
      this.uploadedFile = {
        name: tempTeacherObj.video_name,
        base_path: tempTeacherObj.video_base_path,
        path: tempTeacherObj.video_path,
        mime: tempTeacherObj.video_mime,
        size: tempTeacherObj.video_size
      };
      this.form.controls['user_id'].setValue(tempTeacherObj.user_id);
      this.form.controls['subject'].setValue(tempTeacherObj.subject);
      this.form.controls['experience_year'].setValue(tempTeacherObj.total_experience);
      this.form.controls['qualification'].setValue(tempTeacherObj.qualification);
      this.form.controls['classification'].setValue(tempTeacherObj.classification);

      this.getAchievementExperience(this.teacherId);
    } else if ( this.currentUserRole == "TEACHER") {
      this.form.controls['user_id'].setValue(this.sharedService.getCurrentUser().id);
    } else if ( 
      this.currentUserRole == "ADMIN" || 
      this.currentUserRole == "SUPER ADMIN"
    ) {
      this.teacherService.getUnsetTeacherProfile().subscribe( (data) => {
        this.teachers = data;
      });
    }
  }

  getAchievementExperience(teacher_id: number) {
    let prepareObj = {
      id: teacher_id
    }

    this.teacherService.getAchievementExperience(prepareObj).subscribe((data) => {
      this.achievements = data.data[0];
      this.experiences = data.data[1];
    })
  }

  addAchievement() {
    let achievementField = this.form.controls['achievements'].value;
    if (achievementField && achievementField.length > 0) {
      let achievement = {
        id: null,
        achievement: achievementField,
        status: 2,
        operation: 'add'
      }

      this.achievements.push(achievement);

      this.form.controls['achievements'].setValue(null);
    }
  }

  removeAchievement(index: number) {

    if (this.achievements[index].id != null) {
      this.achievements[index].status = 1;
    } else {
      this.achievements.splice(index, 1);
    }
  }

  addExperience() {
    let experienceField = this.form.controls['experiences'].value;
    if (experienceField && experienceField.length > 0) {
      let experience = {
        id: null,
        experience: experienceField,
        status: 2,
        operation: 'add'
      }

      this.experiences.push(experience);

      this.form.controls['experiences'].setValue(null);
    }
  }

  removeExperience(index: number) {

    if (this.experiences[index].id != null) {
      this.experiences[index].status = 1;
    } else {
      this.experiences.splice(index, 1);
    }
  }

  ngOnDestroy(){
    this.subscriptionCloseModal.unsubscribe();
  }

  submitHandler() {
    this.isSubmit = true;
    let formValue = this.form.value;
    let payloadObject = {
      id: this.teacherId,
      user_id: formValue.user_id,
      subject: formValue.subject,
      total_experience: formValue.experience_year,
      qualification: formValue.qualification,
      requested_by: this.sharedService.getCurrentUser().id,
      video_name: null,
      video_base_path: "teacher-profile",
      video_path: null,
      video_mime: null,
      video_size: null,
      classification: 2
    }

    if( 
      this.currentUserRole == "ADMIN" || 
      this.currentUserRole == "SUPER ADMIN" 
    ){
      payloadObject.classification = formValue.classification;
    }

    if (
      this.uploadedFile &&
      this.uploadedFile.name
    ) {
      payloadObject.video_name = this.uploadedFile.name;
      payloadObject.video_path = this.uploadedFile.path;
      payloadObject.video_mime = this.uploadedFile.mime;
      payloadObject.video_size = this.uploadedFile.size;
    }

    if (
      this.deletedFile &&
      this.deletedFile[0] &&
      this.deletedFile[0].name
    ) {
      payloadObject.video_name = null;
      payloadObject.video_path = null;
      payloadObject.video_mime = null;
      payloadObject.video_size = null;
      this.uploadService.deleteFileStorage(this.deletedFile[0].name, "teacher-profile");
    }

    if (
      this.selectedFiles &&
      this.selectedFiles.length > 0
    ) {
      this.upload(payloadObject, this.achievements, this.experiences);
    } else {
      this.uploadService.saveUpdateTeacherData(null, null, payloadObject, this.achievements, this.experiences);
    }

  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }

  selectFile(event: any): void {
    this.selectedFiles = Array.from(event.target.files);
    this.stopUpload = false;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      let currentdatetime = moment().utc().format('YYYYMMDDHHmmss');
      this.selectedFiles[i] = new File([this.selectedFiles[i]], `${currentdatetime}_${this.selectedFiles[i].name}`, { type: this.selectedFiles[i].type });
    }
  }

  upload(
    payloadObject: any,
    achievements: any,
    experiences: any
  ): void {
    if (this.form.valid) {
      this.isSubmit = true;

      if (this.stopUpload == false) {
        const totalFilesUpload = this.selectedFiles.length;
        const file: any = this.selectedFiles[this.currentIndex];
        this.currentFileUpload = new FileUpload(file);
        let basePath = 'teacher-profile';
        let subscribe = this.uploadService.pushFileToStorage(
          this.currentFileUpload,
          basePath,
          null,
          null,
          payloadObject,
          achievements,
          experiences
        ).subscribe(
          percentage => {
            this.percentage = (this.actualPercentage + Math.round(percentage)) / totalFilesUpload;

            if (
              percentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = percentage;
              this.progressBar.set(this.percentage);
            }

            if (
              percentage == 100 &&
              this.lastPercentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = 100;
              this.actualPercentage += 100;
              if (this.count == totalFilesUpload) {
                this.stopUpload = true;
                this.selectedFiles = null;
                this.count = 1;
                this.currentIndex = 0;
                this.actualPercentage = 0;
                this.percentage = 0;
                this.progressBar.complete();
                this.isSubmit = false;
              } else {
                this.currentIndex++;
                this.count++;
                subscribe.unsubscribe();
              }
            }
          },
          error => {
            console.log(error);
          }
        );
      }
    } else {

    }
  }

  removeFile(i: any) {
    this.selectedFiles.splice(i, 1);
    if (this.selectedFiles.length == 0) {
      this.selectedFiles = undefined;
    }
  }

  trimFileName(fileName: any) {
    return fileName.substr(15);
  }

  deleteFile() {
    if (
      this.uploadedFile &&
      this.uploadedFile.base_path == "teacher-profile"
    ) {
      this.deletedFile.push(this.uploadedFile);
      this.uploadedFile = [];
      if (this.selectedFiles.length == 0) {
        this.selectedFiles = undefined;
      }
    }
  }

}
