import { Component, Inject, OnInit, TemplateRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from 'src/app/modules/session/services/session.service';
import { PackageService } from '../../../package/services/package.service';

import * as moment from 'moment';
import { SharedService } from '../../../../modules/shared/services/shared.service';
import { GlobalService } from '../../../../services/global.service';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import "moment-timezone";
import { AlertComponent } from '../../../shared/components/alert/alert.component';
import { Router } from '@angular/router';
import { CustomDateFormatter } from '../../custom-date-formatter.provider';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],

})
export class ScheduleListComponent implements OnInit {

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public view: CalendarView = CalendarView.Week;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();

  public modalData: {
    action: string;
    event: CalendarEvent;
  };

  public actions: CalendarEventAction[] = [];
  public refresh = new Subject<void>();
  public events: any[] = [];
  public eventsList: any[] = [];
  public activeDayIsOpen: boolean = true;

  public timezone: any = null;
  public packageSessions: any[] = [];

  public packages: any = [];

  public userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));
  public dependentDetail = localStorage.getItem('dependentDetail') ? JSON.parse(<string>localStorage.getItem('dependentDetail')) : null;

  public globalLevel: any = localStorage.getItem('global_level') ? Number(localStorage.getItem('global_level')) : null;
  public globalSubLevel: any = localStorage.getItem('global_sublevel') ? Number(localStorage.getItem('global_sublevel')) : null;

  public screenWidth = window.innerWidth;

  constructor(
    private layoutServiceService: LayoutServiceService,
    public dialog: MatDialog,
    private sessionService: SessionService,
    private ref: ChangeDetectorRef,
    private packageService: PackageService,
    private sharedService: SharedService,
    private router: Router,
    private globalService: GlobalService
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngOnInit(): void {
    this.getPackages();
    if (window.innerWidth <= 480) {
      this.view = CalendarView.Month;
      this.activeDayIsOpen = false;
    }
  }

  getPackages() {
    let packageObject = { 
      level: this.globalLevel,
      sublevel: this.globalSubLevel
    }
    return this.packageService.getAllPackage(packageObject).subscribe((data) => {
      this.packages = data.data;
      this.events = [];
      for(let i = 0; i < this.packages.length; i++){
        this.getPackageSession(this.packages[i]);
      }
    });
  }

  getPackageSession(packageData: any) {
    this.packageSessions = [];

    let payload = { 
      package_id: null,
      teacher_id: null,
      parent_id: null,
      course_sub_level: 0
    }

    if( 
      this.userDetail.usertype_name == "ADMIN" ||
      this.userDetail.usertype_name == "SUPER ADMIN"
    ) {
      payload.package_id = packageData.id;
      payload.teacher_id = null;
    } else if ( this.userDetail.usertype_name == "TEACHER" ) {
      payload.package_id = packageData.id;
      payload.teacher_id = this.userDetail.id;
    } else if ( 
      this.userDetail.usertype_name == "PARENTS" &&
      !this.dependentDetail
    ) {
      payload.package_id = packageData.id;
      payload.course_sub_level = 0;
      payload.parent_id = this.userDetail.id;
    } else if ( 
      this.userDetail.usertype_name == "PARENTS" &&
      this.dependentDetail
    ) {
      payload.package_id = packageData.id;
      payload.course_sub_level = this.globalSubLevel;
      payload.parent_id = this.userDetail.id;
    }

    this.sessionService.getAllSession(payload).subscribe((data) => {
      this.packageSessions = data.data;
      const primaryColor = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
      const secondaryColor = primaryColor;

      this.packageSessions.forEach(session => {
        let startDate = moment.utc(packageData.start_date).tz(this.timezone).format('YYYY-MM-DD');
        let dueDate = moment.utc(packageData.due_date).tz(this.timezone).format('YYYY-MM-DD');
        let startTime = moment.utc(`${startDate} ${session.start_time}`).tz(this.timezone).format('HH:mm:ss');
        let endTime = moment.utc(`${dueDate} ${session.end_time}`).tz(this.timezone).format('HH:mm:ss');
        let reformatStartTime = moment.utc(`${startDate} ${startTime}`).format('hh:mm A');
        let reformatEndTime = moment.utc(`${dueDate} ${endTime}`).format('hh:mm A');
        let day = session.day;
        let duration = packageData.duration;

        // let transactionDate = packageData.transactionDate ? moment.utc(packageData.transactionDate).tz(this.timezone).format('YYYY-MM-DD') : null;

        let dateTimes = this.getDaysBetweenDates(startDate, dueDate, startTime, endTime, day, duration);
        
        for (let j = 0; j < dateTimes.length; j++) {
          let dates = dateTimes[j];
          let message = this.setClassMessages(dates.start, dates.end, session.meeting_number);

          this.events.push({
            start: dates.start,
            end: dates.end,
            title: `
              <span>
              ${message} ${session.course_name}
              </span>
              <br/>
              <span class="title-schedule">
                <span class="title-color-start">${reformatStartTime}</span> - 
                <span class="title-color-end">${reformatEndTime}</span>
              </span>
            `,
            title_tooltip: `${session.course_name}`,
            color: {
              primary: primaryColor,
              secondary: secondaryColor,
            },
            allDay: false,
            classUrl: session.url,
            meetingNumber: session.meeting_number,
            meetingPassword: session.meeting_password,
            message: message
          });
        }

        this.refresh.next();
      });
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      if (this.screenWidth <= 480) {
        this.activeDayIsOpen = false;
        
        this.eventsList = this.events.filter( (el) => {
          let startDateTemp = moment(el.start).toDate();
          return startDateTemp.getDate() == date.getDate() && startDateTemp.getFullYear() == date.getFullYear() &&startDateTemp.getMonth() == date.getMonth();
        });
      }

      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    this.modalData = { event, action };

    let actualStartTime = null;
    let minutesEarlyStart = 0;

    if (this.userDetail.usertype_name == "TEACHER") {
      minutesEarlyStart = 15;
    } else {
      minutesEarlyStart = 10;
    }

    actualStartTime = moment(event.start).subtract(minutesEarlyStart, 'minutes');
    let actualEndTime = moment(event.end);
    const validateSameCurrentDate = moment().isSame(actualStartTime, 'date');
    const validateAfterCurrentDate = moment().isAfter(actualStartTime, 'date');
    const validateBeforeCurrentDate = moment().isBefore(actualStartTime, 'date');
    const validateCurrentStartDateTime = moment().isSameOrAfter(actualStartTime);
    const validateCurrentEndDateTime = moment().isSameOrBefore(actualEndTime);

    if ( validateSameCurrentDate ) {
      if( 
        validateCurrentStartDateTime &&
        validateCurrentEndDateTime
      ){
        if( event.meetingNumber ){
          this.confirmJoinClass(event);
        } else {
          this.sharedService.showSnackBar('Class not open yet');
        }
      } else if( validateCurrentStartDateTime == false ) {
        this.sharedService.showSnackBar(`Class will open ${minutesEarlyStart} minutes before started`);
      } else {
        this.sharedService.showSnackBar(`Class ended. You may wait for next class.`);
      }
    } else if ( validateBeforeCurrentDate ) {
      this.sharedService.showSnackBar(`Class will open ${minutesEarlyStart} minutes before started`);
    } else if ( validateAfterCurrentDate ) {
      this.sharedService.showSnackBar(`Class ended. You may wait for next class.`);
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getDaysBetweenDates(
    startDate: any = null, 
    endDate: any,
    startTime: any,
    endTime: any,
    day: any,
    duration: any
  ) {

    let now = startDate ? moment(startDate) : moment();
    let end = now.clone().add(duration - 1, 'days');
    let dates = [];
    let current = now.clone().subtract(7, 'days');
    // let startDateDay = current.day();
    // let endDateDay = end.day();
    let actualDay = 6 + day;

    while (current.day(actualDay).isSameOrBefore(end)) {
      let reformatDate = current.clone().format('YYYY-MM-DD');

      if(now.isSameOrBefore(current)){
        dates.push({
          start: new Date(moment(`${reformatDate} ${startTime}`).toISOString()),
          end: new Date(moment(`${reformatDate} ${endTime}`).toISOString())
        });
      }
    }
    return dates;
  };

  confirmJoinClass(classData: any): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: `You are about to join <p><b>${classData.title}<b><p>`,
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(
        result &&
        result.data &&
        result.data.confirm == true
      ){
        const meetingNumber = classData.meetingNumber;
        const meetingPassword = classData.meetingPassword;

        this.goToZoomMeeting(meetingNumber,meetingPassword);
      }
    });
  }

  goToZoomMeeting(
    meetingNumber: any,
    password: any
  ) {
    const leaveUrl = this.router.url;
    this.globalService.meetingNumber = meetingNumber;
    this.globalService.password = password;
    this.globalService.leaveUrl = leaveUrl;

    this.router.navigate(['/class/class-meeting']);
  }

  setClassMessages(startDate: any, dueDate: any, meetingNumber: any) {
		let actualStartTime = null;
		let minutesEarlyStart = 0;

		if (this.userDetail.usertype_name == "TEACHER") {
			minutesEarlyStart = 15;
		} else {
			minutesEarlyStart = 10;
		}

		actualStartTime = moment(startDate).subtract(minutesEarlyStart, 'minutes');
		let actualEndTime = moment(dueDate);
		const validateSameCurrentDate = moment().isSame(actualStartTime, 'date');
		const validateAfterCurrentDate = moment().isAfter(actualStartTime, 'date');
		const validateBeforeCurrentDate = moment().isBefore(actualStartTime, 'date');
		const validateCurrentStartDateTime = moment().isSameOrAfter(actualStartTime);
		const validateCurrentEndDateTime = moment().isSameOrBefore(actualEndTime);

		if (validateSameCurrentDate) {
			if (
				validateCurrentStartDateTime &&
				validateCurrentEndDateTime
			) {
				if (meetingNumber) {
					return "<span class='class-open-schedule'>Open <i class='fa fa-chevron-right'></i></span>";
				} else {
					return "<span class='class-preparing-schedule'>Preparing</span>";
				}
			} else if (validateCurrentStartDateTime == false) {
				return "<span class='class-upcoming-schedule'>Upcoming</span>";
			} else {
				return "<span class='class-ended-schedule'>Ended</span>";
			}
		} else if (validateBeforeCurrentDate) {
			return "<span class='class-upcoming-schedule'>Upcoming</span>";
		} else if (validateAfterCurrentDate) {
			return "<span class='class-ended-schedule'>Ended</span>";
		};
	}

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth
  }
}
