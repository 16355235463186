import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { UserService } from '../../../user/services/user.service';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { FileUpload } from '../../../file-upload/models/file-upload.model';
import { NgProgressComponent } from 'ngx-progressbar';
import * as moment from 'moment-timezone';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Subscription } from 'rxjs';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-dependent-list',
  templateUrl: './dependent-list.component.html',
  styleUrls: ['./dependent-list.component.scss'],
})
export class DependentListComponent implements OnInit {

  public dependents: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DependentListComponent>,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public sharedService: SharedService
  ) {
    this.dependents = this.data?.dependents;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(){
  }

  async submitHandler() {
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  selectDependent(i: any){
    const selectedDependent = this.dependents[i];

    this.dialogRef.close({
      dependent: selectedDependent
    });
  }
}
