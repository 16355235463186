import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseDetailComponent } from '../course-detail/course-detail.component';
import { SharedService } from '../../../shared/services/shared.service';
import { CourseService } from '../../services/course.service';

@Component({
  selector: 'course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.sass']
})
export class CourseCardComponent implements OnInit {
  @Input() courses: any = [];
  @Input() isPast: any
  @Output() onUpdateCourse = new EventEmitter<any>();
  
  public currentUserRole: any = '';

  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private courseService: CourseService
  ) { }

  ngOnInit(): void {
    const userDetail = <String>localStorage.getItem('userDetail');
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requestedBy?.usertype_name;
  }

  viewSession(id: number) {

  }

  viewCourse(course: any) {
    const dialogRef = this.dialog.open(CourseDetailComponent, {
      data: { course: course},
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onUpdateCourse.emit(result);
    });
  }

  managePublishCategory(
    categoryData: any,
    isPublished: boolean
  ){
    const categoryObj = {
      categoryId: categoryData?.id,
      isPublished: isPublished
    };

    this.courseService.managePublishCategory(categoryObj).subscribe((response)=>{
      if( response ){
        this.onUpdateCourse.emit(response);
      }
    },(error)=>{
      this.sharedService.showSnackBar(error);
    });
  }

}
