import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  public imagePath: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.imagePath = this.data.imagePath;
  }

  ngOnInit(): void {
  }

}
