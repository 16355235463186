<div fxLayout="column wrap" fxLayoutAlign="space-around center">
  <div fxFlex="100" class="full-width">
    <form [formGroup]="form">
      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Category Name</mat-label>
          <input matInput type="text" formControlName="name" placeholder="Category name" />
          <mat-error *ngIf="f['name'].errors">Category name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="currentUserRole == 'SUPER ADMIN'">
        <label>School</label>
        <br />
        <select formControlName="schoolId" class="form-select pointer" aria-placeholder="Choose School">
          <option value="">Select One</option>
          <option *ngFor="let school of schools" [value]="school['school_id']">
            {{ school['school_name'] }}
          </option>
        </select>
      </div>
      <br />
      <div class="form-group">
        <button type="submit" mat-raised-button class="full-width" color="primary" (click)="submitHandler()">
          {{ course_id ? "Update" : "Create" }}
        </button>
      </div>
    </form>
  </div>
</div>