import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContentService } from '../../services/content.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-video-content',
  templateUrl: './video-content.component.html',
  styleUrls: ['./video-content.component.scss']
})
export class VideoContentComponent implements OnInit {
  
  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public videoContents: any = [];
  public typeName: any = 'video';
  public selectedVideo: any = null;

  public limit: number = 10;
  public offset: number = 0;
  public pageIndex: number = 0;
  public totalResult: number = 0;
  public pageSizeOptions = [10, 20, 30];

  constructor(
    private layoutServiceService: LayoutServiceService,
    private sharedService: SharedService,
    private contentService: ContentService
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    this.getContentByType();
  }

  getContentByType() {

    const contentObject = {
      typeName: this.typeName,
      limit: this.limit,
      offset: this.offset
    };

    this.contentService.getContent(contentObject).subscribe((response) => {
      this.videoContents = response.data.result;
      this.totalResult = response.data.totalResult;
    }, (error) => {

    });
  }

  decodeDescription(description: any) {
    let decodeDescriptionString = decodeURI(description);
    return decodeDescriptionString;
  }

  viewVideo(videoPath: any){
    this.selectedVideo = videoPath;
  }

  handlePageEvent(event: PageEvent) {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex*this.limit;
    console.log(event)
    this.getContentByType();
  }

}
