import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BalanceReportService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getBalanceReport(payloadObject: any = {}): Observable<any> {
    return this.http.post(`${environment.apiURL}/balance-report`, payloadObject, this.httpOptions);
  }

  checkBalanceByCardNo(payloadObject: any = {}): Observable<any> {
    return this.http.post(`${environment.apiURL}/check-balance-card-no`, payloadObject, this.httpOptions);
  }
}
