import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TeacherDetailComponent } from '../../components/teacher-detail/teacher-detail.component';
import { TeacherService } from '../../services/teacher.service';
import { SharedService } from '../../../shared/services/shared.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-teacher-management',
  templateUrl: './teacher-management.component.html',
  styleUrls: ['./teacher-management.component.sass']
})
export class TeacherManagementComponent implements OnInit {

  public teachers: any[] = [];

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));

  constructor(
    public dialog: MatDialog,
    private teacherService: TeacherService,
    private sharedService: SharedService,
    private layoutServiceService: LayoutServiceService
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
    // this.sharedService.setSubscriptionHeaderTitle('Teacher Management');
  }

  ngOnInit(): void {
    this.getTeacher();
  }

  getTeacher() {
    let prepareObj = {
      id: null
    }
    if( this.userDetail.usertype_name == "TEACHER" ){
      prepareObj = {
        id: this.userDetail.id
      }
    } 

    this.teacherService.getTeacher(prepareObj).subscribe((data) => {
      this.teachers = data.data;
    })
  }

  addNewTeacher() {
    const dialogRef = this.dialog.open(TeacherDetailComponent, {
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getTeacher();
    });
  }

  saveTeacherHandler() {
    this.getTeacher();
  }

}
