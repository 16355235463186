import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DependentListComponent } from './pages/dependent-list/dependent-list.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  {path: 'dependent/list', component: DependentListComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DependentRoutingModule { }
