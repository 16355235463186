<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div fxLayout="row wrap" fxLayoutAlign="space-around start">
          <div fxFlex="55">
            <div style="display: flex; justify-content: center; align-items: center;" *ngIf="scanRFID || isCheckBalance">
              <img width="350" height="350" [src]="'./assets/icons/scan-rfid.png'"/>
            </div>
            <div *ngIf="!scanRFID && !isCheckBalance" style="max-height: 350px;overflow: auto;">
              <div *ngIf="menus?.length == 0" class="category-wrapper" style="margin: 10px 0 10px 0;">
                <div *ngFor="let category of categories" (click)="getMenu(category?.id)" class="category">
                  <h3 style="text-align: center;">{{category?.name}}</h3>
                </div>
              </div>
              <div *ngIf="menus?.length > 0" class="menu-wrapper">
                <div class="menu" (click)="getCategory()">
                  <h3><i class="icofont-arrow-left" style="font-size: 60pt;"></i></h3>
                </div>
                <div *ngFor="let menu of menus" (click)="addToCart(menu)" class="menu">
                  <h3 style="text-align: center;">{{menu?.title}}</h3>
                  <span>RM {{menu?.fee}}</span>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="45"class="purchase-area">
            <div class="full-width custom-card menu-list">
              <div class="full-width menu-table">
                <table class="cart-table">
                  <tr>
                    <th> Item </th>
                    <th> Price (RM) </th>
                  </tr>
                  <tr *ngFor="let cart of carts" (click)="removeItem(cart)">
                    <td> {{cart?.packageTitle}} </td>
                    <td> {{cart?.fee}} </td>
                  </tr>
                </table>
              </div>
              <div class="menu-total">
                <span>Total: RM {{totalFee}}</span>
              </div>
            </div>
            <div class="full-width custom-card">
              <div class="full-width">
                <div></div>
              </div>
              <div class="full-width footer-div">
                <div *ngIf="dependent?.length == 0">
                  <span *ngIf="balanceData?.length > 0 && isCheckBalance">Balance: RM {{balanceData[0]?.balance}}</span>
                  <form [formGroup]="validateStudentForm">
                    <input type="text" formControlName="searchText" placeholder="Student Name" style="width: 100%;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    padding-left: 5px;" *ngIf="showMissingCard"/>
                    <button class="btn bg-success btn-sm text-white rounded full-width proceed-payment focus-btn" [disabled]="carts?.length == 0" *ngIf="!showMissingCard && !scanRFID && !isCheckBalance" (click)="handleIsScanRFID()">
                      Scan Card
                    </button>
                    <button class="btn bg-danger btn-sm text-white rounded full-width proceed-payment" [disabled]="carts?.length == 0" *ngIf="!showMissingCard && scanRFID && !isCheckBalance" (click)="removeDependent()">
                      Cancel Scan Card
                    </button>
                    <button class="btn bg-info btn-sm text-white rounded full-width proceed-payment cancel-btn" *ngIf="!showMissingCard && !isCheckBalance" (click)="handleIsCheckBalance()">
                      Check Balance
                    </button>
                    <button class="btn btn-sm text-white rounded full-width proceed-payment missing-btn" (click)="handleMissingCard()" [disabled]="carts?.length == 0" *ngIf="!showMissingCard && !isCheckBalance">
                      Search Card ( Missing / Manual )
                    </button>
                    <button class="btn bg-success btn-sm text-white rounded full-width proceed-payment" (click)="searchDependent($event)" [disabled]="carts?.length == 0" *ngIf="showMissingCard && !isCheckBalance">
                      Search
                    </button>
                    <button class="btn bg-danger btn-sm text-white rounded full-width proceed-payment cancel-btn" (click)="removeDependent()" *ngIf="showMissingCard || isCheckBalance">
                      Cancel
                    </button>
                  </form>
                  
                  <ng-template #customLoadingTemplate>
                    <div class="custom-class">
                        <h3>Validating...</h3>
                    </div>
                  </ng-template>
                  <ngx-loading [show]="isValidateLoading" [config]="{ backdropBorderRadius: '3px' }"
                      [template]="customLoadingTemplate"></ngx-loading>
                </div>
                <div *ngIf="dependent?.length > 0">
                  <button class="btn bg-danger btn-sm text-white rounded full-width proceed-payment notify-btn" (click)="handleNotifyMissing()" *ngIf="showMissingCard">
                    Notify Missing Card
                  </button>
                  <div class="dependent pointer" (click)="removeDependent()">
                    <div class="dependent-image">
                      <img [src]="dependent[0]?.profile_image_path" *ngIf="dependent[0]?.profile_image_path" class="custom-profile shadow-sm"
                        [src]="dependent[0]?.profile_image_path" alt="profile">
                      <img [src]="dependent[0]?.profile_image_path" *ngIf="!dependent[0]?.profile_image_path" class="custom-profile shadow-sm"
                        src="assets/images/default-profile.jpg" alt="profile">
                    </div>
                    <div class="dependent-details">
                      <span>{{dependent[0]?.name}}</span>
                      <span style="font-weight: normal;font-size: 8.5pt;">{{dependent[0]?.school}}</span>
                      <span>Limit Spent: <br/><span style="font-weight: normal;">RM {{ calculateBalanceLimit() }}/RM {{reformatCurrency(dependent[0]?.spend_limit)}}</span></span>
                      <span>Balance: <br/><span style="font-weight: normal;">RM {{ balanceData[0]?.balance }}</span></span>
                    </div>
                  </div>
                  <button class="btn bg-info btn-sm text-white rounded full-width proceed-payment focus-btn" *ngIf="!isLoading" #checkoutBtn [disabled]="carts?.length == 0 || isLoading" (click)="submitPurchase(checkoutBtn)">
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="spinner__container" *ngIf="isLoading">
          <mat-spinner class="spinner__item"></mat-spinner>
        </div>
      </router-outlet>
    </div>
  </div>