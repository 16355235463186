import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CourseRoutingModule } from './course-routing.module';
import { CourseListComponent } from './pages/course-list/course-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CourseManagementComponent } from './pages/course-management/course-management.component';
import { CourseMeetingComponent } from './pages/course-meeting/course-meeting.component';
import { CourseFilterComponent } from './components/course-filter/course-filter.component';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { CourseDetailComponent } from './components/course-detail/course-detail.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { AdminModule } from '../../admin/admin.module';

@NgModule({
  declarations: [
    CourseListComponent,
    CourseCardComponent,
    CourseManagementComponent,
    CourseFilterComponent,
    CourseDetailComponent,
    CourseMeetingComponent
  ],
  imports: [
    CommonModule,
    CourseRoutingModule,
    SharedModule,
    MatCardModule,
    MatTabsModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatListModule,
    MatExpansionModule,
    AdminModule
  ],
})
export class CourseModule {}
