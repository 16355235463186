import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DependentRoutingModule } from './dependent-routing.module';
import { DependentListComponent } from './pages/dependent-list/dependent-list.component';
import { SharedService } from '../shared/services/shared.service';
import { SharedModule } from '../shared/shared.module';
import { DependentDetailComponent } from './components/dependent-detail/dependent-detail.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { DependentLoginComponent } from './components/dependent-login/dependent-login.component';
import { CodeInputModule } from 'angular-code-input';
import { AdminModule } from '../../admin/admin.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    DependentListComponent,
    DependentDetailComponent,
    DependentLoginComponent
  ],
  imports: [
    CommonModule,
    DependentRoutingModule,
    SharedModule,
    
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    FlexLayoutModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    CodeInputModule,
    AdminModule,
    MatPaginatorModule,
    MatTableExporterModule,
    NgxLoadingModule.forRoot({}),
  ]
})
export class DependentModule { }
