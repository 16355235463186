import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { UserService } from '../../services/user.service';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { FileUpload } from '../../../file-upload/models/file-upload.model';
import { NgProgressComponent } from 'ngx-progressbar';
import * as moment from 'moment-timezone';
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Subscription } from 'rxjs';
import * as crypto from 'crypto-js';
import { DependentService } from '../../../../modules/dependent/services/dependent.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  public form: any = null;

  user_id: any = null;

  userTypes = [
    { id: 2, name: 'ADMIN' },
    // { id: 3, name: 'TEACHER' },
    // { id: 4, name: 'PARENT' },
    { id: 6, name: 'STAFF' },
    // { id: 5, name: 'STUDENT' },
  ];

  public fromLogin: boolean = false;
  public isSubmitted: boolean = false;
  public fromProfile: boolean = false;

  public selectedFiles: any = []; //STORE SELECTED FILES
  public currentFileUpload: FileUpload;
  public uploadedFile: any = []; //GET FILE STORED IN DB
  public deletedFile: any = []; //STORE FILE TO BE DELETED
  public percentage: number = 0;

  public actualPercentage: any = 0;
  public lastPercentage: any = 0;
  public currentIndex: any = 0;
  public count: any = 1;
  public stopUpload: boolean = false;

  public profileImage: any = "assets/images/default-profile.jpg";
  public userType: any = null;

  public subscriptionCloseModal: Subscription;

  public hideContentManager: boolean = false;
  public schools: any = [];

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserFormComponent>,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private sharedService: SharedService,
    private uploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private dependentService: DependentService
  ) {
    if (this.data.user) {
      this.form = new FormGroup({
        name_first: new FormControl(''),
        name_last: new FormControl(''),
        email: new FormControl(''),
        phone_no: new FormControl(''),
        isContentManager: new FormControl(''),
        school: new FormControl('')
      });
    } else {
      this.form = new FormGroup({
        name_first: new FormControl(''),
        name_last: new FormControl(''),
        email: new FormControl(''),
        phone_no: new FormControl(''),
        password: new FormControl(''),
        plain_password: new FormControl(''),
        confirm_password: new FormControl(''),
        // user_type: new FormControl(''),
        status: new FormControl(''),
        isContentManager: new FormControl(''),
        school: new FormControl('')
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.uploadService.setSubscriptionCloseModal(false);
    this.subscriptionCloseModal = this.uploadService.getSubscriptionCloseModal().subscribe((value: any)=> {
      if(value){
        this.close(value);
      }
    }, (error : any) => {

    });
    if (this.data.user) {
      this.form = this.formBuilder.group({
        name_first: ['', [Validators.required]],
        name_last: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        phone_no: ['', [Validators.required]],
        isContentManager: [0],
        school: ['', [Validators.required]]
      });
    } else {
      this.form = this.formBuilder.group({
        name_first: ['', [Validators.required]],
        name_last: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        phone_no: ['', [Validators.required]],
        password: ['', [Validators.required]],
        plain_password: ['', [Validators.required, Validators.minLength(8)]],
        confirm_password: ['', [Validators.required, Validators.minLength(8)]],
        // user_type: ['', [Validators.required]],
        status: [2, [Validators.required]],
        isContentManager: [0],
        school: ['', [Validators.required]]
      });
    }

    if (this.data.user) {
      let tempUserObj = this.data.user;
      this.user_id = tempUserObj.id;
      this.form.controls['name_first'].setValue(tempUserObj.name_first);
      this.form.controls['name_last'].setValue(tempUserObj.name_last);
      this.form.controls['email'].setValue(tempUserObj.email);
      this.form.controls['phone_no'].setValue(tempUserObj.phone_no);
      this.form.controls['isContentManager'].setValue(tempUserObj.isContentManager);
      this.form.controls['school'].setValue(tempUserObj.schoolId);
      this.uploadedFile = {
        name: tempUserObj.profile_image_name,
        base_path: tempUserObj.profile_image_base_path,
        path: tempUserObj.profile_image_path,
        mime: tempUserObj.profile_image_mime,
        size: tempUserObj.profile_image_size
      };
      this.profileImage = tempUserObj.profile_image_path;
      this.userType = tempUserObj.user_type;
    }

    if (this.data.fromProfile) {
      this.fromProfile = true;
    }

    if( this.data.hideContentManager ){
      this.hideContentManager = true;
    }

    this.getSchool();
  }

  getSchool(){
    let obj = {
      schoolId: null
    }

    this.dependentService.getSchool(obj).subscribe((response)=> {
      if(response){
        this.schools = response?.data?.result;
      }
    },(error)=>{

    });
  }

  ngOnDestroy(){
    this.subscriptionCloseModal.unsubscribe();
  }

  async submitHandler() {
    this.isSubmitted = true;
    const currentPassword = this.form.value['plain_password'];

    if( 
      currentPassword && 
      this.form.value['confirm_password']
    ){

      if( currentPassword == this.form.value['confirm_password'] ) {
        this.form.controls['password'].setValue(crypto.SHA256(currentPassword).toString());
      } else {
        this.isSubmitted = false;
        return;
      }
    }

    if (this.form.valid) {
      this.register();
    }
  }

  register() {
    let formValue = this.form.value;
    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;
    let payloadObject = null;

    if(this.data.user){
      payloadObject = {
        id: this.user_id,
        name_first: formValue.name_first,
        name_last: formValue.name_last,
        email: formValue.email,
        phone_no: formValue.phone_no,
        user_type: this.userType,
        profile_image_name: null,
        profile_image_base_path: 'profile-image',
        profile_image_path: null,
        profile_image_mime: null,
        profile_image_size: null,
        requested_by: requested_by ? requested_by.id : null,
        isContentManager: formValue.isContentManager ? 1 : 0,
        school: formValue.school
      };
    } else {
      payloadObject = {
        id: this.user_id,
        name_first: formValue.name_first,
        name_last: formValue.name_last,
        email: formValue.email,
        phone_no: formValue.phone_no,
        password: formValue.password,
        // passcode: this.fromLogin == true ? formValue.passcode : 123456,
        user_type: this.userType,
        status: 2,
        profile_image_name: null,
        profile_image_base_path: 'profile-image',
        profile_image_path: null,
        profile_image_mime: null,
        profile_image_size: null,
        requested_by: requested_by ? requested_by.id : null,
        register_type: 2,
        isContentManager: formValue.isContentManager ? 1 : 0,
        school: formValue.school ? formValue.school : 0
      };
    }

    if (
      this.uploadedFile &&
      this.uploadedFile.name
    ) {
      payloadObject.profile_image_name = this.uploadedFile.name;
      payloadObject.profile_image_path = this.uploadedFile.path;
      payloadObject.profile_image_mime = this.uploadedFile.mime;
      payloadObject.profile_image_size = this.uploadedFile.size;
    }

    if (
      this.deletedFile &&
      this.deletedFile[0] &&
      this.deletedFile[0].name
    ) {
      payloadObject.profile_image_name = null;
      payloadObject.profile_image_path = null;
      payloadObject.profile_image_mime = null;
      payloadObject.profile_image_size = null;
      this.uploadService.deleteFileStorage(this.deletedFile[0].name, "profile-image");
    }

    if (
      this.selectedFiles &&
      this.selectedFiles.length > 0
    ) {
      this.upload(payloadObject);
    } else {
      this.uploadService.registerUser(null, null, payloadObject, this.fromLogin);
    }
  }

  close(data?: any) {
    this.userType = null;
    this.dialogRef.close({ data: data });
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    // if( code.length > 0 ){
    //   this.passcodeEditEmpty = false;
    // } else {
    //   this.passcodeEditEmpty = true;
    // }
    // this.form.controls['passcode'].setValue(code);
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    // if( this.data.user ){
    //   this.formPasscode.controls['passcode'].setValue(code);
    // } else {
      // this.form.controls['passcode'].setValue(code);
      // this.passcodeEditEmpty = false;
    // } 
  }

  selectFile(event: any): void {
    this.selectedFiles = Array.from(event.target.files);
    if( this.selectedFiles ){
      this.deleteFile();
    }
    this.profileImage = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(event.target.files[0])
    );
    this.stopUpload = false;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      let currentdatetime = moment().utc().format('YYYYMMDDHHmmss');
      this.selectedFiles[i] = new File([this.selectedFiles[i]], `${currentdatetime}_${this.selectedFiles[i].name}`, { type: this.selectedFiles[i].type });
    }
  }

  upload( payloadObject: any ): void {
    if (this.form.valid) {
      this.isSubmitted = true;

      if (this.stopUpload == false) {
        const totalFilesUpload = this.selectedFiles.length;
        const file: any = this.selectedFiles[this.currentIndex];
        this.currentFileUpload = new FileUpload(file);
        let basePath = 'profile-image';
        let subscribe = this.uploadService.pushFileToStorage(
          this.currentFileUpload,
          basePath,
          null,
          null,
          payloadObject,
          null,
          null,
          this.fromLogin
        ).subscribe(
          (percentage: any) => {
            this.percentage = (this.actualPercentage + Math.round(percentage)) / totalFilesUpload;

            if (
              percentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = percentage;
              this.progressBar.set(this.percentage);
            }

            if (
              percentage == 100 &&
              this.lastPercentage != 100 &&
              this.stopUpload == false
            ) {
              this.lastPercentage = 100;
              this.actualPercentage += 100;
              if (this.count == totalFilesUpload) {
                this.stopUpload = true;
                this.selectedFiles = null;
                this.count = 1;
                this.currentIndex = 0;
                this.actualPercentage = 0;
                this.percentage = 0;
                this.progressBar.complete();
                this.isSubmitted = false;
              } else {
                this.currentIndex++;
                this.count++;
                subscribe.unsubscribe();
              }
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
      }
    } else {

    }
  }

  removeFile(i: any) {
    this.selectedFiles.splice(i, 1);
    if (this.selectedFiles.length == 0) {
      this.selectedFiles = undefined;
    }
  }

  trimFileName(fileName: any) {
    return fileName.substr(15);
  }

  deleteFile() {
    if (
      this.uploadedFile &&
      this.uploadedFile.base_path == "profile-image"
    ) {
      this.deletedFile.push(this.uploadedFile);
      this.uploadedFile = [];
      if (this.selectedFiles.length == 0) {
        this.selectedFiles = undefined;
      }
    }
  }
  getRadioValue(userTypeId: any){
    this.userType = userTypeId;
  }
}
