<div class="row">
  <div class="col-md-12">
    <div class="card border-0 mb-4 no-bg">
      <div
        class="card-header py-3 px-0 d-flex align-items-center justify-content-between border-bottom">
        <h4 class=" fw-bold flex-fill title-left">Menu Management</h4>
        <div fxLayoutAlign="end">
          <button class="btn btn-custom" (click)="addNewPackage()">Add Menu
            <i class="icofont-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>