import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as crypto from 'crypto-js';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public form: any = null;
  public user_id: any = null;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
  ) {
    if (this.data.user) {
      this.user_id = this.data.user.id;
    }

    this.form = new FormGroup({
      password: new FormControl(''),
      plain_password: new FormControl(''),
      confirm_password: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
      plain_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  submitHandler() {
    const currentPassword = this.form.value['plain_password'];

    if( 
      currentPassword && 
      this.form.value['confirm_password']
    ){
      if( currentPassword == this.form.value['confirm_password'] ) {
        this.form.controls['password'].setValue(crypto.SHA256(currentPassword).toString());
      } else {
        this.sharedService.showSnackBar("Password and confirm password are not same");
      }
    }

    if (this.form.valid) {
      this.processChange();
    }
  }

  processChange() {
    let prepareObj = {
      user_id: this.user_id,
      password: this.form.value['password']
    }

    this.userService.updatePassword(prepareObj).subscribe( (responseObj) => {
      if(responseObj) {
        this.close({ response: responseObj});
      }
    })
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }
}
