import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionManagementComponent } from './pages/session-management/session-management.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  { path: 'session/session-management', component: SessionManagementComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SessionRoutingModule {}
