<div fxLayout="column wrap" fxLayoutAlign="space-around start">
  <div class="card-title" fxFlex="100">Card Details</div>

  <div fxFlex="100" class="full-width">
    <form [formGroup]="form" (ngSubmit)="submitHandler()">

      <div class="col-12 text-center dep-image" *ngIf="!data?.isAssignCard && !data?.dependentId">
        <div class="d-flex justify-content-center">
          <input hidden #fileInput type="file" id="file" (change)="selectFile($event)" accept="image/*"/>
          <img [src]="profileImage" (click)="fileInput.click()" class="avatar xl rounded-circle img-thumbnail shadow-sm pointer" alt="profile">
        </div>
      </div>

      <div class="form-group full-width" *ngIf="!data?.isAssignCard && !data?.dependentId">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="100">
            <mat-form-field appearance="outline" class="form-field__extend full-width">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name" placeholder="Name" />
              <mat-error *ngIf="f['name'].errors">Name is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group full-width" *ngIf="!data?.isAssignCard && !data?.dependentId">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="100">
            <mat-form-field appearance="outline" class="form-field__extend full-width">
              <mat-label>IC / MyKid Number</mat-label>
              <input matInput type="text" formControlName="ic" placeholder="IC number without -" />
              <mat-error *ngIf="f['ic'].errors">IC Number is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group full-width" *ngIf="!data?.isAssignCard && !data?.dependentId">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="100">
            <div class="form-field__extend full-width school-select">
              <label>School</label>
              <br />
              <select formControlName="school" class="form-select pointer" aria-placeholder="Choose School" (change)="getClass()">
                <option value="">Select One</option>
                <option *ngFor="let school of schools" [value]="school['school_id']">
                  {{ school['school_name'] }}
                </option>
              </select>
              <mat-error *ngIf="f['school'].errors">School is required</mat-error>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group full-width" *ngIf="!data?.isAssignCard && !data?.dependentId">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="100">
            <div class="form-field__extend full-width school-select">
              <label>Class</label>
              <br />
              <select formControlName="class" class="form-select pointer" aria-placeholder="Choose Class">
                <option value="">Select One</option>
                <option *ngFor="let class of classes" [value]="class['class_id']">
                  {{ class['class_name'] }}
                </option>
              </select>
              <mat-error *ngIf="f['class'].errors">Class is required</mat-error>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group full-width" *ngIf="!data?.isAssignCard && !data?.dependentId">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="100">
            <mat-form-field appearance="outline" class="form-field__extend full-width">
              <mat-label>Spend Limit Per Day</mat-label>
              <input matInput type="number" formControlName="cardLimit" placeholder="Card Limit" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group full-width" *ngIf="data?.isAssignCard && data?.dependentId">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="100">
            <mat-form-field appearance="outline" class="form-field__extend full-width">
              <mat-label>Card</mat-label>
              <input matInput type="password" formControlName="card_no" placeholder="card" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group full-width">
        <button type="submit" mat-raised-button color="#004a9f" class="full-width" [disabled]="loading">Save</button>
      </div>
      <ng-template #customLoadingTemplate>
        <div class="custom-class">
          <h3>Saving...</h3>
        </div>
      </ng-template>
      <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"
        [template]="customLoadingTemplate"></ngx-loading>
    </form>
  </div>
</div>