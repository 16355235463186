import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PackageRoutingModule } from './package-routing.module';
import { PackageStoreComponent } from './pages/package-store/package-store.component';
import { SharedModule } from '../shared/shared.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { PackageListComponent } from './components/package-list/package-list.component';
import { PackageFilterComponent } from './components/package-filter/package-filter.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { PackageManagementComponent } from './pages/package-management/package-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { PackageDetailComponent } from './components/package-detail/package-detail.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { PackageSubjectsComponent } from './components/package-subjects/package-subjects.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { AdminModule } from '../../admin/admin.module';
import { NgProgressModule } from 'ngx-progressbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  declarations: [
    PackageStoreComponent,
    PackageListComponent,
    PackageFilterComponent,
    PackageManagementComponent,
    PackageDetailComponent,
    PackageSubjectsComponent,
  ],
  imports: [
    CommonModule,
    PackageRoutingModule,
    SharedModule,
    MatGridListModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDividerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FileUploadModule,
    AdminModule,
    NgProgressModule,
    MatTooltipModule,
    MatPaginatorModule
  ],
  providers: [MatDatepickerModule],
})
export class PackageModule {}
