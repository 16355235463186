import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartHandlerService {
  public selectedItems: any[] = [];

  constructor() { }

  setSelectedItems = (selectedItemObj: any) => {
    this.selectedItems = [...this.selectedItems, selectedItemObj];
  }

  getSelectedItems = () => {
    return this.selectedItems;
  }

  removeSelectedItems = (itemId: any) => {
    this.selectedItems = this.selectedItems.filter( (item) => {
      return item.id != itemId;
    })
  }

  clearSelectedItems = () => {
    this.selectedItems = [];
  }

  isItemExist = (cart_id: number) => {
    let isExist = this.selectedItems.filter( (item) => {
      return item.id == cart_id;
    })

    return isExist.length > 0 ? true : false;
  }
}
