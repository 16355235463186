<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around start">
          <div class="custom-card content-container" fxFlex="100">
            <div class="content-area__main">
              <div>
                <course-filter (onAddNewCourse)="addNewCourseHandler($event)"></course-filter>
              </div>
              <div class="fixed-height-overflow-2">
                <course-card [courses]="courses" (onUpdateCourse)="updateCourseHandler($event)"></course-card>
              </div>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>
</body>