<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

    <div id="elearn-layout" class="theme-black h-100">
        <!-- sidebar -->
        <!-- <app-leftbar id='leftbar'></app-leftbar> -->

        <!-- main body area -->
        <div class="main px-lg-4 px-md-4 bg-dark-defualt">
            <!-- Body: Header -->
            <app-header [showHomeButton]='true' [hideMenuButton]='true' [hideFilterButton]='true'></app-header>

            <!-- Body: Body -->
            <router-outlet>
                <div class="body d-flex py-lg-3 py-md-2">
                    <div class="container-fluid">
                        <div class="row clearfix">
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-12 order-1 order-sm-1 order-md-1 order-lg-1">
                                <div class="video-tool py-4">
                                    <video class="d-block w-100 h-510" [src]='selectedVideo' controlsList="nodownload"
                                        controls loop muted playsinline></video>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 order-2 order-sm-2 order-md-2 order-lg-2">
                                <div class="card border-0 my-4 bg-dark ">
                                    <div id="rightchatbox" class="rightchatbox p-3 video-container">
                                        <div id="friendslist">
                                            <div class="d-flex align-items-center mb-3">
                                                <div><i class="icofont-video fs-2"></i></div>
                                                <div class="flex-fill ms-3 text-truncate">
                                                    <h5 class="mb-0">Video</h5>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex flex-column align-items-center progress overflow-content h-470">
                                                <span *ngFor="let video of videoContents; let i = index;"
                                                    class="event-item shadow">
                                                    <video class="d-block w-100 pointer" [src]='video.contentFilePath'
                                                        controlsList="nodownload" loop muted playsinline
                                                        (click)="viewVideo(video.contentFilePath)"></video>
                                                    <br />
                                                </span>
                                            </div>
                                        </div>
                                        <mat-paginator (page)="handlePageEvent($event)" [length]="totalResult"
                                            [pageSize]="limit" [showFirstLastButtons]="true"
                                            [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
                                            aria-label="Select page">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </router-outlet>
        </div>
    </div>
</body>