import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { UserService } from 'src/app/modules/user/services/user.service';

import * as moment from 'moment';
import "moment-timezone";
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-session-detail',
  templateUrl: './session-detail.component.html',
  styleUrls: ['./session-detail.component.sass']
})
export class SessionDetailComponent implements OnInit {
  form: FormGroup = new FormGroup({
    status: new FormControl(2),
    start_date: new FormControl(null),
    due_date: new FormControl(null),
    start_time: new FormControl(null),
    end_time: new FormControl(null),
    duration: new FormControl(0),
    day: new FormControl(''),
    url: new FormControl(null),
    meeting_number: new FormControl(null),
    meeting_password: new FormControl(null)
  });

  courseObj: any = null;
  session_id: any = null;

  days: any[] = [
    {id: 1, name: 'Sunday'},
    {id: 2, name: 'Monday'},
    {id: 3, name: 'Tuesday'},
    {id: 4, name: 'Wednesday'},
    {id: 5, name: 'Thursday'},
    {id: 6, name: 'Friday'},
    {id: 7, name: 'Saturday'},
  ];

  public timezone: any = null;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SessionDetailComponent>,
    private sharedService: SharedService,
    private userService: UserService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar
  ) { 
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngOnInit(): void {
    this.courseObj = this.data.course;

    this.form = this.formBuilder.group({
      status: [2],
      start_date: [null, [Validators.required]],
      due_date: [null, [Validators.required]],
      start_time: [null, [Validators.required]],
      end_time: [null],
      duration: [null, [Validators.required]],
      day: ['', [Validators.required]],
      url: [null],
      meeting_number: [null],
      meeting_password: [null]
    });

    if (this.data.session) {
      let tempSessionObj = this.data.session;
      this.session_id = tempSessionObj.id;
      this.form.controls['status'].setValue(tempSessionObj.status);
      this.form.controls['start_date'].setValue(this.convertDate(tempSessionObj.start_date));
      this.form.controls['due_date'].setValue(this.convertDate(tempSessionObj.due_date));
      this.form.controls['start_time'].setValue(this.convertTime(tempSessionObj.start_time));
      this.form.controls['end_time'].setValue(this.convertTime(tempSessionObj.end_time));
      this.form.controls['duration'].setValue(tempSessionObj.duration);
      this.form.controls['day'].setValue(tempSessionObj.day);
      this.form.controls['meeting_number'].setValue(tempSessionObj.meeting_number);
      this.form.controls['meeting_password'].setValue(tempSessionObj.meeting_password);
    }
  }

  convertTime(time: any) {
    return moment.utc(time, 'HH:mm:ss').tz(this.timezone).format('HH:mm:ss');
  }

  convertDate(date: any) {
    return moment.utc(date).tz(this.timezone).format('YYYY-MM-DD');
  }


  calculateDuration() {
    if (this.form.value.start_time && this.form.value.end_time) {
      let startTime = moment(this.form.value.start_time, 'HH:mm'); //new Date("01-01-2022 " + this.form.value.start_time);
      let endTime = moment(this.form.value.end_time, 'HH:mm'); //new Date("01-01-2022 " + this.form.value.end_time);
      let difference = endTime.diff(startTime);

      this.form.controls['duration'].setValue(difference);
    }
  }

  submitHandler() {
    let formValue = this.form.value;
    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;
    let startTimeUTC = moment(formValue.start_time, 'HH:mm:ss').utc().format('HH:mm:ss');
    let endTimeUTC = moment(formValue.end_time, 'HH:mm:ss').utc().format('HH:mm:ss');
    let prepareObj = {
      id: this.session_id ? this.session_id : null,
      course_id: this.courseObj.id,
      status: formValue.status,
      type: formValue.type,
      start_date: moment(formValue.start_date).format('DD-MM-YYYY'),
      due_date: moment(formValue.due_date).format('DD-MM-YYYY'),
      start_time: startTimeUTC,
      end_time: endTimeUTC,
      duration: formValue.duration,
      day: formValue.day,
      url: formValue.url,
      meetingNumber: formValue.meeting_number,
      meetingPassword: formValue.meeting_password,
      requested_by: requested_by.id
    }

    this.sessionService.saveSession(prepareObj).subscribe( (data) => {
      this.sharedService.showSnackBar(data.message);
      this.close(data);
    })
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }
}
