import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TeacherRoutingModule } from './teacher-routing.module';
import { TeacherManagementComponent } from './pages/teacher-management/teacher-management.component';

import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../shared/shared.module';
import { TeacherDetailComponent } from './components/teacher-detail/teacher-detail.component';
import { TeacherListComponent } from './components/teacher-list/teacher-list.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { TeacherShowcaseComponent } from './pages/teacher-showcase/teacher-showcase.component';
import { TeacherProfileComponent } from './components/teacher-profile/teacher-profile.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdminModule } from '../../admin/admin.module';
import { NgProgressModule } from 'ngx-progressbar';

@NgModule({
  declarations: [
    TeacherManagementComponent,
    TeacherDetailComponent,
    TeacherListComponent,
    TeacherShowcaseComponent,
    TeacherProfileComponent,
  ],
  imports: [
    CommonModule,
    TeacherRoutingModule,
    SharedModule,

    MatCardModule,
    MatTabsModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    FlexLayoutModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatDividerModule,
    MatListModule,
    MatExpansionModule,
    MatListModule,
    MatProgressSpinnerModule,
    AdminModule,
    NgProgressModule
  ]
})
export class TeacherModule { }
