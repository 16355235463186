<table mat-table [dataSource]="usersList" class="mat-elevation-z8 table">
  <ng-container matColumnDef="profile-image">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <img *ngIf="!element.profile_image_path" src="assets/images/default-profile.jpg" alt="" class="avatar lg rounded-circle img-thumbnail shadow-sm pointer">
      <img *ngIf="element.profile_image_path" [src]="element.profile_image_path" alt="" class="avatar lg rounded-circle img-thumbnail shadow-sm pointer">
    </td>
  </ng-container>

  <ng-container matColumnDef="name_first">
    <th mat-header-cell *matHeaderCellDef>First Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name_first }}</td>
  </ng-container>

  <ng-container matColumnDef="name_last">
    <th mat-header-cell *matHeaderCellDef>Last Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name_last }}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>

  <ng-container matColumnDef="phone_no">
    <th mat-header-cell *matHeaderCellDef>Phone No</th>
    <td mat-cell *matCellDef="let element">{{ element.phone_no }}</td>
  </ng-container>

  <ng-container matColumnDef="user_type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">{{ element.usertype_name }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{ element.status_name }}</td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button  (click)="openUser(element)"><i class="icofont-edit"></i></button>
      <button mat-icon-button color="warn" (click)="removeUser(element)" *ngIf="element.status_name == 'active'"><i class="icofont-ui-delete"></i></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
