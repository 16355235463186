import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getAllTransaction(transactionObject: any = {}): Observable<any> {
    return this.http.post(`${environment.apiURL}/transaction`, transactionObject, this.httpOptions);
  }

  createBill(billObject: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/create-bill`, billObject, this.httpOptions);
  }

  getBill(billObject: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/get-bill`, billObject, this.httpOptions);
  }

  updateTransaction(transactionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/update-transaction`, transactionObj, this.httpOptions);
  }

  getPendingTransaction(transactionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/pending-transaction`, transactionObj, this.httpOptions);
  }

  getPackageByTransaction(transactionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/package-transaction`, transactionObj, this.httpOptions);
  }

  cancelTransaction(transactionObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/cancel-transaction`, transactionObj, this.httpOptions);
  }
}
