<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="start">
          <div class="custom-card content-container" fxFlex="100">
            <div class="content-area__main">
              <div *ngIf="userDetail.usertype_name == 'ADMIN' || userDetail.usertype_name == 'SUPER ADMIN'">
                <h4 class=" fw-bold flex-fill title-left">Teacher Management</h4>
                <div fxFlex fxLayout="row" fxLayoutAlign="end">
                  <button class="btn btn-custom" (click)="addNewTeacher()">Add New Teacher
                    <i class="icofont-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div style="margin-top: 20px;">
                <teacher-list [teacherList]="teachers" (onSaveTeacher)="saveTeacherHandler()"></teacher-list>
              </div>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>