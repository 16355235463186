<div fxLayout="column wrap" fxLayoutAlign="space-around center">
  <div class="card-title" fxFlex="100">Menu Detail</div>
  <ng-progress #progressBar></ng-progress>
  <div fxFlex="100" class="full-width">
    <form [formGroup]="form" (ngSubmit)="submitHandler()">

      <!-- <div class="d-flex justify-content-center mb-5">
        <input hidden #fileInput type="file" id="file" (change)="selectFile($event)" accept="image/*" />
        <img [src]="packageImage" (click)="fileInput.click()" alt="" class="img-thumbnail pointer custom-height">
      </div> -->

      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="title" placeholder="Title" />
          <mat-error *ngIf="f['title'].errors">Name is required</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Calories</mat-label>
          <input matInput type="number" formControlName="calorie" placeholder="Calories" />
          <mat-error *ngIf="f['calorie'].errors">Calorie is required</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="mat-form-field__90 full-width">
              <mat-label>Price</mat-label>
              <input matInput type="number" formControlName="fee" placeholder="Price" />
              <span matPrefix style="top: 0 !important">RM &nbsp;</span>
              <mat-error *ngIf="f['fee'].errors">Price is required</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-label>Category</mat-label>
            <select formControlName="category" class="form-select pointer">
              <option value=""></option>
              <option *ngFor="let category of categories" [value]="category['id']">
                {{category['name']}}
              </option>
            </select>
            <mat-error *ngIf="f['category'].errors && isSubmitted">Category is required</mat-error>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button type="submit" mat-raised-button class="full-width">
          {{ package_id ? 'Update' : 'Register'}}
        </button>
      </div>
    </form>
  </div>
</div>