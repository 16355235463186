import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserFormComponent } from '../user-form/user-form.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserTableComponent implements OnInit {
  @Input() usersList: any;
  @Output() userUpdatedEvent = new EventEmitter<any>();

  // usersList: any[] = [];
  displayedColumns = [ 'profile-image', 'name_first', 'name_last', 'email', 'phone_no', 'user_type', 'status', 'action' ]

  constructor(
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    
  }

  openUser(userObj: any) {
    const dialogRef = this.dialog.open( UserFormComponent, {
      data: {
        fromLogin: false,
        user: userObj
      },
      panelClass: 'dialog-panel'
    } );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userUpdatedEvent.emit(result);
      }
    });
  }

  removeUser(userObj: any){
    const userObject = {
      id: userObj.id
    };
    console.log(userObj)
    this.userService.removeUser(userObject).subscribe((response)=>{
      if( response ){
        this.userUpdatedEvent.emit(true);
      }
    },(error)=>{

    });
  }
}
