import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '../../../shared/services/shared.service';
import { GlobalService } from '../../../../services/global.service';
import { DependentService } from '../../services/dependent.service';
import { UserService } from '../../../user/services/user.service';

@Component({
  selector: 'app-dependent-login',
  templateUrl: './dependent-login.component.html',
  styleUrls: ['./dependent-login.component.scss']
})
export class DependentLoginComponent implements OnInit {
  dependents: any[] = [];
  selectedDependent: any = null;

  userDetail = <String>localStorage.getItem("userDetail");
  requested_by = this.userDetail ? JSON.parse(this.userDetail.toString()) : null;

  passcode: any = null;
  isWrongPasscode: boolean = false;

  constructor(
    private sharedService: SharedService,
    private dependentService: DependentService,
    private dialogRef: MatDialogRef<DependentLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalService: GlobalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let parentData = this.data.parentData;
    // this.getDependent(parentData);
    let type = 'parents';
    this.updateAccessToken(parentData.email, type);
  }

  getDependent(parentData: any) {
    let prepareObj = {
      id: null,
      parent_id: parentData.id
    }
    this.dependentService.getDependent(prepareObj).subscribe((response) => {
      this.dependents = response?.data?.result;
      if (this.dependents.length == 0) {
        let type = 'parents';
        this.updateAccessToken(parentData.email, type);
      }
    })
  }

  // showPasscode(user: any) {
  //   this.selectedDependent = user;
  // }

  checkPasscode(user: any) {
    this.selectedDependent = user;
    let type = this.selectedDependent.usertype_name ? 'parents' : 'dependent';

    let prepareObj = {
      id: this.selectedDependent.id
    }

    localStorage.removeItem('dependentDetail');

    if (type == 'parents') {
      this.updateAccessToken(user.email, type);
    } else {
      this.dependentService.dependentToken(prepareObj).subscribe((response) => {
        if (
          response &&
          response.status &&
          response.status.code == 200
        ) {
          if (type == 'dependent') {
            const dependentToken = response.data.token
            this.selectedDependent.usertype_name = 'DEPENDENT';
            let filter_level = this.selectedDependent.level;
            let filter_sublevel = this.selectedDependent.sublevel;
            localStorage.setItem('dependentDetail', JSON.stringify(this.selectedDependent));
            localStorage.setItem('dependentToken', dependentToken);
            localStorage.setItem('global_level', filter_level);
            localStorage.setItem('global_sublevel', filter_sublevel);
            localStorage.setItem('isLoggedIn', 'true');
            this.globalService.isLoggedIn = true;
            this.globalService.dependentTokens = dependentToken;
          }
          this.sharedService.showSnackBar(response.message);
          this.close({ type: type, isPasses: true });
        } else {
          this.sharedService.showSnackBar(response.message);
        }
      }, (error) => {
        this.sharedService.showSnackBar(error);
      });
    }

    // this.dependentService.passcodeLogin(prepareObj).subscribe((data) => {
    //   if (
    //     data &&
    //     data.message == "Login successfully"
    //   ) {
    //     localStorage.removeItem('dependentDetail');
    //     if (type == 'dependent') {
    //       this.selectedDependent = data.data[0];
    //       this.selectedDependent.usertype_name = 'DEPENDENT';
    //       let filter_level = this.selectedDependent.level;
    //       let filter_sublevel = this.selectedDependent.sublevel;
    //       localStorage.setItem('dependentDetail', JSON.stringify(this.selectedDependent));
    //       localStorage.setItem('global_level', filter_level);
    //       localStorage.setItem('global_sublevel', filter_sublevel);
    //     }
    //     this.close();
    //   } else {
    //     this.isWrongPasscode = true;
    //   }
    // }, (error) => {
    //   this.isWrongPasscode = true;
    // });
  }

  updateAccessToken(email: any, type: any){
    let prepareObj = {
      email: email
    };

    this.userService.updateAccessToken(prepareObj).subscribe((response) => {
      if (
        response &&
        response.status &&
        response.status.code == 200
      ) {
        const accessToken = response.data.token
        localStorage.setItem('token', accessToken);
        localStorage.setItem('isLoggedIn', 'true');
        this.globalService.isLoggedIn = true;
        this.globalService.tokens = accessToken;
        this.sharedService.showSnackBar(response.message);
        this.close({ type: type, isPasses: true });
      } else {
        this.sharedService.showSnackBar(response.message);
      }
    },(error) => {

    });
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    this.passcode = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.passcode = code;
    // this.checkPasscode();
  }

  close(data: any = { isPasses: true }) {
    this.dialogRef.close(data);
  }
}
