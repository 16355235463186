import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}
  
  saveTeacher(teacherObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-teacher`, teacherObj, this.httpOptions);
  }

  saveAchievement(achievementObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-achievement`, achievementObj, this.httpOptions);
  }

  saveExperience(experienceObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-experience`, experienceObj, this.httpOptions);
  }
  
  getTeacher(teacherObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/teacher`, teacherObj, this.httpOptions);
  }
  
  getAchievementExperience(teacherObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/teacher-details`, teacherObj, this.httpOptions);
  }

  getUnsetTeacherProfile(teacherObj: any = []): Observable<any> {
    return this.http.post(`${environment.apiURL}/teacher-unset`, teacherObj, this.httpOptions);
  }
}
