import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LayoutServiceService {

    public themeDarkClass: string = "";
    public themeltrClass: string = "";
    public isShowMenu: boolean = false;
    public themeDarkClassChange: Subject<string> = new Subject<string>();
    public themeltrClassChange: Subject<string> = new Subject<string>();
    public showMenuClassChange: Subject<boolean> = new Subject<boolean>();


    constructor() {
        // Dark Mode
        this.themeDarkClassChange.subscribe((value) => {
            this.themeDarkClass = value;
        });

        this.themeltrClassChange.subscribe((value) => {
          this.themeltrClass = value;
        });

        this.showMenuClassChange.subscribe((value) => {
            this.isShowMenu = value;
        });
    }

    /** Dark Class functions */
    themeDarkChange(darkClass: string) {
        this.themeDarkClassChange.next(darkClass);
    }

    /** Dark Class functions */
    themeltrChange(ltrClass: string) {
      this.themeltrClassChange.next(ltrClass);
    }

     /** show menu Class functions */
    showMenuChange(darkClass: boolean) {
      this.showMenuClassChange.next(darkClass);
    }
}
