import { LayoutServiceService } from 'src/app/services/layout-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HostListener } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharedService } from '../../../modules/shared/services/shared.service';

@Component({
  selector: 'app-leftbar',
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss']
})
export class LeftbarComponent implements OnInit {

  @ViewChild('sidebar') targetElement: any;

  public isToggle = '';
  public isDarkMode:boolean = false;
  public themeDarkClass: string = "light";
  public isrtlMode:boolean = false;
  public themeltrClass: string = "ltr";
  private ngUnsubscribe = new Subject();
  status: boolean = false;
  public activeTab = 'dashboard';
  public isShowMenu: boolean = false;
  sidebarClass: any;
  sidebarMiniClass: any;

  userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));
  dependentDetail = localStorage.getItem('dependentDetail') ? JSON.parse(<string>localStorage.getItem('dependentDetail')) : null;

  currentUserRole: any = "";
  currentSchoolCode: any = "";
  isContentManager: any = 0;

  public pageTitle: any = "Dashboard";
  public isOpen: number = 0;

  constructor(
    private layoutServiceService: LayoutServiceService,
    private router: Router, 
    private sharedService: SharedService, 
    private dialog: MatDialog
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
        this.themeDarkClass = themeDarkClass;
    });

    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });

    let self = this;

    this.layoutServiceService.showMenuClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isShowMenu => {
      if( this.isOpen == 0 ){
        this.isOpen += 1;
        this.isShowMenu = true;
        this.isToggle = '';
      } else {
        this.isOpen = 0;
        this.isShowMenu = false;
      }
    });
    
    window.addEventListener("click", (e: any) => {
      if (document.getElementById('leftbar').contains(e.target)){
        // Clicked in box
      } else{
        if( self.isOpen == 1 ){
          self.isOpen++;
        } else  if(self.isOpen == 2 ){
          self.isShowMenu = false;
          self.isOpen = 0;
        }
      }
    });
  }

  ngOnInit(): void {

    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requested_by.usertype_name;
    this.currentSchoolCode = requested_by.schoolCode;
    this.isContentManager = requested_by.isContentManager;

    setTimeout(() => {
      const height = this.targetElement.nativeElement.offsetHeight + 48;

      this.sidebarClass = this.targetElement.nativeElement.classList.contains('sidebar');
      this.sidebarMiniClass = this.targetElement.nativeElement.classList.contains('sidebar-mini');

      if( this.sidebarClass == true && this.sidebarMiniClass == true ){
        if(height <= 760){
          this.targetElement.nativeElement.style.overflow = 'scroll';
        }else{
          this.targetElement.nativeElement.style.overflow = 'visible';
        }
      }

    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const height = this.targetElement.nativeElement.offsetHeight + 48;

      this.sidebarClass = this.targetElement.nativeElement.classList.contains('sidebar');
      this.sidebarMiniClass = this.targetElement.nativeElement.classList.contains('sidebar-mini');

      if( this.sidebarClass == true && this.sidebarMiniClass == true ){
        if(height <= 760){
          this.targetElement.nativeElement.style.overflow = 'scroll';
        }else{
          this.targetElement.nativeElement.style.overflow = 'visible';
        }
      }
  }

  toggleSideBarMini(){
    if(!this.isToggle){
      this.isToggle = 'sidebar-mini';
    }else{
      this.isToggle = '';
    }
  }

  darkModeToggle($event:any){
    if ( $event.target.checked ) {
      this.layoutServiceService.themeDarkChange('dark');
    }else{
      this.layoutServiceService.themeDarkChange('light');
    }
  }

  rtlModeToggle($event:any){
    if ( $event.target.checked ) {
      this.layoutServiceService.themeltrChange('rtl_mode');
    }else{
      this.layoutServiceService.themeltrChange('ltr');
    }
  }

  toggleCollapse(tab: string){
    if(this.activeTab != tab){
      this.status = false;
    }
    this.status = !this.status;
    this.activeTab = tab;
  }

  ngOnDestroy() {
    // this.subscriptionHeaderTitleEvent.unsubscribe();
  }

  goToURL(url: string) {
    this.router.navigateByUrl(url);
  }

  openCredit() {
  }
}
