import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './layouts/admin/admin.component';
import { HeaderComponent } from './layouts/header/header.component';
import { LeftbarComponent } from './layouts/leftbar/leftbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AdminComponent,
    HeaderComponent,
    LeftbarComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent,
    LeftbarComponent,
  ]
})
export class AdminModule { }
