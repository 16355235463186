import { Component, OnInit } from '@angular/core';
import { PackageService } from '../../services/package.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-package-management',
  templateUrl: './package-management.component.html',
  styleUrls: ['./package-management.component.sass']
})
export class PackageManagementComponent implements OnInit {

  packages: any[] = [];
  packagesDefault: any[] = [];
  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  constructor(
    private layoutServiceService: LayoutServiceService,
    private packageService: PackageService
  ) {
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    this.getPackages();
  }

  getPackages() {
    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    let payloadObject = {
      schoolId: requested_by.schoolId,
      schoolUniqueId: requested_by.schoolUUID
    }
    return this.packageService.getAllPackage(payloadObject).subscribe((data) => {
      this.packages = data.data;
      this.packagesDefault = this.packages;
    });
  }

  savePackageHandler(result: any) {
    this.getPackages();
  }

  changeLevelFilterHandler(filterObj: any) {
    if (filterObj.filter_level == null && filterObj.filter_sublevel == null) {
      this.packages = this.packagesDefault;
      return;
    }

    this.packages = this.packagesDefault.filter((_package) => {
      return filterObj.filter_sublevel != null ? _package.sublevel == filterObj.filter_sublevel : _package.level == filterObj.filter_level
    })
  }

}
