<div class="row">
  <div class="col-md-12">
    <div class="card border-0 mb-4 no-bg">
      <div class="card-header py-3 px-0 d-flex align-items-center justify-content-between border-bottom">
        <h4 class=" fw-bold flex-fill title-left">Session Management</h4>
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2"
            data-bs-toggle="dropdown" aria-expanded="false">
            {{ filterCourseName? filterCourseName : 'Class' }}
          </button>
          <ul class="dropdown-menu dropdown-menu-end dropdown-overflow" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item"></a></li>
            <li *ngFor="let course of courses" (click)="updateCourse(course)"><a
                class="dropdown-item">{{course['name']}}</a></li>
          </ul>
        </div>
        &nbsp;&nbsp;
        <div fxLayoutAlign="end">
          <button class="btn btn-custom" (click)="addNewSession()" [disabled]="!filter_course">Add New Session
            <i class="icofont-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>