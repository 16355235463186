<div fxLayout="row wrap" fxLayoutAlign="space-around start">
  <div fxFlex="100" fxLayout="row wrap"  *ngIf="carts && carts.length > 0" class="cart-content">
    <div fxFlex="100" *ngFor="let cart of carts, let i = index">
      <div class="card shadow">
        <div class="card-body">
          <div fxLayout="row wrap" fxLayoutAlign="space-around center">
            <div fxFlex="10">
              <!-- <mat-checkbox [checked]="cart.isChecked" (change)="selectItem(cart, $event)"></mat-checkbox> -->
              <input type="checkbox" class="pointer" [checked]="cart.isChecked" (change)="selectItem(cart, $event)"/>
            </div>
            <!-- <div fxFlex="10">
              <img src="assets/images/default.jpg" height="30px" />
            </div> -->
            <div fxFlex="60">
              <span class="cart-item__title">{{cart.packageTitle}}</span>
            </div>
            <div fxFlex="25">
              <span class="cart-item__fee">
                <span class="ms-2" style="font-weight: bold;">
                  <span style="text-decoration: line-through;color: red; margin-right: 10px;" *ngIf="cart.fee === 0">RM 99</span>
                  <span style="color: green;" *ngIf="cart.fee !== 0">RM {{cart.fee}}</span>
                  <span style="color: green;" *ngIf="cart.fee === 0">FREE</span>
                </span>
              </span>
            </div>
            <div fxFlex="5">
              <span class="cart-item__fee button__delete" (click)="removeFromCart(cart.id)"><mat-icon color="warn">delete</mat-icon></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="100" *ngIf="carts && carts.length == 0">
    <div role="alert" class="alert alert-info full-width"><i class="icofont-info-circle fs-5"></i>&nbsp;&nbsp;Cart is empty. Please check <b>Available Packages</b> and add any to cart.</div>
  </div>
  <mat-divider></mat-divider>
  <div fxFlex="50" *ngIf="carts && carts.length > 0" class="web-view">
    <button class="btn bg-warning btn-sm text-white rounded" [disabled]="checkedCount == 0" (click)="proceedCheckout()">Proceed</button>
  </div>
  <div fxFlex="50" *ngIf="carts && carts.length > 0" class="web-view">
    <span class="cart-footer__total">Total: RM{{calculateTotal()}}</span>
  </div>
  <div fxFlex="50" *ngIf="carts && carts.length > 0" class="mobile-view">
    <button class="btn bg-warning btn-sm text-white rounded w-100" [disabled]="checkedCount == 0" (click)="proceedCheckout()">Proceed</button>
  </div>
  <div fxFlex="50" *ngIf="carts && carts.length > 0" class="mobile-view">
    <span class="cart-footer__total w-100 right">Total: RM{{calculateTotal()}}</span>
  </div>
</div>