import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../services/global.service';

@Injectable()
export class RequestInterceptorInterceptor implements HttpInterceptor {

	constructor(private global: GlobalService) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

		const accessToken = this.global.tokens ? this.global.tokens : null;

		if ( accessToken ) {
			return next.handle(request.clone({
				setHeaders: {
					Authorization: `Bearer ${accessToken}`
				}
			}));
		}
		return next.handle(request);
	}
}
