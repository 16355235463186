<div class="row g-3 py-3 fixed-height-overflow">
  <div class="col-12 col-lg-3 col-md-4 col-sm-6 col-xxl-2" *ngFor="let package of packages">
    <div class="card shadow web-view">
      <div class="lesson_name">
        <div class="bg-primary d-flex justify-content-center align-items-center flex-column position-relative">
          <img *ngIf="package.image_path" [src]="package.image_path" alt="package-img" class="img-fluid custom-height pointer" (click)="viewImage(package.image_path)">
          <img *ngIf="!package.image_path" src="assets/images/default-menu2.gif" alt="package-img"
            class="img-fluid custom-height">
        </div>
      </div>
      <div class="card-body">
        <div class="row g-2">
          <div>
            <div class="d-flex align-items-center pointer">
              <b class="ms-2">{{package.title}}</b>
            </div>
            <div class="d-flex align-items-center pointer">
              <span class="ms-2">{{package.categoryName}}</span>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <i class="icofont-price" style="font-size: 20pt;"></i>
              <span class="ms-2" style="font-weight: bold;">
                <span style="text-decoration: line-through;color: red; margin-right: 10px;" *ngIf="package.fee === 0">RM 99</span>
                <span style="color: green;" *ngIf="package.fee !== 0">RM {{package.fee}}</span>
                <span style="color: green;" *ngIf="package.fee === 0">FREE</span>
              </span>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <img width="25" height="25" [src]="'./assets/images/calorie.png'" />
              <span class="ms-2" style="font-weight: bold;">
                <span>{{package?.calorie ? package?.calorie : 0}} kcal</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-footer">
        <div class="video-setting-icon mt-3 pt-3 border-top" style="width: 100%;">
          <button type="button" class="btn btn-success" *ngIf="!isStore" (click)="viewPackage(package)" style="margin-bottom: 5px;"><i
              class="icofont-edit"></i> Edit Menu</button>
          <button *ngIf="isStore && !isSubscribed && package.isPendingPayment == 1" style="margin-bottom: 5px;"
            [disabled]="requestedBy.usertype_name != 'PARENTS'"
            class="btn bg-info btn-sm text-white rounded-0" title="buy-button"><i
              class="icofont-cart-alt me-2"></i> Pending Payment</button>
          <button *ngIf="!isStore && package.isPublished == 0" (click)="managePublishPackage(package, true)" style="margin-bottom: 5px;"
            class="btn bg-success btn-sm text-white rounded-0" title="buy-button"><i
              class="icofont-check-circled me-2"></i> Publish</button>
          <button *ngIf="!isStore && package.isPublished == 1" (click)="managePublishPackage(package, false)" style="margin-bottom: 5px;"
            class="btn bg-danger btn-sm text-white rounded-0" title="buy-button"><i
            class="icofont-close-circled me-2"></i> Unpublish</button>
        </div>
      </div>
    </div>

    <!--Mobile View-->
    <div class="card shadow mobile-view">
      <div class="row">
        <div class="col-5">
          <div class="bg-primary d-flex justify-content-center align-items-center flex-column position-relative ">
            <img *ngIf="package.image_path" [src]="package.image_path" alt="package-img" class="img-fluid custom-height pointer" (click)="viewImage(package.image_path)">
            <img *ngIf="!package.image_path" src="assets/images/default-menu2.gif" alt="package-img"
              class="img-fluid custom-height">
          </div>
        </div>
        <div class="col-7">
          <div class="card-body">
            <div class="row g-2">
              <div>
                <div class="d-flex align-items-center pointer">
                  <b class="ms-2">{{package.title}}</b>
                </div>
                <div class="d-flex align-items-center pointer">
                  <span class="ms-2">{{package.categoryName}}</span>
                </div>
              </div>
              <div>
                <div class="d-flex align-items-center">
                  <i class="icofont-price "></i>
                  <span class="ms-2" style="font-weight: bold;">
                    <span style="text-decoration: line-through;color: red; margin-right: 10px;" *ngIf="package.fee === 0">RM 99</span>
                    <span style="color: green;" *ngIf="package.fee !== 0">RM {{package.fee}}</span>
                    <span style="color: green;" *ngIf="package.fee === 0">FREE</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-footer">
            <div class="video-setting-icon mt-3 pt-3 border-top" style="width: 100%;">
              <button type="button" class="btn btn-success" *ngIf="!isStore" (click)="viewPackage(package)" style="margin-bottom: 5px;"><i
                  class="icofont-edit"></i> Edit Menu</button>
              <button *ngIf="isStore && !isSubscribed && package.isPendingPayment == 1" style="margin-bottom: 5px;"
                [disabled]="requestedBy.usertype_name != 'PARENTS'"
                class="btn bg-info btn-sm text-white rounded-0" title="buy-button"><i
                  class="icofont-cart-alt me-2"></i> Pending Payment</button>
              <button *ngIf="!isStore && package.isPublished == 0" (click)="managePublishPackage(package, true)" style="margin-bottom: 5px;"
                class="btn bg-success btn-sm text-white rounded-0" title="buy-button"><i
                  class="icofont-check-circled me-2"></i> Publish</button>
              <button *ngIf="!isStore && package.isPublished == 1" (click)="managePublishPackage(package, false)" style="margin-bottom: 5px;"
                class="btn bg-danger btn-sm text-white rounded-0" title="buy-button"><i
                class="icofont-close-circled me-2"></i> Unpublish</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>