<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div class="custom-card content-container">
          <div>
            <session-filter (onChangeCourse)="changeCourseHandler($event)" (onAddSessionEvent)="addSessionHandler($event)"></session-filter>
          </div>
          <div>
            <session-list [sessionList]="sessions" [course]="selectedCourse" (onUpdateSession)="addSessionHandler($event)" ></session-list>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>
</body>