import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScheduleListComponent } from './pages/schedule-list/schedule-list.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  {path: 'schedule/list', component: ScheduleListComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScheduleRoutingModule { }
