<div class="row g-3 py-3">
    <ul class="nav nav-tabs">
        <!-- <li class="nav-item">
            <a class="nav-link pointer" [ngClass]="{
				'active': currentTab == 'general'
			}" aria-current="page" (click)="changeTab('general')">Announcement</a>
        </li> -->
        <!-- <li class="nav-item">
            <a class="nav-link pointer" [ngClass]="{
				'active': currentTab == 'image'
			}" (click)="changeTab('image')">Quotes</a>
        </li>
        <li class="nav-item">
            <a class="nav-link pointer" [ngClass]="{
				'active': currentTab == 'video'
			}" (click)="changeTab('video')">Videos</a>
        </li>
        <li class="nav-item">
            <a class="nav-link pointer" [ngClass]="{
				'active': currentTab == 'event'
			}" (click)="changeTab('event')">Events</a>
        </li> -->
        <li class="nav-item add-content-btn">
            <button class="btn btn-primary" (click)="openContentForm()">
                New Announcement
            </button>
        </li>
    </ul>
    <div class="d-flex flex-column">
        <div *ngFor="let file of fileContentFilter">
            <app-content-details [fileContent]='file' [typeName]='typeName' 
            (onManagePublishAnnouncement)="onManagePublishAnnouncement($event)" 
            (onDeleteAnnouncement)="onDeleteAnnouncement($event)"></app-content-details>
        </div>
        <mat-paginator (page)="handlePageEvent($event)" [length]="totalResult" [pageSize]="limit"
            [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
            aria-label="Select page">
        </mat-paginator>
    </div>

    <div style="padding-top: 10px" *ngIf="!fileContentFilter || (fileContentFilter && fileContentFilter.length == 0)">
        <div role="alert" class="alert alert-primary full-width"><i class="icofont-info-circle"></i>&nbsp;&nbsp;No
            Announcement.
        </div>
    </div>
</div>