import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TransactionService } from '../../services/transaction.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { SharedService } from '../../../../modules/shared/services/shared.service';
import { UserService } from '../../../../modules/user/services/user.service';
import { Router } from '@angular/router';
import xlsx from "json-as-xlsx";
import * as moment from "moment-timezone";
import jsPDFInvoiceTemplate from "jspdf-invoice-template";

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit {

  public transactionList: any;

  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public limit: number = 30;
  public defaultLimit: number = 30;
  public offset: number = 0;
  public pageIndex: number = 0;
  public prevPage: number = 0;
  public totalResult: number = 0;
  public pageSizeOptions = [10, 20, 30, 50];
  public totalSubscribed: number = 0;
  public firstTimeLoad: boolean = false;
  public loading: boolean = false;
  public totalPaidAmount: number = 0;
  public totalCalorie: number = 0;
  public filterObject: any = null;

  public currentUserRole: any = "";
  public dependentList: any = "";
  public timezone: any = null;

  public totalFee: any = 0;

  constructor(
    private layoutServiceService: LayoutServiceService,
    public dialog: MatDialog,
    private transactionService: TransactionService,
    private sharedService: SharedService,
    private userService: UserService,
    private router: Router
  ) { 
    this.timezone = "Asia/Kuala_Lumpur";
    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });
  }

  ngOnInit(): void {
    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.currentUserRole = requested_by.usertype_name;

    if( this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ){
      this.getParentDetails();
    } else if( this.currentUserRole == 'SUPER ADMIN' || this.currentUserRole == 'ADMIN' ){
      this.getTransactionList();
    } else {
      this.router.navigateByUrl('cart/cart-process');
    }
  }

  getTransactionList(filterObject: any = null) {

    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;
    
    this.loading = true;
    let transactionObject: any = {
      limit: this.limit,
      offset: this.offset,
      startDate: null,
      endDate: null
    };

    if( this.currentUserRole == 'PARENTS' ||  this.currentUserRole == 'TEACHER' ){
      transactionObject.dependent_id = this.dependentList;
      
      if( !this.dependentList ){
        this.loading = false;
        return;
      }
    } else {
      transactionObject.schoolId = requested_by.schoolId;
      transactionObject.schoolUniqueId = requested_by.schoolUUID;
    }

    const currentFilterObject = filterObject ? filterObject : this.filterObject;
    const isFromFilter = filterObject ? true : false;

    if( currentFilterObject ){
      this.filterObject = currentFilterObject;

      if( isFromFilter ){
        this.limit = 30;
        this.defaultLimit = 30;
        this.pageIndex = 0;
        this.prevPage = 0;
        this.offset = 0;
        this.totalPaidAmount = 0;
        this.totalCalorie = 0;
        this.totalResult = 0;

        transactionObject.limit = this.limit;
        transactionObject.offset = this.offset;
      }

      transactionObject.startDate = currentFilterObject.startDate ? currentFilterObject.startDate : null;
      transactionObject.endDate = currentFilterObject.endDate ? currentFilterObject.endDate : null;
      transactionObject.schoolId = currentFilterObject.school?.school_id ? currentFilterObject.school?.school_id : transactionObject.schoolId;
      transactionObject.schoolUniqueId = currentFilterObject.school?.school_uuid ? currentFilterObject.school?.school_uuid : transactionObject.schoolUniqueId;
    }

    this.transactionService.getAllTransaction(transactionObject).subscribe( response => {
      this.transactionList = response.data.data;
      this.totalPaidAmount = 0;
      this.totalCalorie = 0;
      
      if( 
        this.offset == 0 &&
        this.defaultLimit == this.limit &&
        this.prevPage == this.pageIndex
      ) {
        this.totalResult = 0;
        this.totalResult = response.data.total;
      }

      this.totalPaidAmount = response?.data?.totalPaidAmount;
      this.totalCalorie = response?.data?.totalCalorie;
      this.totalFee = response?.data?.totalFee;
      
      this.prevPage = this.pageIndex;
      this.defaultLimit = this.limit;

      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  transactionUpdatedHandler(eventObj: any) {
    this.getTransactionList(eventObj);
  }

  handlePageEvent(event: PageEvent) {
    this.limit = event.pageSize;
    this.defaultLimit = this.limit;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * this.limit;
    this.getTransactionList();
  }

  resetPackages(event: any) {
    if (this.firstTimeLoad == false) {
      this.limit = 30;
      this.defaultLimit = 30;
      this.pageIndex = 0;
      this.prevPage = 0;
      this.offset = 0;
      this.totalPaidAmount = 0;
      this.totalCalorie = 0;
      this.totalResult = 0;

      this.getTransactionList();
    } else {
      this.firstTimeLoad = true;
    }
  }

  getPageSizeOptions(){
    return [ 20, 50, 100, this.totalResult];
  }

  getParentDetails() {
    const object = {
      id: this.sharedService.getCurrentUser().id
    }

    this.userService.getAllUser(object).subscribe( data => {
      this.dependentList = data[0]?.dependent_list;

      if( this.dependentList ){
        this.getTransactionList();
      }
    });
  }

  setPriceDecimal(price: any){
    return Number(price).toFixed(2);
  }

  exportHandler(eventObj: any) {

    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    this.loading = true;
    let transactionObject: any = {
      limit: this.totalResult,
      offset: 0,
      startDate: eventObj?.startDate ? eventObj?.startDate : null,
      endDate: eventObj?.endDate ? eventObj?.endDate : null
    };

    const currentDateTime = moment().tz(this.timezone).format('DD MMMM YYYY HH:mm A');

    if( this.currentUserRole == 'PARENTS' ||  this.currentUserRole == 'TEACHER' ){
      transactionObject.dependent_id = this.dependentList;
      
      if( !this.dependentList ){
        this.loading = false;
        return;
      }
    } else {
      transactionObject.schoolId = requested_by.schoolId;
      transactionObject.schoolUniqueId = requested_by.schoolUUID;
    }

    const currentFilterObject = this.filterObject;

    if( currentFilterObject ){
      transactionObject.startDate = currentFilterObject.startDate ? currentFilterObject.startDate : null;
      transactionObject.endDate = currentFilterObject.endDate ? currentFilterObject.endDate : null;
      transactionObject.schoolId = currentFilterObject.school?.school_id ? currentFilterObject.school?.school_id : transactionObject.schoolId;
      transactionObject.schoolUniqueId = currentFilterObject.school?.school_uuid ? currentFilterObject.school?.school_uuid : transactionObject.schoolUniqueId;
    }

    let content: any = [];

    this.transactionService.getAllTransaction(transactionObject).subscribe( response => {
      
      const responseData = response?.data?.data ? response?.data?.data : [];

      const totalPaidAmount = response.data.totalPaidAmount;
      const totalFee = response?.data?.totalFee;
      const netSale = totalPaidAmount - totalFee;

      for( let i = 0; i < responseData?.length; i++ ){
        content.push({
          refno: responseData[i].transactionReferenceId,
          menu: responseData[i].packageName,
          amount: "RM " + responseData[i].paidAmount.toFixed(2),
          seller: `${responseData[i].sellerFirstName} ${responseData[i].sellerLastName}`,
          student: responseData[i].studentName,
          soldtime: moment.utc(responseData[i].sellTime).tz(this.timezone).format('DD MMMM YYYY HH:mm A')
        });
      }
      for( let i = 0; i < 3; i++ ){
        content.push({
          refno: '',
          menu: '',
          amount: '',
          seller: '',
          student: '',
          soldtime: ''
        });
      }

      content.push({
        refno: 'Gross Sale (A)',
        menu: 'Fee (B)',
        amount: 'Net Sale (A-B)',
        seller: '',
        student: '',
        soldtime: ''
      });

      content.push({
        refno: "RM " + totalPaidAmount.toFixed(2),
        menu: "RM " + totalFee.toFixed(2),
        amount: "RM " + netSale.toFixed(2),
        seller: '',
        student: '',
        soldtime: ''
      });

      let data = [
        {
          sheet: "Dependents",
          columns: [
            { label: "Reference No.", value: "refno" },
            { label: "Menu", value: "menu" },
            { label: "Paid Amount", value: "amount" },
            { label: "Seller", value: "seller" },
            { label: "Student", value: "student" },
            { label: "Sold On", value: "soldtime" },
            { label: "", value: "" }
          ],
          content: content
        }
      ];

      const exportedDate = moment().utc().format('YYYYMMDDHHmmss')

      let fileName = `${exportedDate}_SALE`;
      
      let settings = {
        fileName: fileName, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      }
      
      xlsx(data, settings) // Will download the excel file
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  public exportInvoiceHandler(eventObj: any = null) {

    this.loading = true;

    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    let transactionObject: any = {
      limit: this.totalResult,
      offset: 0,
      startDate: eventObj?.startDate ? eventObj?.startDate : null,
      endDate: eventObj?.endDate ? eventObj?.endDate : null
    };

    const currentDateTime = moment().tz(this.timezone).format('DD MMMM YYYY HH:mm A');

    if( this.currentUserRole == 'PARENTS' ||  this.currentUserRole == 'TEACHER' ){
      transactionObject.dependent_id = this.dependentList;
      
      if( !this.dependentList ){
        this.loading = false;
        return;
      }
    } else {
      transactionObject.schoolId = requested_by.schoolId;
      transactionObject.schoolUniqueId = requested_by.schoolUUID;
    }

    const currentFilterObject = this.filterObject;

    if( currentFilterObject ){
      transactionObject.startDate = currentFilterObject.startDate ? currentFilterObject.startDate : null;
      transactionObject.endDate = currentFilterObject.endDate ? currentFilterObject.endDate : null;
      transactionObject.schoolId = currentFilterObject.school?.school_id ? currentFilterObject.school?.school_id : transactionObject.schoolId;
      transactionObject.schoolUniqueId = currentFilterObject.school?.school_uuid ? currentFilterObject.school?.school_uuid : transactionObject.schoolUniqueId;
    }

    let content: any = [];

    this.transactionService.getAllTransaction(transactionObject).subscribe( response => {
      
      const responseData = response?.data?.data ? response?.data?.data : [];
      const totalPaidAmount = response.data.totalPaidAmount;
      const totalFee = response?.data?.totalFee;
      const netSale = totalPaidAmount - totalFee;

      for( let i = 0; i < responseData?.length; i++ ){
        content.push({
          refno: responseData[i].transactionReferenceId,
          menu: responseData[i].packageName,
          amount: responseData[i].paidAmount.toFixed(2),
          seller: `${responseData[i].sellerFirstName} ${responseData[i].sellerLastName}`,
          student: responseData[i].studentName,
          soldtime: moment.utc(responseData[i].sellTime).tz(this.timezone).format('DD MMMM YYYY HH:mm A')
        });
      }
      let fileName = '';

      if(eventObj?.startDate){
        fileName = `SALE INVOICE (${moment(eventObj.startDate).format('DDMMYYYY')} - ${moment(eventObj.endDate).format('DDMMYYYY')})`
      } else {
        fileName = `ALL SALE INVOICE`
      }

      let props: any = {
        outputType: "save",
        returnJsPDFDocObject: true,
        fileName: fileName,
        orientationLandscape: false,
        compress: true,
        logo: {
          src: "./assets/images/logo.png",
          width: 53.33, //aspect ratio = width/height
          height: 26.66,
          margin: {
            top: 0, //negative or positive num, from the current position
            left: 0 //negative or positive num, from the current position
          }
        },
        stamp: {
          inAllPages: true,
          src: "assets/images/website-qr.png",
          width: 20, //aspect ratio = width/height
          height: 20,
          margin: {
            top: 0, //negative or positive num, from the current position
            left: 0 //negative or positive num, from the current position
          }
        },
        business: {
          name: "Nahra Solution",
          address: "",
          phone: "",
          email: "nahra.sysadm@gmail.com",
          email_1: "nahra.sysadm@gmail.com",
          website: "https://www.nahrasolution.com/",
        },
        contact: {
          label: "Invoice issued for:",
          name: `${currentFilterObject.school?.school_name}`
        },
        invoice: {
          label: `Sale Invoice`,
          invDate: `Invoice Date: ${currentDateTime}`,
          invGenDate: `Invoice Generated Date: ${currentDateTime}`,
          headerBorder: true,
          tableBodyBorder: true,
          header: [
          {
              title: "#", 
              style: { 
                width: 10
              } 
          },
          { title: "Reference No."}, 
          { title: "Menu"},
          { title: "Price (RM)"},
          { title: "Seller"},
          { title: "Student"},
          { title: "Sold On"}
          ],
          table: Array.from(Array(content.length), (item, index)=>([
              index + 1,
              content[index].refno,
              content[index].menu,
              content[index].amount,
              content[index].seller,
              content[index].student,
              content[index].soldtime
          ])),
          invDescLabel: "Invoice Note",
          invDesc: "",
          additionalRows: [{
            col1: 'Net Sale (A-B):',
            col2: `RM ${netSale.toFixed(2)}`,
            col3: 'ALL',
            style: {
                fontSize: 14 //optional, default 12
            }
          },{
            col1: 'Fee (B):',
            col2: `RM ${totalFee.toFixed(2)}`,
            col3: '3%',
            style: {
                fontSize: 10 //optional, default 12
            }
          }, {
            col1: 'Gross Sale (A):',
            col2: `RM ${totalPaidAmount.toFixed(2)}`,
            col3: 'ALL',
            style: {
                fontSize: 10 //optional, default 12
            }
          }]
        },
        footer: {
          text: "The invoice is created on a computer and is valid without the signature and stamp.",
        },
        pageEnable: true,
        pageLabel: "Page ",
      }
      const pdfObject = jsPDFInvoiceTemplate(props); //returns number of pages created

      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
}
