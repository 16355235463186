import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from 'src/app/authentication/auth/auth.component';
import { TeacherManagementComponent } from './pages/teacher-management/teacher-management.component';
import { TeacherShowcaseComponent } from './pages/teacher-showcase/teacher-showcase.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent, 
    canActivate: [AuthGuard],
    children: [
      { path: 'teacher/list', component: TeacherManagementComponent, canActivate: [AuthGuard], data: { title: ':: pos:: teacher-management ::' } },
      { path: 'teacher/showcase', component: TeacherShowcaseComponent, canActivate: [AuthGuard], data: { title: ':: pos :: teacher-showcase ::' } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TeacherRoutingModule { }
