import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { TransactionService } from '../../services/transaction.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-pending-transaction',
  templateUrl: './pending-transaction.component.html',
  styleUrls: ['./pending-transaction.component.scss'],
})
export class PendingTransactionComponent implements OnInit {

  public pendingTransaction: any[] = [];
  public checkedCount: number = 0;
  public FPXPaymentURL: any = '';
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ownDialogRef: MatDialogRef<PendingTransactionComponent>,
    // private cartHandler: CartHandlerService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    private transactionService: TransactionService
  ) {
    this.FPXPaymentURL = environment.FPXPaymentURL;
  }

  ngOnInit(): void {
    this.getPendingTransaction();
  }

  getPendingTransaction(){
    this.pendingTransaction = this.data.pendingTransaction;

    for( let i = 0; i < this.pendingTransaction.length; i++ ){
      this.pendingTransaction[i]['isChecked'] = false;
      this.getPackageByTransaction(this.pendingTransaction[i].transactionId, i);
    }
    this.ref.detectChanges();
  }

  getPackageByTransaction(transId: any, index: any) {

    let prepareObj = {
      transactionId: transId
    }

    return this.transactionService.getPackageByTransaction(prepareObj).subscribe((response) => {
      this.pendingTransaction[index]['packages'] = response.data[0];

      let cartIdArray: any[] = [];

      for(let i = 0; i < this.pendingTransaction[index]['packages'].length; i++){
        cartIdArray.push(this.pendingTransaction[index]['packages'][i].cartId);      
      }
      this.pendingTransaction[index]['cartIds'] = cartIdArray.join(',');
      
    },(error) => {

    });
  }

  cancelTransaction(
    transId: any,
    cartIds: any,
    index: any
  ) {
    
    let prepareObj = {
      transactionId: transId,
      cartIds: cartIds
    }

    return this.transactionService.cancelTransaction(prepareObj).subscribe((response: any) => {
      if(response.status.code == 200 ){
        this.pendingTransaction.splice(index, 1);
        if( this.pendingTransaction.length == 0 ){
          this.close(true);
        }
      }
    },(error) => {

    });
  }

  decodeDescription(description: any) {
    return decodeURI(description);
  }

  selectItem(cartObj: any, event: any) {
  }

  payNow(billcode: any){
    window.open(`${this.FPXPaymentURL}${billcode}`,'_self');
  }

  proceedCheckout() {
    this.router.navigate(['cart/cart-process']);
    this.close(true);
  }

  close(data?: any) {
    this.ownDialogRef.close();
  }

  async checkNow(billCode: any, index: any){
		this.loading = true;
		this.getLatestBillInformation(billCode, index);
	}

  getLatestBillInformation(billCode: any, index: any = null) {
		let transactionObj = {
			billCode: billCode
		}
		this.transactionService.getBill(transactionObj).subscribe(response => {

			if (
				response &&
				response.data &&
				response.data[0]
			) {
				const transactionData = response.data[0];

				this.updateLatestTransactionInformation(transactionData, billCode, index);
			} else {
				this.sharedService.showSnackBar('Failed getting latest transaction record');
				this.loading = false;
			}

		}, (error) => {
			this.sharedService.showSnackBar('Failed getting latest transaction record');
			this.loading = false;
		});
	}

  updateLatestTransactionInformation(transactionData: any, billCode: any, index: any = null) {
		let userDetail = <String>localStorage.getItem('userDetail');
    const requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

		const fpxStatusId = transactionData.billpaymentStatus;
		const fpxBillCode = billCode;
		const fpxTransactionId = transactionData.billpaymentInvoiceNo;

		if (
			fpxStatusId &&
			fpxBillCode &&
			fpxTransactionId
		) {

			if ( fpxStatusId != '1' ){
				this.loading = false;
				this.pendingTransaction[index]['isChecked'] = true;
				this.sharedService.showSnackBar('Pending Payment. Please proceed to Pay Now');
			} else {
				let transationObject = {
					fpx_reference_no: fpxTransactionId,
					fpx_permalink: fpxBillCode,
					status: fpxStatusId,
					user_id: requested_by.id
				}
	
				this.transactionService.updateTransaction(transationObject).subscribe((response) => {
					this.loading = false;
					if (response.data) {
						if (fpxStatusId == 1) {
							this.sharedService.showSnackBar('Payment Received. Thanks for subscribe');
						} else {
							this.sharedService.showSnackBar('Processing payment Failed. Please try again');
						}
						this.close();
					}
				}, (error) => {
					this.loading = false;
					this.sharedService.showSnackBar('Updating Payment Failed. Please contact administrator for more info.');
				});
			}
		}
	}
}
