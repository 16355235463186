<div #sidebar class="sidebar {{isToggle}} {{isShowMenu ? 'open' : ''}} me-0">
    <a class="mb-0 brand-icon" style="background: #fff;
    padding: 0.7rem 0.7rem;">
        <div style="display: flex;
        align-items: center;
        width: 100%;">
            <img width="50" height="50" [src]="'./assets/images/logo.png'" />
            <span style="margin-left:10px;" *ngIf="!this.isToggle">Nahra Solution</span>
            <!-- <span style="margin-left:10px;" *ngIf="!this.isToggle">v2.0.0-1</span> -->
        </div>
    </a>
    <div class="d-flex flex-column px-4 py-4 py-md-4" style="padding-top: 0 !important;height: 100vh; overflow-y: auto;" >
        <ul class="menu-list flex-grow-1 mt-3">
            <!-- <li><a class="m-link active" [routerLink]="['/pages/dashboard']"><i
                        class="icofont-ui-home fs-4"></i><span>Dashboard</span></a></li> -->
            <li><a *ngIf="currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER'" class="m-link active" [routerLink]="['/pages/dashboard']"><i
                class="icofont-ui-home fs-4"></i><span>Dashboard</span></a>
            </li>
            <li><a *ngIf="currentUserRole == 'SUPER ADMIN' || currentUserRole == 'STAFF'" class="m-link active"
                    [routerLink]="['/cart/cart-process']"><i class="icofont-prestashop fs-4"></i><span>Sale</span></a>
            </li>
            <li><a *ngIf="(currentUserRole == 'ADMIN' && currentSchoolCode == 'ALL') || currentUserRole == 'SUPER ADMIN' || currentUserRole == 'TEACHER' || currentUserRole == 'PARENTS'"
                    class="m-link active" [routerLink]="['/file/file-management']"><i class="icofont-money fs-4"></i><span>Topup</span></a></li>
            <li *ngIf="(( currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER' ) && !dependentDetail)"><a class="m-link active"
                    [routerLink]="['/dependent/list']"><i class="icofont-boy fs-4"></i> <span>Card Enrollment</span></a></li>
            <li *ngIf="(currentUserRole == 'ADMIN' && currentSchoolCode == 'ALL') || currentUserRole == 'SUPER ADMIN'"><a class="m-link active"
                [routerLink]="['/dependent/list']"><i class="icofont-card fs-4"></i> <span>Card Enrollment</span></a></li>
            <li *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'"><a class="m-link active"
                [routerLink]="['/transaction/list']"><i class="icofont-list fs-4"></i><span>Sales Report</span></a></li>
            <li *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'"><a class="m-link active"
                [routerLink]="['/balance-report/list']"><i class="icofont-file-spreadsheet fs-4"></i><span>Balance Report</span></a></li>    
            <li *ngIf="currentUserRole == 'PARENTS' || currentUserRole == 'TEACHER'"><a class="m-link active"
                [routerLink]="['/transaction/list']"><i class="icofont-history fs-4"></i><span>Purchase History</span></a></li>
            <li *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'"><a class="m-link active"
                    [routerLink]="['/user/list']"><i class="icofont-user fs-4"></i><span>Users</span></a></li>
            <li (click)="toggleCollapse('settings')" class="collapsed show-hide">
                <a class="m-link active cursor" data-bs-toggle="collapse" data-bs-target="#menu-Settings"
                    *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN' || currentUserRole == 'STAFF'"><i
                        class="icofont-settings-alt fs-4"></i><span>Settings</span><span
                        class="arrow icofont-dotted-down ms-auto text-end fs-5"></span></a>
                <ul class="sub-menu" [ngClass]="(activeTab == 'settings' && status== true) ? 'collapse show':'collapse'"
                    id="menu-Settings">
                    <li *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN' || currentUserRole == 'STAFF'"><a class="ms-link"
                            [routerLink]="['/class/class-management']"><span>Category</span></a></li>
                    <li *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN' || currentUserRole == 'STAFF'"><a class="ms-link"
                            [routerLink]="['/menu/menu-management']"><span>Menu</span></a></li>
                    <li *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'"><a class="ms-link"
                        [routerLink]="['/announcement']"><span>Announcement</span></a>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="menu-list">
            <li><a class="m-link active" [routerLink]="['/pages/help']"><i
                        class="icofont-support-faq fs-4"></i><span>FAQ</span></a></li>
        </ul>
        <!-- Theme: Switch Theme -->
        <ul class="list-unstyled mb-0">
        </ul>
        <button type="button" class="btn sidebar-mini-btn" style="color: #ffffff" (click)="toggleSideBarMini()">
            <span class="ms-2"><i class="icofont-bubble-right"></i></span>
        </button>
    </div>
</div>