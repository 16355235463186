<div id="meetingSDKElement">
</div>
<div class="body d-flex py-lg-3 py-md-2">
  <div class="container-xxl">
    <div class="row clearfix">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 order-lg-1  order-sm-2 order-2">
        <div class="card border-0">
          <div class="card-body container-loader-meeting container-img">
            <img src="assets/images/zoom-logo.png" class="img-fluid mh-50" width="500px" alt="No Data">
            <div *ngIf="!action" class="spinner-border text-primary fw-bold loader-fpx" role="status">
            </div>
            <br /><br />
            <div *ngIf="!action">
              Meeting Checking. Please wait...
            </div>
            <div *ngIf="action == 'exit'" role="alert" class="alert alert-info" style="width:90%;">
            <i class="icofont-info-circle"></i>&nbsp;&nbsp;
            You may close this browser
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>