import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseService } from 'src/app/modules/course/services/course.service';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { PackageDetailComponent } from '../package-detail/package-detail.component';

@Component({
  selector: 'package-filter',
  templateUrl: './package-filter.component.html',
  styleUrls: ['./package-filter.component.sass']
})
export class PackageFilterComponent implements OnInit {
  @Output() onAddPackageEvent = new EventEmitter<any>();
  @Output() onChangeLevel = new EventEmitter<any>();
  filter_search: any = null;
  filter_course: any = null;

  filter_level: any = null;
  filter_sublevel: any = null;

  courses: any[] = [];
  levels: any[] = [];
  sublevels: any[] = [];

  public filterLevelName: any = null;
  public filterSubLevelName: any = null;

  constructor(private courseService: CourseService, public dialog: MatDialog, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.getCourseLevel().subscribe((data) => {
      this.levels = data[0];
    });
    this.getCourses();
  }

  getCourses() {

    const userDetail = <String>localStorage.getItem("userDetail");
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    const requestObj = {
      isPublished: null,
      schoolId: requestedBy?.schoolId
    }

    this.courseService.getAllCourse(requestObj).subscribe( (data) => {
      this.courses = data.data;
    })
  }

  addNewPackage() {
    const dialogRef = this.dialog.open(PackageDetailComponent, {
      data: { },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onAddPackageEvent.emit(result);
    });
  }

  changeLevelHandler(level: any): void {
    this.filter_sublevel = null;
    this.filter_level = level.id;
    this.filterLevelName = level.name;
    this.onChangeLevel.emit({filter_level: this.filter_level, filter_sublevel: this.filter_sublevel});
    this.sharedService
      .getCourseSubLevel({ level_id: this.filter_level })
      .subscribe((data) => {
        this.sublevels = data[0];
      });
  }

  changeSubLevelHandler(sublevel: any): void {
    this.filter_sublevel = sublevel.id;
    this.filterSubLevelName = sublevel.name;
    this.onChangeLevel.emit({filter_level: this.filter_level, filter_sublevel: this.filter_sublevel});
  }
}
