import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DependentService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}
  
  saveDependent(dependentObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-dependent`, dependentObj, this.httpOptions);
  }
  
  getDependent(dependentObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/get-dependent`, dependentObj, this.httpOptions);
  }

  dependentToken(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/dependent-token`, reqBody, this.httpOptions);
  }

  passcodeLogin(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/passcode-login`, reqBody, this.httpOptions);
  }

  validateDependentAccessToken(reqBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/validate-dependent-access-token`, reqBody, this.httpOptions);
  }

  assignCardDependent(dependentObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/assign-card`, dependentObj, this.httpOptions);
  }

  notifyMissingCard(dependentObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/notify-missing-card`, dependentObj, this.httpOptions);
  }

  getClass(classObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/get-class`, classObj, this.httpOptions);
  }

  getSchool(schoolObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/get-school`, schoolObj, this.httpOptions);
  }

  notifyLimitReached(dependentObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/notify-limit-reached`, dependentObj, this.httpOptions);
  }
}
