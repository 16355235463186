import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { SharedService } from '../../../shared/services/shared.service';
import { CartHandlerService } from '../../services/cart-handler.service';
import { CartService } from '../../services/cart.service';
import { LayoutServiceService } from '../../../../services/layout-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransactionService } from '../../../transaction/services/transaction.service';
import { environment } from '../../../../../environments/environment';
import moment from 'moment-timezone';
import { CourseService } from '../../../course/services/course.service';
import { PackageService } from '../../../package/services/package.service';
import { DependentService } from '../../../dependent/services/dependent.service';
import { UserService } from '../../../user/services/user.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DependentListComponent } from '../../components/dependent-list/dependent-list.component';
import { BalanceReportService } from '../../../balance-report/services/balance-report.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cart-process',
  templateUrl: './cart-process.component.html',
  styleUrls: ['./cart-process.component.scss'],
})
export class CartProcessComponent implements OnInit {
  rfidData: string = '';
  storedOldRFIDData: string = '';
  cardNo: string = '';
  scanRFID: boolean = false;
  isCheckBalance: boolean = false;
  rfidTimeout: any; // A timeout to distinguish RFID input
  balanceData: any = [];

  selectedItems: any;

  levels: any[] = [];
  sublevels: any[] = [];

  paymentOption: any = 1;
  totalFee: number = 0;
  isLoading: boolean = false;
  public themeDarkClass: string = "light";
  public themeltrClass: string = "ltr";
  public ngUnsubscribe = new Subject();

  public baseURL: any = '';
  public FPXPaymentURL: any = '';
  public paymentProcessURL: any = '';

  public categories: any = [];
  public menus: any = [];
  public carts: any = [];
  public dependent: any = [];
  public dependentTodaySpent: any = 0;

  public validateStudentForm: any = null;
  public isValidateLoading: boolean = false;

  public showMissingCard: boolean = false;
  public storeTimeoutSearch: any = null;

  constructor(
    private layoutServiceService: LayoutServiceService,
    private cartService: CartService,
    private cartHandler: CartHandlerService,
    public sharedService: SharedService,
    private router: Router,
    private dialog: MatDialog,
    private transactionService: TransactionService,
    private courseService: CourseService,
    private packageService: PackageService,
    private dependentService: DependentService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private balanceReportService: BalanceReportService
  ) {
    this.validateStudentForm = new FormGroup({
      searchText: new FormControl(null)
    });

    this.layoutServiceService.themeDarkClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeDarkClass => {
      this.themeDarkClass = themeDarkClass;
    });
    this.layoutServiceService.themeltrClassChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(themeltrClass => {
      this.themeltrClass = themeltrClass;
    });

    this.baseURL = environment.baseURL;
    this.paymentProcessURL = environment.paymentProcessURL;

    this.FPXPaymentURL = environment.FPXPaymentURL;
  }

  ngOnInit(): void {

    // setInterval(function() {
    //   const focusedElement = document.activeElement;
    //   console.log("Currently focused element: ", focusedElement);
    // }, 1000); // 1000 milliseconds (1 second)
    
    this.getCategory();
    this.getCart();

    this.validateStudentForm = this.formBuilder.group({
      searchText: [null]
    });
  }

  removeItem(itemObj: any) {
    let prepareObj = {
      cart_id: itemObj.id,
    };

    this.cartService.removeCart(prepareObj).subscribe((data: any) => {
      this.sharedService.showSnackBar('Item removed');
      this.getCart();
    }, (error) => {
      this.sharedService.showSnackBar('Fail to remove package');
    });
  }

  getSelectedItems() {
    this.selectedItems = this.cartHandler.getSelectedItems();
    this.totalFee = 0;

    this.selectedItems.forEach((item: any) => {
      this.totalFee = this.totalFee + item.fee;
    });
  }

  checkLevelName(level_id: any) {
    let display = '';
    this.levels.forEach((level) => {
      if (level.id == level_id) {
        display = level.name;
      }
    });
    return display;
  }

  checkSublevelName(sublevel_id: any) {
    let display = '';
    this.sublevels.forEach((sublevel) => {
      if (sublevel.id == sublevel_id) {
        display = sublevel.name;
      }
    });
    return display;
  }

  submitPurchase(element: HTMLButtonElement) {
    
    element.blur();
    this.isLoading = true;
    this.isValidateLoading = true;
    
    if( Number(this.balanceData?.[0]?.balance) < this.totalFee ){
      Swal.fire({
        title: `Insufficient balance`,
        text: `INFO_1,${this.storedOldRFIDData},${Number(this.balanceData?.[0]?.balance)},${this.totalFee}`,
        icon: 'warning'
      });
      this.isLoading = false;
      this.isValidateLoading = false;
      return;
    }

    if( Number(this.dependent[0]?.spend_limit) < this.dependentTodaySpent ){
      Swal.fire({
        title: `Limit has reached`,
        text: `INFO_2,${this.storedOldRFIDData},${Number(this.dependent[0]?.spend_limit)},${this.dependentTodaySpent}`,
        icon: 'error'
      });
      this.notifyLimitReached();
      this.isLoading = false;
      this.isValidateLoading = false;
      return;
    }

    console.log(parseFloat((Number(this.dependent[0]?.spend_limit) - this.dependentTodaySpent).toFixed(2)));
    console.log(this.totalFee);
    
    if( parseFloat((Number(this.dependent[0]?.spend_limit) - this.dependentTodaySpent).toFixed(2)) < this.totalFee ) {
      Swal.fire({
        title: `Limit has reached`,
        text: `INFO_3,${this.storedOldRFIDData},${Number(this.dependent[0]?.spend_limit)},${this.dependentTodaySpent},${this.totalFee}`,
        icon: 'error'
      });
      this.notifyLimitReached();
      this.isLoading = false;
      this.isValidateLoading = false;
      return;
    }

    let userDetail = <String>localStorage.getItem('userDetail');
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    let cartIds: any[] = [];
    let amounts: any[] = [];
    let totalAmount: number = 0;

    for (let i = 0; i < this.carts.length; i++) {
      cartIds.push(this.carts[i].id);
      amounts.push(this.carts[i].fee);

      totalAmount += this.carts[i].fee;
    }

    let ref_id = "NS-" + (Math.floor(Math.random() * 10000) + 90000) + moment().utc().format('YYYYMMDDHHmmss');
    totalAmount = totalAmount ? Number(`${totalAmount}00`) : 0;

    let prepareObj = {
      reference_id: ref_id,
      fpx_permalink: 'SALE',
      status: "completed",
      user_id: requested_by.id,
      dependent_id: this.dependent[0]?.id,
      cart_ids: cartIds.join(),
      paid_amounts: amounts.join()
    }
    
    this.cartService.submitCartPurchase(prepareObj).subscribe((data) => {

      this.removeDependent();
      let self = this;
      
      setTimeout( () => {
        self.sharedService.showSnackBar('Success');
        // location.reload();
        self.getCart();
        self.isLoading = false;
        this.isValidateLoading = false;
      }, 2000);

    }, (error) => {
      this.sharedService.showSnackBar('Please try again');
      this.isLoading = false;
      this.isValidateLoading = false;
    });
  }

  notifyLimitReached(){
    let isNotify: boolean = true;

    let limitNotify: any = localStorage.getItem('limitNotify') ? JSON.parse(localStorage.getItem('limitNotify')) : [];

    if( limitNotify?.length > 0 ){
      for( let i = 0; i < limitNotify.length; i++ ){
        if(this.dependent[0]?.id == limitNotify[i]?.dependentId){
          const duration = moment.duration(moment().diff(limitNotify[i]?.notifiedOn));
          const durationMinutes = duration.asMinutes();

          if( durationMinutes < 15 ){
            isNotify = false;
          } else {
            limitNotify.splice(i, 1);
            localStorage.setItem('limitNotify', JSON.stringify(limitNotify));
          }

          break;
        }
      }
    }
    
    if( isNotify ){

      const notifyObject = {
        spendLimit: Number(this.dependent[0]?.spend_limit),
        todaySpent: this.dependentTodaySpent,
        totalFee: this.totalFee,
        notifiedOn: moment(),
        dependentId: this.dependent[0]?.id,
        parentId: this.dependent[0]?.parent_id,
        dependentName: this.dependent[0]?.name
      };

      limitNotify.push(notifyObject);
      localStorage.setItem('limitNotify', JSON.stringify(limitNotify));

      const prepareObj = {
        parentId: notifyObject?.parentId,
        dependentName: notifyObject?.dependentName,
        spendLimit: `RM ${notifyObject?.spendLimit}.00`,
        todaySpent: `RM ${notifyObject?.todaySpent}.00`,
        totalFee: `RM ${notifyObject?.totalFee}.00`,
      };
  
      this.dependentService.notifyLimitReached(prepareObj).subscribe( (response) => {
  
      },(error) => {
  
      });
    }
  }

  getCategory() {
    this.menus = [];
    const userDetail = <String>localStorage.getItem("userDetail");
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    const requestObj = {
      isPublished: true,
      schoolId: requestedBy?.schoolId
    }

    this.courseService.getAllCourse(requestObj).subscribe( (data) => {
      this.categories = data.data;
    }, (error) =>{

    });
  }

  getMenu(categoryId: any){
    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    let menuObject = { 
      categoryId: categoryId,
      isPublished: true,
      schoolId: requested_by.schoolId,
      schoolUniqueId: requested_by.schoolUUID
    }
    return this.packageService.getAllPackage(menuObject).subscribe((data: any) => {
      this.menus = data.data;
      this.getCart();
    });
  }

  addToCart(itemObject: any) {
    let prepareObj = {
      user_id: this.sharedService.getCurrentUser().id,
      package_id: itemObject.id
    }

    this.cartService.addCart(prepareObj).subscribe((data) => {

      this.getCart();
    }, (error) => {

    });

  }

  getCart() {
    let prepareObj = {
      user_id: this.sharedService.getCurrentUser().id,
      status: '2'
    }
    return this.cartService.getCart(prepareObj).subscribe((data) => {
      this.carts = data.data;

      this.totalFee = 0;
      this.carts.forEach((item: any) => {
        this.totalFee += item.fee;
        this.totalFee = Number(this.totalFee.toFixed(2));
      });
    });
  }

  async checkDependent(event?) {
    event?.stopPropagation();

    if(this.showMissingCard){
      return;
    }
    this.isValidateLoading = true;

    if(this.cardNo){

      const prepareObj = {
        cardNo: this.cardNo
      };
  
      this.balanceReportService.checkBalanceByCardNo(prepareObj).subscribe(async (response) => {
        this.cardNo = '';

        this.dependent = [];
        let tempDependent = [];
        tempDependent = response?.data;
  
        if( tempDependent?.length > 0 ){
          const tempDependentId = tempDependent?.[0]?.id;
          await this.getTodayTransaction(tempDependentId);

          this.balanceData = tempDependent;
          this.dependent = tempDependent;
        } else {
          this.sharedService.showSnackBar('Student details not found');
        }

        this.isValidateLoading = false;
      }, (error) => {
        this.isValidateLoading = false;
        this.cardNo = '';
        this.sharedService.showSnackBar('Fail to get student details. Please try again');
      })
    } else {
      this.isValidateLoading = false;
      this.cardNo = '';
      this.sharedService.showSnackBar('Student details not found');
    }
    
  }

  async getTodayTransaction(dependentId?){
    let promise = new Promise((resolve, reject) => {
      const filterObject = {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        dependent_id: dependentId ? dependentId : this.dependent[0]?.id
      };
  
      this.transactionService.getAllTransaction(filterObject).subscribe( response => {
  
        this.dependentTodaySpent = Number(response?.data?.totalPaidAmount);
        
        resolve(true);
  
      }, (error) => {
        resolve(false);
      });
    });

    return promise;
  }

  removeDependent(){
    this.dependent = [];
    this.showMissingCard = false;
    this.cardNo = '';
    this.scanRFID = false;
    this.isCheckBalance = false;
    this.balanceData = [];
    this.validateStudentForm.reset();
  }

  calculateBalanceLimit(){
    const spendLimit = Number(this.dependent[0]?.spend_limit);
    return (spendLimit - this.dependentTodaySpent).toFixed(2);
  }

  searchDependent(event) {
    event.stopPropagation();

    if(!this.showMissingCard){
      return;
    }

    this.isValidateLoading = true;

    let validateStudentFormValue = this.validateStudentForm.value;

    let prepareObj = {
      id: null,
      parent_id: null,
      card_no: null,
      searchText: validateStudentFormValue?.searchText,
      isValidateCard: true
    }

    if( validateStudentFormValue?.searchText ){
      this.dependentService.getDependent(prepareObj).subscribe( (response) => {
        this.isValidateLoading = false;

        let dependents = response?.data?.result;

        const dialogRef = this.dialog.open( DependentListComponent, {
          data: {
            dependents: dependents
          },
          panelClass: 'dependent-panel'
        } );

        dialogRef.afterClosed().subscribe( async dialogResult => {
          if (dialogResult) {
            this.dependent = [];
            let tempDependent = [];
            tempDependent.push(dialogResult?.dependent);

            if( tempDependent?.length > 0 ){
              this.cardNo = tempDependent?.[0]?.card_no;
              this.storedOldRFIDData = this.cardNo;
              const tempDependentId = tempDependent?.[0]?.id;
              await this.checkBalance();
              await this.getTodayTransaction(tempDependentId);

              this.dependent = tempDependent;
            }
          }
        });

      }, (error) => {
        this.isValidateLoading = false;
        this.sharedService.showSnackBar('Fail to get student details. Please try again');
      })
    } else {
      this.isValidateLoading = false;
      this.sharedService.showSnackBar('Student details not found');
    }
    
  }

  handleMissingCard(){
    this.showMissingCard = true;
  }

  handleNotifyMissing(){
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Are you sure to mark selected Student ID as Missing?',
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if( result?.data?.confirm == true ){
        this.notifyMissing();
      }
    });
  }

  notifyMissing(){
    const prepareObj = {
      id: this.dependent[0].id,
      status: 11 //MISSING
    };

    this.dependentService.notifyMissingCard(prepareObj).subscribe( (response) => {

    },(error) => {

    });
  }

  reformatCurrency(currency){
    if( currency ){
      return currency.toFixed(2);
    } else {
      return currency;
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    console.log(event.key);
    if(
      (
        (!this.scanRFID && this.dependent?.length == 0) ||
        (this.scanRFID && this.dependent?.length > 0)
      )
       &&
      !this.isCheckBalance
    ){
      return;
    }
    
    // Clear any existing timeout and set a new one
    clearTimeout(this.rfidTimeout);

    // Capture RFID data when any key is pressed, excluding the "Enter" key
    const key = event.key;

    if (key !== 'Enter' && this.isValidRFIDInput(key)) {
      this.rfidData = this.rfidData || ''; // Initialize if not set
      this.rfidData += key;
  
      // Handle RFID data once you've received it completely
      if (this.isRFIDDataComplete(this.rfidData)) {
        this.handleRfidData(this.rfidData);
        this.rfidData = ''; // Reset for the next capture
      } else {
        // If the input is not valid RFID, set a timeout to reset the RFID data
        let self = this;

        this.rfidTimeout = setTimeout(() => {
          console.log('RFID cleared', this.rfidData);
          self.rfidData = '';
        }, 600); // Adjust the timeout duration as needed
      }
    } else if (key !== 'Enter'){
      console.log('RFID cleared', this.rfidData);
      this.rfidData = '';
    } else if (key == 'Enter' && !this.isRFIDDataComplete(this.rfidData)){
      console.log('RFID cleared', this.rfidData);
      this.rfidData = '';
    }
  }

  handleRfidData(data: string) {
    // Process and display RFID data as needed
    console.log('Received RFID Data:', data);
    this.cardNo = data;
    this.storedOldRFIDData = this.cardNo;
    if(this.isCheckBalance){
      this.checkBalance();
    } else {
      this.checkDependent();
    }
  }

  isRFIDDataComplete(data: string): boolean {
    // Define a condition to determine when the RFID data is complete.
    // For example, you can check for a specific delimiter or data length.
    // Return true when the data is complete; otherwise, return false.
    return data?.length >= 10; // Example: RFID data has a length of 10 characters
  }

  isValidRFIDInput(key: string): boolean {
    // Define a function to validate whether a key press is a valid RFID input
    // You can implement specific criteria here to filter out unwanted input.
    // For example, you can check if the key is a number or a specific character.
    // Return true for valid RFID input; otherwise, return false.
    return /^[0-9]$/.test(key); // Only allow numeric input
  }

  handleIsScanRFID(){
    this.scanRFID = true;
  }

  handleIsCheckBalance(){
    this.isCheckBalance = true;
  }

  async checkBalance(){
    let promise = new Promise((resolve, reject) => {
      
      if(!this.cardNo){
        resolve(false);
      }
  
      this.isValidateLoading = true;
  
      const prepareObj = {
        cardNo: this.cardNo
      };
  
      this.balanceReportService.checkBalanceByCardNo(prepareObj).subscribe( (response) => {
        this.cardNo = '';
        this.balanceData = response?.data;
  
        this.isValidateLoading = false;
        resolve(true);
      },(error) => {
        this.isValidateLoading = false;
        this.sharedService.showSnackBar('Card details not found');
        resolve(false);
      });
    });

    return promise;
  }
}
