import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { map } from 'rxjs/operators';
import { CourseService } from 'src/app/modules/course/services/course.service';
import { Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment-timezone';
import xlsx from "json-as-xlsx";

@Component({
  selector: 'app-upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.scss']
})
export class UploadListComponent implements OnInit, OnDestroy {

  @Input() packageCourseData: any = null;

  public fileUploads: any = [];
  private subscriptionRefreshCourseFile: Subscription;
  public selectedPackageCourseData: any = null;
  public currentTab: any = 'file';
  public fileUploadFilter: any = [];
  public currentFileType: any = 'others';

  public userDetail = <String>localStorage.getItem("userDetail");
  public userData = this.userDetail ? JSON.parse(this.userDetail.toString()) : null;
  public currentUserRole: any = '';

  public limit: number = 30;
  public defaultLimit: number = 30;
  public offset: number = 0;
  public pageIndex: number = 0;
  public prevPage: number = 0;
  public totalResult: number = 0;
  public pageSizeOptions = [];

  public filterStartDate: any = '';
  public filterEndDate: any = '';
  public filterStatus: any = '';
  public totalRechargeAmount: any = 0;
  public timezone: any = '';

  public searchText: any = '';
  public timeoutSearch: any = null;
  
  constructor(
    private uploadService: FileUploadService,
    private courseService: CourseService,
    private ref: ChangeDetectorRef
  ) {
    this.timezone = "Asia/Kuala_Lumpur";
    this.currentUserRole = this.userData.usertype_name;

    if( this.currentUserRole == 'ADMIN' || this.currentUserRole == 'SUPER ADMIN' ){
      this.filterStartDate = moment().format('YYYY-MM-DD');
      this.filterEndDate = moment().format('YYYY-MM-DD');
    }
  }

  ngOnInit(): void {
    this.subscriptionRefreshCourseFile = this.uploadService.getSubscriptionRefreshCourseFile().subscribe((value: any)=> {
      this.getAllRecharge();
    }, (error : any) => {

    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedPackageCourseData = this.packageCourseData;

    this.getAllRecharge();
  }

  getAllRecharge() {

    let requestObject = {
      userId: this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ? this.userData.id : null,
      limit: this.limit,
      offset: this.offset,
      startDate: this.filterStartDate,
      endDate: this.filterEndDate,
      rechargeStatus: this.filterStatus,
      searchText: this.searchText
    }

    this.courseService.getAllRechargeFileOrById(requestObject).subscribe((response) => {
      this.fileUploads = response?.data?.result ? response?.data?.result : [];

      this.totalResult = 0;
      this.totalRechargeAmount = response?.data?.totalRechargeAmount ? response?.data?.totalRechargeAmount.toFixed(2) : 0;
      
      if( 
        this.offset == 0 &&
        this.defaultLimit == this.limit &&
        this.prevPage == this.pageIndex
      ) {
        this.totalResult = response?.data?.countTotal;
      }
      
      this.prevPage = this.pageIndex;
      this.defaultLimit = this.limit;

      this.filterFileUploads();
    }, (error) => {
      this.fileUploads = [];
    });
  }

  ngOnDestroy(){
    this.subscriptionRefreshCourseFile.unsubscribe();
  }

  changeTab(tab: any = 'file'){
    this.currentTab = tab;
    this.filterFileUploads();
  }

  filterFileUploads(){
    this.fileUploadFilter = [];
    if( this.currentTab == 'file' || this.currentTab == 'image'){
      for( let i = 0; i < this.fileUploads.length; i++ ){
        let splitArray = this.fileUploads[i].fileMime.split('/');
        if( splitArray[0] == "application" ){
          if(
            splitArray[1] == 'msword' ||
            splitArray[1] == 'vnd.openxmlformats-officedocument.wordprocessingml.document'
          ) {
            this.currentFileType = 'word';
          } else if ( splitArray[1] == 'pdf' ) {
            this.currentFileType = 'pdf';
          } else if (
            splitArray[1] == 'vnd.ms-powerpoint' ||
            splitArray[1] == 'vnd.openxmlformats-officedocument.presentationml.presentation'
          ) {
            this.currentFileType = 'powerpoint';
          } else if (
            splitArray[1] == 'vnd.ms-excel' ||
            splitArray[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ) {
            this.currentFileType = 'excel';
          } else {
            this.currentFileType = 'pdf';
          }
          this.fileUploads[i]['fileType'] = this.currentFileType;
          this.fileUploadFilter.push(this.fileUploads[i]);
        } else if ( splitArray[0] == "text" ) {
          this.fileUploads[i]['fileType'] = this.currentFileType;
          this.fileUploadFilter.push(this.fileUploads[i]);
        } else if( splitArray[0] == "image" ){
          this.fileUploads[i]['fileType'] = 'image';
          this.fileUploadFilter.push(this.fileUploads[i]);
        } else {
          this.fileUploads[i]['fileType'] = 'pdf';
          this.fileUploadFilter.push(this.fileUploads[i]);
        }
      }
    } else if ( this.currentTab == 'video' ){
      for( let i = 0; i < this.fileUploads.length; i++ ){
        let splitArray = this.fileUploads[i].fileMime.split('/');
        if( splitArray[0] == "video" ){
          this.fileUploads[i]['fileType'] = 'video';
          this.fileUploadFilter.push(this.fileUploads[i]);
        }
      }
    } else if ( this.currentTab == 'image' ){
      for( let i = 0; i < this.fileUploads.length; i++ ){
        let splitArray = this.fileUploads[i].fileMime.split('/');
        if( splitArray[0] == "image" ){
          this.fileUploads[i]['fileType'] = 'image';
          this.fileUploadFilter.push(this.fileUploads[i]);
        }
      }
    }
    // this.ref.detectChanges();
  }

  filterTable(){
    this.getAllRecharge();
  }

  handlePageEvent(event: PageEvent) {
    this.limit = event.pageSize;
    this.defaultLimit = this.limit;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex * this.limit;
    this.getAllRecharge();
  }

  getPageSizeOptions(){
    return [ 20, 50, 100, 200, this.totalResult];
  }

  resetFilterEndDate(){
    this.filterEndDate = "";
  }

  handleExport(){
    let requestObject = {
      userId: this.currentUserRole == 'PARENTS' || this.currentUserRole == 'TEACHER' ? this.userData.id : null,
      limit: this.totalResult,
      offset: 0,
      startDate: this.filterStartDate,
      endDate: this.filterEndDate,
      rechargeStatus: this.filterStatus,
      searchText: this.searchText
    }

    let content: any = [];

    this.courseService.getAllRechargeFileOrById(requestObject).subscribe((response) => {
      const responseData = response?.data?.result ? response?.data?.result : [];

      const totalRechargeAmount = response?.data?.totalRechargeAmount ? response?.data?.totalRechargeAmount.toFixed(2) : 0;

      for( let i = 0; i < responseData?.length; i++ ){
        content.push({
          receiptNumber: responseData[i].ReceiptNumber,
          user: responseData[i].rechargeName,
          email: responseData[i].rechargeEmail,
          phoneno: responseData[i].rechargePhoneNo,
          status: responseData[i].rechargeStatus.toLowerCase() == 'system' ? 'deposit' : responseData[i].rechargeStatus.toLowerCase(),
          topup: "RM " + Math.abs(responseData[i].rechargeAmount)?.toFixed(2),
          timestamp: moment.utc(responseData[i].rechargeDate).format('DD MMM YYYY hh:mm A')
        });
      }

      for( let i = 0; i < 3; i++ ){
        content.push({
          receiptNumber: '',
          user: '',
          email: '',
          phoneno: '',
          status: '',
          topup: '',
          timestamp: ''
        });
      }

      content.push({
        receiptNumber: 'Total Topup',
        user: '',
        email: '',
        phoneno: '',
        status: '',
        topup: '',
        timestamp: ''
      });

      content.push({
        receiptNumber: "RM " + totalRechargeAmount,
        user: '',
        email: '',
        phoneno: '',
        status: '',
        topup: '',
        timestamp: ''
      });

      let data = [
        {
          sheet: "Topup",
          columns: [
            { label: "Receipt No.", value: "receiptNumber" },
            { label: "User", value: "user" },
            { label: "Email", value: "email" },
            { label: "Phone No.", value: "phoneno" },
            { label: "Status", value: "status" },
            { label: "Topup Value", value: "topup" },
            { label: "Topup Timestamp", value: "timestamp" }
          ],
          content: content
        }
      ];

      const exportedDate = moment().utc().format('YYYYMMDDHHmmss')

      let fileName = `${exportedDate}_TOPUP_REPORT`;
      
      if( this.filterStatus ){
        if( this.filterStatus == '7' ){
          fileName = `${fileName}_Pending Approval`;
        } else if( this.filterStatus == '8' ){
          fileName = `${fileName}_Approved`;
        } else if( this.filterStatus == '9' ){
          fileName = `${fileName}_Rejected`;
        }
      }

      if( this.filterEndDate ){
        fileName = `${fileName}_${this.filterStartDate}_${this.filterEndDate}`;
      }
      
      let settings = {
        fileName: fileName, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      }
      
      xlsx(data, settings) // Will download the excel file

    }, (error) => {
    });
  }

  search(event){
    clearTimeout(this.timeoutSearch);

    this.timeoutSearch = setTimeout(() => {
      this.getAllRecharge();
    }, 1200);
  }
}