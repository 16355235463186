<div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around start">
  <ng-progress #progressBar></ng-progress>
  <div fxFlex="100" class="full-width">
    <h2 *ngIf="teacherObj">{{ !teacherObj ? null : teacherObj.name_first.concat(' ', teacherObj.name_last, "'s") }}
      Details</h2>
    <h2 *ngIf="teacherObj == null">New Teacher</h2>
  </div>

  <div fxFlex="100" class="full-width">
    <form [formGroup]="form">

      <div class="form-group">
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="form-field__extend">
              <mat-label>Main Subject</mat-label>
              <input matInput type="text" formControlName="subject" placeholder="Main Subject" />
              <!-- <mat-error *ngIf="f['name'].errors"
                >Course name is required</mat-error
              > -->
            </mat-form-field>
          </div>

          <div fxFlex="50">
            <mat-form-field appearance="outline" class="form-field__extend">
              <mat-label>Total Experience (year)</mat-label>
              <input matInput type="text" formControlName="experience_year" placeholder="Total Experience In Year" />
              <!-- <mat-error *ngIf="f['name'].errors"
                >Course name is required</mat-error
              > -->
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="form-field__extend">
              <mat-label>Qualification</mat-label>
              <input matInput type="text" formControlName="qualification" placeholder="Bachelor's Degree / Etc." />
              <!-- <mat-error *ngIf="f['name'].errors"
                >Course name is required</mat-error
              > -->
            </mat-form-field>
          </div>
          <div fxFlex="50" *ngIf="!teacherObj">
            <mat-label>Teachers</mat-label>
            <select formControlName="user_id" class="form-select pointer">
              <option value=""></option>
              <option *ngFor="let teacher of teachers" [value]="teacher['id']">
                {{teacher['name_first']}} {{teacher['name_last']}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div fxFlex="100" *ngIf="currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN'">
          <mat-label>Classification</mat-label>
            <select formControlName="classification" class="form-select pointer">
              <option value=""></option>
              <option value="2" >Normal</option>
              <option value="1" >Premium</option>
            </select>
        </div>
      </div>
      <br/>
      <div class="form-group" *ngIf="!selectedFiles || !uploadedFile.name">
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
          <div fxFlex="100">
            <button type="button" [disabled]="isSubmit" mat-raised-button (click)="fileInput.click()">Choose
              Video&nbsp;&nbsp;<i class="icofont-ui-video-play"></i></button>
            <input hidden #fileInput type="file" id="file" (change)="selectFile($event)" accept="video/*" />
          </div>
        </div>
      </div>
      <mat-card-content *ngIf="selectedFiles">
        <div fxFlex fxLayout="column">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" class="file-list"
            *ngFor="let file of selectedFiles; let i = index;">
            <div fxFlex="90" fxLayoutAlign="initial">
              <span>{{trimFileName(file.name)}}</span>
            </div>
            <div fxFlex="10" fxLayoutAlign="end">
              <button mat-mini-fab [disabled]="isSubmit" color="warn" (click)="removeFile(i)">
                <i class="icofont-ui-close"></i>
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="uploadedFile && uploadedFile.name">
        <div fxFlex fxLayout="column">
          <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" class="file-list">
            <div fxFlex="90" fxLayoutAlign="initial">
              <span>{{trimFileName(uploadedFile.name)}}</span>
            </div>
            <div fxFlex="10" fxLayoutAlign="end">
              <button mat-mini-fab [disabled]="isSubmit" color="warn" (click)="deleteFile()">
                <i class="icofont-ui-close"></i>
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
      <br/>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Achievements
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="form-group">
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
              <div fxFlex="90">
                <mat-form-field appearance="outline" class="form-field__extend full-width">
                  <mat-label>Enter achievements</mat-label>
                  <textarea matInput formControlName="achievements"></textarea>
                  <!-- <mat-error *ngIf="f['name'].errors"
                    >Course name is required</mat-error
                  > -->
                </mat-form-field>
              </div>

              <div fxFlex="10" class="mini-fab__container">
                <button mat-mini-fab (click)="addAchievement()">
                  <i class="icofont-ui-add"></i>
                </button>
              </div>

              <div fxFlex="100" class="mat-list__container">
                <mat-list>
                  <mat-list-item *ngIf="achievements.length == 0">
                    No achievements set ...
                  </mat-list-item>
                  <mat-list-item *ngFor="let achievement of achievements; let i = index" class="mat-list-item__content">
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
                      <div fxFlex="80">
                        {{achievement.achievement}}
                      </div>
                      <div fxFlex="20" class="centered">
                        <button mat-mini-fab color="warn" (click)="removeAchievement(i)"
                          [disabled]="achievement.status == 1">
                          <i class="icofont-ui-close"></i>
                        </button>
                      </div>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <br />
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Experiences
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="form-group">
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
              <div fxFlex="90">
                <mat-form-field appearance="outline" class="form-field__extend full-width">
                  <mat-label>Enter experiences</mat-label>
                  <textarea matInput formControlName="experiences"></textarea>
                  <!-- <mat-error *ngIf="f['name'].errors"
                    >Course name is required</mat-error
                  > -->
                </mat-form-field>
              </div>

              <div fxFlex="10" class="mini-fab__container">
                <button mat-mini-fab (click)="addExperience()">
                  <i class="icofont-ui-add"></i>
                </button>
              </div>

              <div fxFlex="100" class="mat-list__container">
                <mat-list>
                  <mat-list-item *ngIf="experiences.length == 0">
                    No experiences set ...
                  </mat-list-item>
                  <mat-list-item *ngFor="let experience of experiences; let i = index" class="mat-list-item__content">
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
                      <div fxFlex="80">
                        {{experience.experience}}
                      </div>
                      <div fxFlex="20" class="centered">
                        <button mat-mini-fab color="warn" (click)="removeExperience(i)"
                          [disabled]="experience.status == 1">
                          <i class="icofont-ui-close"></i>
                        </button>
                      </div>
                    </div>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <br />

      <div class="form-group" *ngIf="teacherObj">
        <button (click)="submitHandler()" mat-raised-button class="full-width">
          Update
        </button>
      </div>
      <div class="form-group" *ngIf="teacherObj == null">
        <button (click)="submitHandler()" mat-raised-button class="full-width">
          Save
        </button>
      </div>
    </form>
  </div>

</div>