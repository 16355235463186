<div class="d-flex">
    <div class="card teacher-card shadow w-100">
        <div class="card-body d-flex align-items-center">
            <!-- <div class="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 pe-4 text-center">
                <img [src]="fileContent.contentFilePath" *ngIf="fileContent.fileType == 'image'" height="315"
                class="img-fluid img-sizing" onerror="this.onerror=null;this.src='assets/images/default-image.png';"/>
                <video width='100%' *ngIf="fileContent.fileType == 'video'" class="vid-sizing" [src]='fileContent.contentFilePath'
                controlsList="nodownload" controls loop muted playsinline onerror="this.onerror=null;this.src='assets/videos/default-video.mp4';"></video>
                <button *ngIf="showDelete" (click)="confirmDeleteFileContent(fileContent)"
                    class="btn bg-danger btn-sm text-white top-0 position-absolute end-0 rounded-0"
                    title="delete-button"><i class="icofont-ui-delete me-2"></i></button>
            </div> -->
            <div class="border-start ps-xl-4 ps-md-2 ps-sm-4 ps-4 pointer text-start w-100">
                <h4 class="header-h4">
                    {{ fileContent.contentName }}
                    <div style="display: flex;
                    justify-content: space-between;
                    width: 60px;">
                        <mat-icon (click)="managePublishAnnouncement(fileContent, !fileContent?.isPublished)"
                        [ngClass]="{
                            'title-color-end': fileContent?.isPublished == 1,
                            'title-color-start': fileContent?.isPublished == 0 || !fileContent?.isPublished
                        }">{{fileContent?.isPublished == 1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <span><mat-icon class="title-color-end" (click)="deleteAnnouncement(fileContent?.contentId)">delete_forever</mat-icon></span>
                    </div>
                </h4>
                <i class="fs-date">{{ convertDate(fileContent.contentDate) }}</i>
                <p>{{ decodeDescription(fileContent.contentDescription) }}</p>

                <div class="mt-3 pt-3 border-top" *ngIf="typeName == 'event'">
                    <i class="fs-date"><b>{{ convertDateTime(fileContent.startDateTime) }}</b> to <b>{{ convertDateTime(fileContent.endDateTime) }}</b></i>
                </div>
            </div>
        </div>
    </div>
</div>