import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { TeacherService } from '../../services/teacher.service';
import { DomSanitizer } from "@angular/platform-browser";
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-teacher-profile',
  templateUrl: './teacher-profile.component.html',
  styleUrls: ['./teacher-profile.component.scss']
})
export class TeacherProfileComponent implements OnInit {

  teacherObj: any;

  achievements: any[] = [];
  experiences: any[] = [];
  public teacherProfileVideo: any = null;
  public sanitizedURL: any = null;
  public teacherProfileImage: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TeacherProfileComponent>,
    public sharedService: SharedService,
    private teacherService: TeacherService,
    private sanitizer: DomSanitizer,
    private storage: AngularFireStorage
  ) {
  }

  ngOnInit(): void {
    this.teacherObj = this.data.teacher;

    this.teacherProfileImage = this.teacherObj.profile_image_path ? this.teacherObj.profile_image_path : "assets/images/default-profile.jpg";

    let basePath = this.teacherObj.video_base_path;
    let videoName = this.teacherObj.video_name;
    const filePath = `${basePath}/${videoName}`;
    const storageRef = this.storage.ref(filePath);
    storageRef.getDownloadURL().subscribe(downloadURL => {
      this.teacherProfileVideo = downloadURL;
      // this.getSanitizedURL();
    }, (error)=>{
      
    });
    this.getAchievementExperiences();
  }

  // getSanitizedURL() {
  //   this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.teacherProfileVideo);
  // }

  getAchievementExperiences() {
    let prepareObj = {
      id: this.teacherObj.id
    }

    this.teacherService.getAchievementExperience(prepareObj).subscribe( (data) => {
      // this.achievements = data.data[0];
      this.experiences = data.data[1];
    })
  }

}
