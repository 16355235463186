import { Component, Inject, OnInit, TemplateRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef,HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseService } from 'src/app/modules/course/services/course.service';
import { SessionService } from 'src/app/modules/session/services/session.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { PackageService } from '../../services/package.service';

import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import "moment-timezone";
import { GlobalService } from '../../../../services/global.service';
import { Router } from '@angular/router';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-package-subjects',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './package-subjects.component.html',
  styleUrls: ['./package-subjects.component.sass'],
  viewProviders: [MatExpansionPanel]
})
export class PackageSubjectsComponent implements OnInit {
  public courses: any[] = [];
  public sessions: any[] = [];
  public package: any = null;

  public isDisabled: boolean = true;
  public isStore: boolean = false;

  public subjectSession: any = {
    course: '',
    session: []
  };

  public subjectSessions: any[] = [];
  public selectedCourseId: any = null;
  public packageSessions: any[] = [];

  public sessionToDay: any[] = [
    { sessions: [] },
    { sessions: [] },
    { sessions: [] },
    { sessions: [] },
    { sessions: [] },
    { sessions: [] },
    { sessions: [] },
    { sessions: [] }
  ];

  public days: any[] = environment.day;
  public view: CalendarView = CalendarView.Week;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();

  public modalData: {
    action: string;
    event: CalendarEvent;
  };

  public actions: CalendarEventAction[] = [];
  public refresh = new Subject<void>();
  public events: any[] = [];
  public eventsList: any[] = [];
  public activeDayIsOpen: boolean = true;
  public timezone: any = null;
  public fileUploads: any = [];

  public isStudyMaterial: any = false;
  public isTimetable: any = false;
  public isInfo: any = false;

  public currentTab: any = 'file';
  public fileUploadFilter: any = [];
  public currentInfoTab: any = 'description';
  public currentFileType: any = 'others';

  public courseData: any[] = [];
  public courseDataGroup: any[] = [];
  public userDetail = JSON.parse(<string>localStorage.getItem('userDetail'));

  public screenWidth = window.innerWidth;

  constructor(
    private packageService: PackageService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PackageSubjectsComponent>,
    private courseService: CourseService,
    private sessionService: SessionService,
    private sharedService: SharedService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private router: Router,
    private globalService: GlobalService
  ) {
    this.package = this.data.package;
    this.isStore = this.data.isStore;
    this.isStudyMaterial = this.data.section && this.data.section == "study-material" ? true : false;
    this.isTimetable = this.data.section && this.data.section == "time-table" ? true : false;
    this.isInfo = this.data.section && this.data.section == "package-info" ? true : false;
    this.timezone = "Asia/Kuala_Lumpur";
  }

  ngOnInit(): void {
    if (this.isStudyMaterial) {
      this.getAllCourseFiles();
    }
    this.getAllCourse();
    this.getPackageSession();

    if (this.isInfo) {
      this.getPackageData();
    }
    
    if (window.innerWidth <= 480) {
      this.view = CalendarView.Month;
      this.activeDayIsOpen = false;
    }
  }

  getDay(day: any) {
    return environment.day[day];
  }

  convertTime(time: any) {
    return moment.utc(time, 'HH:mm:ss').tz(this.timezone).format('hh:mm A');
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }

  async getAllCourse() {
    const userDetail = <String>localStorage.getItem("userDetail");
    const requestedBy = userDetail ? JSON.parse(userDetail.toString()) : null;

    const requestObj = {
      isPublished: null,
      schoolId: requestedBy?.schoolId
    }

    await this.courseService.getAllCourse(requestObj).subscribe((data) => {
      this.courses = data.data;
    })
  }

  getCourseSession(course_id: any) {
    this.sessionService.getAllSession({ course_id: course_id }).subscribe((data) => {
      this.sessions = data.data;

      for (let counter = 0; counter < this.sessions.length; counter++) {
        let session = this.sessions[counter];

        session.start_time = moment.utc(session.start_time, 'HH:mm:ss').tz(this.timezone).format('hh:mm A');
        session.end_time = moment.utc(session.end_time, 'HH:mm:ss').tz(this.timezone).format('hh:mm A');
        session.isSelected = false;

        this.sessions[counter] = session;
      }
      this.ref.detectChanges();
    })
  }

  getPackageSession() {
    this.sessionToDay = [
      { sessions: [] },
      { sessions: [] },
      { sessions: [] },
      { sessions: [] },
      { sessions: [] },
      { sessions: [] },
      { sessions: [] },
      { sessions: [] }
    ];
    this.packageSessions = [];

    this.sessionService.getAllSession({ package_id: this.data.package.id }).subscribe((data) => {
      this.packageSessions = data.data;

      this.events = [];

      this.packageSessions.forEach(session => {
        let startDate = moment.utc(this.data.package.start_date).tz(this.timezone).format('YYYY-MM-DD');
        let dueDate = moment.utc(this.data.package.due_date).tz(this.timezone).format('YYYY-MM-DD');
        let startTime = moment.utc(`${startDate} ${session.start_time}`).tz(this.timezone).format('HH:mm:ss');
        let endTime = moment.utc(`${startDate} ${session.end_time}`).tz(this.timezone).format('HH:mm:ss');
        let reformatStartTime = moment.utc(`${startDate} ${startTime}`).format('hh:mm A');
        let reformatEndTime = moment.utc(`${dueDate} ${endTime}`).format('hh:mm A');
        let day = session.day;

        // let transactionDate = this.data.package.transactionDate ? moment.utc(this.data.package.transactionDate).tz(this.timezone).format('YYYY-MM-DD') : null;

        let dateTimes = this.getDaysBetweenDates(startDate, dueDate, startTime, endTime, day);

        for (let j = 0; j < dateTimes.length; j++) {
          let dates = dateTimes[j];
          let message = this.setClassMessages(dates.start, dates.end, session.meeting_number);
          if (this.package.isSubscribed == true) {
            this.events.push({
              start: dates.start,
              end: dates.end,
              title: `
              <span>
                ${message} ${session.course_name}
                </span>
                <br/>
                <span class="title-schedule">
                  <span class="title-color-start">${reformatStartTime}</span> - 
                  <span class="title-color-end">${reformatEndTime}</span>
                </span>
              `,
              title_tooltip: `${session.course_name}`,
              color: colors.blue,
              allDay: false,
              isSubscribed: true,
              classUrl: session.url,
              meetingNumber: session.meeting_number,
              meetingPassword: session.meeting_password,
              message: message
            });
          } else {
            this.events.push({
              start: dates.start,
              end: dates.end,
              title: `
              <span>
                ${session.course_name}
                </span>
                <br/>
                <span class="title-schedule">
                  <span class="title-color-start">${reformatStartTime}</span> - 
                  <span class="title-color-end">${reformatEndTime}</span>
                </span>
              `,
              title_tooltip: `${session.course_name}`,
              color: colors.blue,
              allDay: false,
              isSubscribed: false
            });
          }

        }
        this.refresh.next();
      });
      this.packageSessions.forEach(session => {
        this.sessionToDay[session.day].sessions = [...this.sessionToDay[session.day].sessions, session];
      });

      this.ref.detectChanges();
    })
  }

  courseChangeHandler() {
    this.sessions = [];
    this.getCourseSession(parseInt(this.selectedCourseId));
  }

  selectedSessionHandler(sessionIndex: any) {
    let selectedCount = 0;

    this.sessions[sessionIndex].isSelected = !this.sessions[sessionIndex].isSelected;

    this.sessions.forEach(session => {
      if (session.isSelected) {
        selectedCount++;
      }
    });

    this.isDisabled = selectedCount > 0 ? false : true;
  }

  async setPackageSession() {
    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

    await this.sessions.forEach(async session => {
      if (session.isSelected) {
        let prepareObj = {
          id: null,
          session_id: session.id,
          package_id: this.data.package.id,
          operation: 'ADD',
          requested_by: requested_by.id
        }

        await this.sessionService.setPackageSession(prepareObj).subscribe((data) => {
        })
      }
    });
    this.sharedService.showSnackBar("Session set completed");
    this.getPackageSession();
  }

  deleteConfirm(session: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: "Confirm delete the selected session?"
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      let userDetail = <String>localStorage.getItem("userDetail");
      let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

      if (result.data && result.data.confirm) {
        let prepareObj = {
          id: session.package_session_id,
          package_id: null,
          session_id: null,
          operation: 'DELETE',
          requested_by: requested_by.id
        }

        this.sessionService.setPackageSession(prepareObj).subscribe((data) => {
          this.sharedService.showSnackBar("Session removed");
          this.getPackageSession();
        })
      }
    });
  }

  viewCourse(sessionObj: any) {

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      if (this.screenWidth <= 480) {
        this.activeDayIsOpen = false;
        
        this.eventsList = this.events.filter( (el) => {
          let startDateTemp = moment(el.start).toDate();
          return startDateTemp.getDate() == date.getDate() && startDateTemp.getFullYear() == date.getFullYear() &&startDateTemp.getMonth() == date.getMonth();
        });
      }

      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    this.modalData = { event, action };

    let actualStartTime = null;
    let minutesEarlyStart = 0;

    if (this.userDetail.usertype_name == "TEACHER") {
      minutesEarlyStart = 15;
    } else {
      minutesEarlyStart = 10;
    }

    if (event.isSubscribed) {

      actualStartTime = moment(event.start).subtract(minutesEarlyStart, 'minutes');
      let actualEndTime = moment(event.end);
      const validateSameCurrentDate = moment().isSame(actualStartTime, 'date');
      const validateAfterCurrentDate = moment().isAfter(actualStartTime, 'date');
      const validateBeforeCurrentDate = moment().isBefore(actualStartTime, 'date');
      const validateCurrentStartDateTime = moment().isSameOrAfter(actualStartTime);
      const validateCurrentEndDateTime = moment().isSameOrBefore(actualEndTime);

      if (validateSameCurrentDate) {
        if (
          validateCurrentStartDateTime &&
          validateCurrentEndDateTime
        ) {
          if (event.meetingNumber) {
            this.confirmJoinClass(event);
          } else {
            this.sharedService.showSnackBar('Class not open yet');
          }
        } else if( validateCurrentStartDateTime == false ) {
          this.sharedService.showSnackBar(`Class will open ${minutesEarlyStart} minutes before started`);
        } else {
          this.sharedService.showSnackBar(`Class ended. You may wait for next class.`);
        }
      } else if (validateBeforeCurrentDate) {
        this.sharedService.showSnackBar(`Class will open ${minutesEarlyStart} minutes before started`);
      } else if (validateAfterCurrentDate) {
        this.sharedService.showSnackBar(`Class ended. You may wait for next class.`);
      }
    } else {
      this.sharedService.showSnackBar('Please subscribe to join the class');
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getDaysBetweenDates(
    startDate: any = null,
    endDate: any,
    startTime: any,
    endTime: any,
    day: any
  ) {

    let now = startDate ? moment(startDate) : moment();
    let end = now.clone().add(this.data.package.duration - 1, 'days');
    let dates = [];
    let current = now.clone().subtract(7, 'days');
    // let startDateDay = current.day();
    // let endDateDay = end.day();
    let actualDay = 6 + day;

    while (current.day(actualDay).isSameOrBefore(end)) {
      let reformatDate = current.clone().format('YYYY-MM-DD');

      if (now.isSameOrBefore(current)) {
        dates.push({
          start: new Date(moment(`${reformatDate} ${startTime}`).toISOString()),
          end: new Date(moment(`${reformatDate} ${endTime}`).toISOString())
        });
      }
    }
    return dates;
  };

  getAllCourseFiles() {

    if (
      this.data &&
      this.data.package &&
      this.data.package.id
    ) {
      let requestObject = {
        userId: this.userDetail.id
      }

      this.courseService.getAllRechargeFileOrById(requestObject).subscribe((result) => {
        this.fileUploads = result.data ? result.data : [];
        this.filterFileUploads();
        this.ref.detectChanges();
      }, (error) => {

      });
    } else {
      this.fileUploads = [];
    }
  }

  changeTab(tab: any = 'file') {
    this.currentTab = tab;
    this.fileUploadFilter = [];
    this.filterFileUploads();
    this.ref.detectChanges();
  }

  filterFileUploads() {
    if (this.currentTab == 'file') {
      for (let i = 0; i < this.fileUploads.length; i++) {
        let splitArray = this.fileUploads[i].fileMime.split('/');
        if (splitArray[0] == "application") {
          if (
            splitArray[1] == 'msword' ||
            splitArray[1] == 'vnd.openxmlformats-officedocument.wordprocessingml.document'
          ) {
            this.currentFileType = 'word';
          } else if (splitArray[1] == 'pdf') {
            this.currentFileType = 'pdf';
          } else if (
            splitArray[1] == 'vnd.ms-powerpoint' ||
            splitArray[1] == 'vnd.openxmlformats-officedocument.presentationml.presentation'
          ) {
            this.currentFileType = 'powerpoint';
          } else if (
            splitArray[1] == 'vnd.ms-excel' ||
            splitArray[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ) {
            this.currentFileType = 'excel';
          }
          this.fileUploads[i]['fileType'] = this.currentFileType;
          this.fileUploadFilter.push(this.fileUploads[i]);
        }

      }
    } else if (this.currentTab == 'video') {
      for (let i = 0; i < this.fileUploads.length; i++) {
        let splitArray = this.fileUploads[i].fileMime.split('/');
        if (splitArray[0] == "video") {
          this.fileUploads[i]['fileType'] = 'video';
          this.fileUploadFilter.push(this.fileUploads[i]);
        }
      }
    } else if (this.currentTab == 'image') {
      for (let i = 0; i < this.fileUploads.length; i++) {
        let splitArray = this.fileUploads[i].fileMime.split('/');
        if (splitArray[0] == "image") {
          this.fileUploads[i]['fileType'] = 'image';
          this.fileUploadFilter.push(this.fileUploads[i]);
        }
      }
    }
  }

  changeInfoTab(tab: any = 'description') {
    this.currentInfoTab = tab;
    this.ref.detectChanges();
  }

  getPackageData() {
    let packageObj = {
      packageId: this.data.package.id
    }

    this.courseData = [];
    this.courseDataGroup = [];

    this.packageService.getPackageCourse(packageObj).subscribe((response) => {
      if (response.data[0]) {
        const packageAdditionalData = response.data[0];

        this.courseData = packageAdditionalData;

        for (let i = 0; i < this.courseData.length; i++) {

          let validateExistCourseIndex = null;

          if (this.courseDataGroup.length > 0) {
            for (let j = 0; j < this.courseDataGroup.length; j++) {
              if (this.courseDataGroup[j].courseId == this.courseData[i].courseId) {
                validateExistCourseIndex = j;
              }
            }
          }

          if (validateExistCourseIndex) {
            this.courseDataGroup[validateExistCourseIndex].teachers.push({
              name: this.courseData[i].teacherName,
              profileImage: this.courseData[i].teacherProfileImagePath,
              subject: this.courseData[i].teacherSubject,
              totalExperience: this.courseData[i].teacherTotalExperience,
              qualification: this.courseData[i].teacherQualification
            });
          } else {
            this.courseDataGroup.push({
              courseId: this.courseData[i].courseId,
              courseName: this.courseData[i].courseName,
              courseDescription: this.courseData[i].courseDescription,
              totalSession: this.courseData[i].totalSession,
              teachers: [
                {
                  name: this.courseData[i].teacherName,
                  profileImage: this.courseData[i].teacherProfileImagePath,
                  subject: this.courseData[i].teacherSubject,
                  totalExperience: this.courseData[i].teacherTotalExperience,
                  qualification: this.courseData[i].teacherQualification
                }
              ]
            });
          }
        }
      }
    }, (error) => {

    })
  }

  decodeDescription(description: any) {
    return decodeURI(description);
  }

  convertDate(date: any) {
    return moment.utc(date, 'YYYY-MM-DD').tz(this.timezone).format('DD MMMM YYYY');
  }

  confirmJoinClass(classData: any): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: `You are about to join <p><b>${classData.title}<b><p>`,
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (
        result &&
        result.data &&
        result.data.confirm == true
      ) {
        const meetingNumber = classData.meetingNumber;
        const meetingPassword = classData.meetingPassword;

        this.goToZoomMeeting(meetingNumber, meetingPassword);
      }
    });
  }

  goToZoomMeeting(
    meetingNumber: any,
    password: any
  ) {
    const leaveUrl = this.router.url;
    this.globalService.meetingNumber = meetingNumber;
    this.globalService.password = password;
    this.globalService.leaveUrl = leaveUrl;

    this.close(false);
    this.router.navigate(['/class/class-meeting']);
  }

  setClassMessages(startDate: any, dueDate: any, meetingNumber: any) {
		let actualStartTime = null;
		let minutesEarlyStart = 0;

		if (this.userDetail.usertype_name == "TEACHER") {
			minutesEarlyStart = 15;
		} else {
			minutesEarlyStart = 10;
		}

		actualStartTime = moment(startDate).subtract(minutesEarlyStart, 'minutes');
		let actualEndTime = moment(dueDate);
		const validateSameCurrentDate = moment().isSame(actualStartTime, 'date');
		const validateAfterCurrentDate = moment().isAfter(actualStartTime, 'date');
		const validateBeforeCurrentDate = moment().isBefore(actualStartTime, 'date');
		const validateCurrentStartDateTime = moment().isSameOrAfter(actualStartTime);
		const validateCurrentEndDateTime = moment().isSameOrBefore(actualEndTime);

		if (validateSameCurrentDate) {
			if (
				validateCurrentStartDateTime &&
				validateCurrentEndDateTime
			) {
				if (meetingNumber) {
					return "<span class='class-open-schedule'>Open <i class='fa fa-chevron-right'></i></span>";
				} else {
					return "<span class='class-preparing-schedule'>Preparing</span>";
				}
			} else if (validateCurrentStartDateTime == false) {
				return "<span class='class-upcoming-schedule'>Upcoming</span>";
			} else {
				return "<span class='class-ended-schedule'>Ended</span>";
			}
		} else if (validateBeforeCurrentDate) {
			return "<span class='class-upcoming-schedule'>Upcoming</span>";
		} else if (validateAfterCurrentDate) {
			return "<span class='class-ended-schedule'>Ended</span>";
		};
	}

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth
  }
}

