import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CourseService } from '../../course/services/course.service';
import { SharedService } from '../../shared/services/shared.service';
import { FileUpload } from '../models/file-upload.model';
import { TeacherService } from '../../teacher/services/teacher.service';
import { UserService } from '../../user/services/user.service';
import { PackageService } from '../../package/services/package.service';
import { ContentService } from '../../content/services/content.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { DependentService } from '../../dependent/services/dependent.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private basePath = '/uploads';
  public subscriptionRefreshCourseFile: any = new Subject();
  public subscriptionCloseModal: any = new Subject();

  public subscriptionRefreshContent: any = new Subject();

  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private courseService: CourseService,
    private sharedService: SharedService,
    private teacherService: TeacherService,
    private userService: UserService,
    private packageService: PackageService,
    private contentService: ContentService,
    public router: Router,
    public globalService: GlobalService,
    private dependentService: DependentService
  ) { }

  setSubscriptionRefreshCourseFile(
    packageId: any,
    courseId: any
  ) {
    this.subscriptionRefreshCourseFile.next({
      package_id: packageId,
      course_id: courseId
    });
  }

  getSubscriptionRefreshCourseFile() {
    return this.subscriptionRefreshCourseFile;
  }

  setSubscriptionRefreshContent(refresh: boolean) {
    this.subscriptionRefreshCourseFile.next(refresh);
  }

  getSubscriptionRefreshContent() {
    return this.subscriptionRefreshCourseFile;
  }

  pushFileToStorage(
    fileUpload: FileUpload,
    basePath: any,
    packageId?: any,
    courseId?: any,
    payloadObject?: any,
    achievements?: any,
    experiences?: any,
    fromLogin?: any,
    loading: boolean = false
  ): Observable<number> {
    const filePath = `${basePath}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask: any = this.storage.upload(filePath, fileUpload.file);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          if (basePath == "recharge-file") {
            this.saveRechargeFileDataSQL(fileUpload, basePath, packageId, courseId);
          } else if (basePath == "teacher-profile") {
            let fileUploadData = fileUpload.file;
            payloadObject.video_name = fileUploadData.name;
            payloadObject.video_path = fileUpload.url;
            payloadObject.video_mime = fileUploadData.type;
            payloadObject.video_size = fileUploadData.size;
            this.saveUpdateTeacherData(fileUpload, basePath, payloadObject, achievements, experiences);
          } else if (basePath == "profile-image") {
            let fileUploadData = fileUpload.file;
            payloadObject.profile_image_name = fileUploadData.name;
            payloadObject.profile_image_path = fileUpload.url;
            payloadObject.profile_image_mime = fileUploadData.type;
            payloadObject.profile_image_size = fileUploadData.size;
            this.registerUser(fileUpload, basePath, payloadObject, fromLogin);
          } else if (basePath == "menu-image") {
            let fileUploadData = fileUpload.file;
            payloadObject.package_image_name = fileUploadData.name;
            payloadObject.package_image_path = fileUpload.url;
            payloadObject.package_image_mime = fileUploadData.type;
            payloadObject.package_image_size = fileUploadData.size;
            this.managePackage(fileUpload, basePath, payloadObject);
          } else if (basePath == "content-info") {
            let fileUploadData = fileUpload.file;
            payloadObject.contentFileName = fileUploadData.name;
            payloadObject.contentFilePath = fileUpload.url;
            payloadObject.contentFileMime = fileUploadData.type;
            payloadObject.contentFileSize = fileUploadData.size;
            this.manageContent(fileUpload, basePath, payloadObject, loading);
          } else if (basePath == "dependent-image") {
            let fileUploadData = fileUpload.file;
            payloadObject.profile_image_name = fileUploadData.name;
            payloadObject.profile_image_path = fileUpload.url;
            payloadObject.profile_image_mime = fileUploadData.type;
            payloadObject.profile_image_size = fileUploadData.size;
            this.registerDependent(fileUpload, basePath, payloadObject);
          }
        });
      })
    ).subscribe();
    return uploadTask.percentageChanges();
  }
  private saveFileData(fileUpload: FileUpload, basePath: any): void {
    this.db.list(basePath).push(fileUpload);
  }

  public saveUpdateTeacherData(
    fileUpload: any,
    basePath: any,
    payloadObject: any,
    achievements?: any,
    experiences?: any
  ) {
    let fileUploadData = fileUpload && fileUpload.file ? fileUpload.file : null;
    this.teacherService.saveTeacher(payloadObject).subscribe((data) => {
      let teacher_id = data.data[0].id;

      let achievementResults: any[] = [];
      let experienceResults: any[] = [];

      achievements.forEach(async (achievement: any) => {
        let prepareAchievementObj = {
          id: achievement.id,
          achievement: achievement.achievement,
          teacher_id: teacher_id,
          status: achievement.status,
          requested_by: this.sharedService.getCurrentUser().id
        }

        await this.teacherService.saveAchievement(prepareAchievementObj).subscribe((dataAchievement) => {
          achievementResults.push(dataAchievement);
        });
      });

      experiences.forEach(async (experience: any) => {
        let prepareExperienceObj = {
          id: experience.id,
          experience: experience.experience,
          teacher_id: teacher_id,
          status: experience.status,
          requested_by: this.sharedService.getCurrentUser().id
        }

        await this.teacherService.saveExperience(prepareExperienceObj).subscribe((dataExperience) => {
          experienceResults.push(dataExperience);
        });
      });
      let msg = 'Successfully update teacher';
      this.sharedService.showSnackBar(msg);
      this.setSubscriptionCloseModal(true);
    }, (error) => {
      if (fileUploadData) {
        this.deleteFileStorage(fileUploadData.name, basePath);
      }
      this.setSubscriptionCloseModal(true);
    });
  }

  public registerUser(
    fileUpload: any,
    basePath: any,
    payloadObject: any,
    fromLogin?: any
  ) {
    let fileUploadData = fileUpload && fileUpload.file ? fileUpload.file : null;

    this.userService.saveUser(payloadObject).subscribe((response: any) => {
      let msg = "";
      this.globalService.isLoading = false;
      if (
        response &&
        response.data &&
        response.data != null &&
        response.status &&
        response.status.code &&
        response.status.code == 200
      ) {
        msg = fromLogin ? 'Your account created successfully'
          : (payloadObject.user_id ? 'User detail updated successfully' : 'User registered successfully');

        if (fromLogin) {
          this.router.navigate(['auth/sign-in']);
        }

        if (!fromLogin) {
          this.setSubscriptionCloseModal(true);
        }
      } else {
        msg = response.message;
      }
      this.sharedService.showSnackBar(msg);

    }, (error: any) => {
      this.globalService.isLoading = false;
      if (fileUploadData) {
        this.deleteFileStorage(fileUploadData.name, basePath);
      }
      if (!fromLogin) {
        this.setSubscriptionCloseModal(true);
      }

    });
  }

  public managePackage(
    fileUpload: any,
    basePath: any,
    payloadObject: any,
  ) {
    let fileUploadData = fileUpload && fileUpload.file ? fileUpload.file : null;

    this.packageService.saveMenu(payloadObject).subscribe((data: any) => {
      this.sharedService.showSnackBar(data.message);
      this.setSubscriptionCloseModal(true);
    }, (error: any) => {
      if (fileUploadData) {
        this.deleteFileStorage(fileUploadData.name, basePath);
      }
      this.setSubscriptionCloseModal(true);
    });
  }

  public manageContent(
    fileUpload: any,
    basePath: any,
    payloadObject: any,
    loading: boolean = false
  ) {
    let fileUploadData = fileUpload && fileUpload.file ? fileUpload.file : null;

    this.contentService.manageContent(payloadObject).subscribe((data: any) => {
      this.sharedService.showSnackBar(data.message);
      this.setSubscriptionCloseModal(true);
      loading = false;
    }, (error: any) => {
      if (fileUploadData) {
        this.deleteFileStorage(fileUploadData.name, basePath);
      }
      loading = false;
      this.setSubscriptionCloseModal(true);
    });
  }

  private saveRechargeFileDataSQL(
    fileUpload: any,
    basePath: any,
    receiptNumber: any,
    rechargeAmount: any
  ): void {
    let userDetail = <String>localStorage.getItem("userDetail");
    let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;
    let fileUploadData = fileUpload.file;
    let prepareObj = {
      name: fileUploadData.name,
      size: fileUploadData.size,
      base_path: basePath,
      path: fileUpload.url,
      mime: fileUploadData.type,
      receiptNumber: receiptNumber,
      rechargeAmount: rechargeAmount,
      requested_id: requested_by.id,
      id: null
    }

    this.courseService.saveRechargeFile(prepareObj).subscribe((data) => {
      this.sharedService.showSnackBar(data.message);
      this.setSubscriptionRefreshCourseFile(receiptNumber, rechargeAmount);
    }, (error) => {
      this.deleteFileStorage(fileUploadData.name, basePath);
      this.sharedService.showSnackBar('Fail to save file');
    });
  }
  getFiles(numberItems: number, basePath: any): AngularFireList<FileUpload> {
    return this.db.list(basePath, ref =>
      ref.limitToLast(numberItems));
  }
  deleteFile(fileUpload: FileUpload, basePath: any): void {
    this.deleteFileDatabase(fileUpload.key, basePath)
      .then(() => {
        this.deleteFileStorage(fileUpload.name, basePath);
      })
      .catch(error => console.log(error));
  }
  deleteFileSQL(fileName: any, courseFileId: any, basePath: any, packageId?: any, courseId?: any): void {

    let prepareObj = {
      id: courseFileId
    }

    if (basePath == "recharge-file") {
      this.courseService.removeCourseFile(prepareObj).subscribe((data) => {
        this.sharedService.showSnackBar(data.message);
        this.deleteFileStorage(fileName, basePath);
        this.setSubscriptionRefreshCourseFile(packageId, courseId);
      }, (error) => {
        this.sharedService.showSnackBar('Fail to remove file');
      });
    } else if (basePath == "content-info") {
      this.contentService.removeContent(prepareObj).subscribe((data) => {
        this.sharedService.showSnackBar(data.message);
        if( fileName ){
          this.deleteFileStorage(fileName, basePath);
        }
        this.setSubscriptionRefreshContent(true);
      }, (error) => {
        this.sharedService.showSnackBar('Fail to remove file');
      });
    }
  }
  private deleteFileDatabase(key: string, basePath: any): Promise<void> {
    return this.db.list(basePath).remove(key);
  }
  public deleteFileStorage(name: string, basePath: any): void {
    const storageRef = this.storage.ref(basePath);
    storageRef.child(name).delete();
  }

  setSubscriptionCloseModal(value: boolean) {
    this.subscriptionCloseModal.next(value);
  }

  getSubscriptionCloseModal() {
    return this.subscriptionCloseModal;
  }

  public registerDependent(
    fileUpload: any,
    basePath: any,
    payloadObject: any
  ) {
    let fileUploadData = fileUpload && fileUpload.file ? fileUpload.file : null;

    this.dependentService.saveDependent(payloadObject).subscribe(async (response) => {
      let msg = "";
      this.globalService.isLoading = false;
      if (
        response &&
        response.data &&
        response.data != null &&
        response.status &&
        response.status.code &&
        response.status.code == 200
      ) {
        msg = response.message;
        this.setSubscriptionCloseModal(true);
      } else {
        msg = response.message;
      }
      this.sharedService.showSnackBar(msg);

    }, (error: any) => {
      this.globalService.isLoading = false;
      if (fileUploadData) {
        this.deleteFileStorage(fileUploadData.name, basePath);
      }
      this.setSubscriptionCloseModal(true);

    });
  }
}