
<div class="table-transaction">
  <div class="main-filter">
    <div class="sub-filter-1">
      <div class="label-input">
        <label>Start Date</label>
        <input [(ngModel)]="filterStartDate" (change)="resetFilterEndDate()" type="date">
      </div>
      <div class="label-input"> 
        <label>End Date</label>
        <input [(ngModel)]="filterEndDate" (change)="filterTable()" type="date">
      </div>
      <div class="label-input" *ngIf="(currentUserRole == 'ADMIN' && currentSchoolCode == 'ALL') || currentUserRole == 'SUPER ADMIN'">
        <label>School</label>
        <select [(ngModel)]="school" aria-placeholder="Choose School" class="form-select pointer custom-select">
          <option value="">All</option>
          <option *ngFor="let school of schools" [value]="school['school_id']">
            {{ school['school_name'] }}
          </option>
        </select>
      </div>
      <div style="display: flex;
        align-items: end;">
          <button type="submit" (click)="filterTable()" class="filter"><i class="icofont-filter file-fixed-size excel"></i>Filter</button>
      </div>
      <div style="display: flex;
        align-items: end;margin-top: 10px;">
          <button (click)="exportTable()" *ngIf="currentUserRole != 'PARENTS' && currentUserRole != 'TEACHER'"><i class="icofont-file-excel file-fixed-size excel"></i> Export to Excel</button>
      </div>
      <div style="display: flex;
        align-items: end;margin-top: 10px;">
          <button class='pdf' (click)="exportInvoice()" *ngIf="currentUserRole != 'PARENTS' && currentUserRole != 'TEACHER'"><i class="icofont-file-pdf file-fixed-size"></i> Export Invoice</button>
      </div>
    </div>
    <!-- <div class="sub-filter-2">
      <input [(ngModel)]="filterStartDate" (change)="resetFilterEndDate()" type="date">
      <input [(ngModel)]="filterEndDate" (change)="filterTable()" type="date">
    </div> -->
  </div>
  <table mat-table matTableExporter [dataSource]="transactionsList" class="mat-elevation-z8 table" matSort #exporter="matTableExporter">
  
    <ng-container matColumnDef="transactionReferenceId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Reference No.</th>
      <td mat-cell *matCellDef="let element">{{ element.transactionReferenceId }}</td>
    </ng-container>
  
    <ng-container matColumnDef="menuName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Menu</th>
      <td mat-cell *matCellDef="let element">
        <span class="inline-block menu">{{ element.packageName }}</span>
        <br/>
        <span class="inline-block calorie">
          <img width="20" height="20" [src]="'./assets/images/calorie.png'" /> {{ element?.calorie ? element?.calorie : 0 }} kcal
        </span>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="paidAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Price (RM)</th>
      <td mat-cell *matCellDef="let element">{{ setPriceDecimal(element.paidAmount) }}</td>
    </ng-container>
  
    <ng-container matColumnDef="sellerName" *ngIf="currentUserRole != 'PARENTS' && currentUserRole != 'TEACHER'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Seller Name</th>
      <td mat-cell *matCellDef="let element">{{ element.sellerFirstName }} {{ element.sellerLastName }}</td>
    </ng-container>

    <ng-container matColumnDef="studentName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Student Name</th>
      <td mat-cell *matCellDef="let element">{{ element.studentName }}</td>
    </ng-container>
  
    <ng-container matColumnDef="sellTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor">Sold On</th>
      <td mat-cell *matCellDef="let element">{{ convertDateTime(element.sellTime) }}</td>
    </ng-container>
  
    <!-- <ng-container matColumnDef="action" *ngIf="currentUserRole != 'PARENTS' && currentUserRole != 'TEACHER'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [style.background-color]="headerColor"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="warn" (click)="renderInvoice(element)"><i class="icofont-download"></i></button>
      </td>
    </ng-container> -->
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div id="pdf"></div>
