<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div>
          <!-- <div class="img-container">
            <img src="assets/images/banner-2.jpg" alt="Snow" style="width: 100%" />
            <div class="text-centered">
              <span class="title-font dark">Packages</span>
            </div>
          </div> -->
          <div>
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <!-- <div fxFlex="20">
                <package-filter></package-filter>
              </div> -->
              <!-- <div fxFlex="100">
                <package-list [isStore]="true" [packages]="packages" (onAddToCart)="addToCartHandler($event)"></package-list>
              </div> -->
              <div fxFlex="100">
                <mat-paginator (page)="handlePageEvent($event)" [length]="totalResult" [pageSize]="limit"
                  [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
                  aria-label="Select page" class="web-view" style="position: absolute;
                  top: 100px;
                  right: 0;
                  z-index: 99;">
                </mat-paginator>
                <mat-tab-group mat-align-tabs="center" #tabs (selectedTabChange)="resetPackages($event)">
                  <mat-tab label="My Packages">
                    <package-list *ngIf="packagesSubscribed && packagesSubscribed.length > 0" [isStore]="true"
                      [isSubscribed]="true" [packages]="packagesSubscribed"></package-list>
                    <div *ngIf="packagesSubscribed && packagesSubscribed.length == 0">
                      <div role="alert" class="alert alert-primary full-width"><i
                          class="icofont-info-circle"></i>&nbsp;&nbsp;You may subscribed to <b>Available Packages</b>.
                      </div>
                    </div>
                    <mat-paginator (page)="handlePageEvent($event)" [length]="totalResult" [pageSize]="limit"
                      [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
                      aria-label="Select page" class="mobile-view" style="">
                    </mat-paginator>
                  </mat-tab>
                  <mat-tab label="Available Packages">
                    <package-list *ngIf="packages && packages.length > 0" [isStore]="true" [isSubscribed]="false"
                      [packages]="packages" (onAddToCart)="addToCartHandler($event)"
                      [overlapScheduleObj]="overlapScheduleObj"></package-list>
                    <div *ngIf="packages && packages.length == 0">
                      <div role="alert" class="alert alert-primary full-width"><i
                          class="icofont-info-circle"></i>&nbsp;&nbsp;You have subscribed to all packages.</div>
                    </div>
                  <mat-paginator (page)="handlePageEvent($event)" [length]="totalResult" [pageSize]="limit"
                    [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
                    aria-label="Select page" class="mobile-view" style="">
                  </mat-paginator>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
        <button mat-fab type="button" matBadgeColor="warn" (click)="openPayNow()"
          class="btn btn-primary floating-fab_pending blink-pay-now">
          <i class="icofont-pay fs-2"></i>
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {{ getTotalPendingTransation() }}
          </span>
        </button>
        <button mat-fab type="button" matBadgeColor="warn" (click)="viewCart()"
          class="btn btn-primary floating-fab_cart">
          <i class="icofont-cart-alt fs-2"></i>
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {{ cart.length }}
          </span>
        </button>
      </router-outlet>
    </div>
  </div>
</body>