import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { UserModule } from './modules/user/user.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModule } from './modules/shared/shared.module';
import { CourseModule } from './modules/course/course.module';
import { PackageModule } from './modules/package/package.module';
import { CartModule } from './modules/cart/cart.module';
import { ScheduleModule } from './modules/schedule/schedule.module';
import { SessionModule } from './modules/session/session.module';
import { DependentModule } from './modules/dependent/dependent.module';
import { TeacherModule } from './modules/teacher/teacher.module';
import { TransactionModule } from './modules/transaction/transaction.module'; 
import { CodeInputModule } from 'angular-code-input';
import { NgChartsModule } from 'ng2-charts';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from '../environments/environment';
import { FileUploadModule } from './modules/file-upload/file-upload.module';
import { ContentModule } from './modules/content/content.module';
import { BalanceReportModule } from './modules/balance-report/balance-report.module'; 
import { 
  AuthGuardService
} from './auth/auth-guard.service';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RequestInterceptorInterceptor } from './interceptor/request-interceptor.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AppRoutingModule,
    UserModule,
    CourseModule,
    PackageModule,
    CartModule,
    ScheduleModule,
    SessionModule,
    DependentModule,
    TeacherModule,
    FileUploadModule,
    TransactionModule,
    ContentModule,
    BalanceReportModule,
    
    SharedModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    CodeInputModule,
    NgChartsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgApexchartsModule
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
