<div class="header">
    <nav class="navbar py-2">
        <div class="container-xxl">
            <!-- <div *ngIf="showHomeButton" class="home-btn">
                <a class="m-link" [routerLink]="['/pages/dashboard']"><i
                    class="icofont-ui-home fs-4"></i>&nbsp;&nbsp;Home</a>
            </div> -->
            <div *ngIf="!hideFilterButton" class="h-right d-flex justify-content-end align-items-center mr-5 mr-lg-0 order-1 custom-header">
                <!-- <div class="filter__div pointer" (click)="showFilter(filterModal)">
                    <i class="icofont-filter fs-4 me-3 filter__icon"></i>
                    <span>Filter</span>
                </div> -->
            </div>

            <div class="h-right d-flex justify-content-end align-items-center mr-5 mr-lg-0 order-1 label-header-main">
                <div class="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center">
                    <div class="u-info me-2">
                        <p class="mb-0 text-end line-height-sm " *ngIf="dependentDetail == null"><span
                                class="font-weight-bold">{{ processText(userDetail.name_first, userDetail.name_last) }}</span>
                        </p>
                        <p class="mb-0 text-end line-height-sm " *ngIf="dependentDetail != null"><span
                                class="font-weight-bold">{{ dependentDetail.name }}</span></p>
                        <small class="pill-header" *ngIf="dependentDetail == null">{{ removeLastCharacter(userDetail.usertype_name) }}</small>
                        <small class="pill-header" *ngIf="dependentDetail != null">{{ dependentDetail.usertype_name }}</small>
                    </div>
                    <a class="nav-link dropdown-toggle pulse p-0" (click)="toggleDropdown()" href="javascript:void(0)"
                        role="button" data-bs-toggle="dropdown" data-bs-display="static">
                        <img *ngIf="userDetail.profile_image_path" class="avatar lg rounded-circle img-thumbnail shadow"
                            [src]="userDetail.profile_image_path" alt="profile">
                        <img *ngIf="!userDetail.profile_image_path" class="avatar lg rounded-circle img-thumbnail shadow"
                            src="assets/images/default-profile.jpg" alt="profile">
                    </a>
                    <div class="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0"
                        [ngClass]="isOpen ? 'show' : '' " [attr.data-bs-popper]="isOpen ? 'none' : ''">
                        <div class="card border-0 w280">
                            <div class="card-body pb-0">
                                <div class="d-flex py-1">
                                    <img *ngIf="userDetail.profile_image_path" class="avatar rounded-circle shadow"
                                        [src]="userDetail.profile_image_path" alt="profile">
                                    <img *ngIf="!userDetail.profile_image_path" class="avatar rounded-circle shadow"
                                        src="assets/images/default-profile.jpg" alt="profile">
                                    <div class="flex-fill ms-3">
                                        <p class="mb-0" *ngIf="dependentDetail == null"><span
                                                class="font-weight-bold">{{ userDetail.name_first }} {{
                                                userDetail.name_last }}</span>
                                        </p>
                                        <p class="mb-0" *ngIf="dependentDetail != null"><span
                                                class="font-weight-bold">{{ dependentDetail.name }}</span></p>
                                        <small class="">{{ userDetail.email }}</small>
                                        <br/>
                                        <small class="pill-header" *ngIf="dependentDetail == null">{{ removeLastCharacter(userDetail.usertype_name) }}</small>
                                        <small class="pill-header" *ngIf="dependentDetail != null">{{ dependentDetail.usertype_name
                                            }}</small>
                                    </div>
                                </div>
                                <div>
                                    <hr class="dropdown-divider border-dark">
                                </div>
                            </div>
                            <div class="list-group m-2 ">
                                <a (click)="goToUserProfile();"
                                    class="list-group-item list-group-item-action border-0 pointer"><i
                                        class="icofont-graduate-alt fs-6 me-3"></i>User Profile</a>
                                <a (click)="changePassword();"
                                    class="list-group-item list-group-item-action border-0 pointer"><i
                                        class="icofont-refresh fs-6 me-3"></i>Change Password</a>
                                <a (click)="signout()"
                                    class="list-group-item list-group-item-action border-0 pointer"><i
                                        class="icofont-logout fs-6 me-3"></i>Signout</a>
                                <div>
                                    <hr class="dropdown-divider border-dark">
                                </div>
                                <!-- <a [routerLink]="['auth/sign-up']" class="list-group-item list-group-item-action border-0 "><i class="icofont-contact-add fs-5 me-3"></i>Add personal account</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- menu toggler -->
            <button *ngIf="!hideMenuButton" class="navbar-toggler p-0 border-0 menu-toggle order-3" type="button" data-bs-toggle="collapse"
                data-bs-target="#mainHeader" (click)="showMenuToggle()">
                <span class="fa fa-bars"></span>
            </button>

            <!-- main menu Search-->
            <!-- <div class="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 ">
                <div class="input-group flex-nowrap input-group-lg">
                    <button type="button" class="input-group-text" id="addon-wrapping"><i
                            class="fa fa-search"></i></button>
                    <input type="search" class="form-control" placeholder="Search" aria-label="search"
                        aria-describedby="addon-wrapping">
                    <button type="button" class="input-group-text add-member-top" id="addon-wrappingone"
                        data-bs-toggle="modal" data-bs-target="#addUser"><i class="fa fa-plus"></i></button>
                </div>
            </div> -->

        </div>
    </nav>
</div>

<ng-template #filterModal let-modal>
    <div class="modal-header modal-header__padding">
        <i class="icofont-close" (click)="closeFilter()"></i>
    </div>
    <div class="modal-body">
        <span>Level</span>
        <select [(ngModel)]="filter_level" (change)="changeLevelHandler()" [disabled]="dependentDetail"
            class="form-select pointer custom-select">
            <option value="">All</option>
            <option *ngFor="let level of levels" [value]="level['id']">{{
                level["name"]
                }}</option>
        </select>
        <!-- &nbsp;&nbsp; -->
        <span>Sub-Level</span>
        <select [(ngModel)]="filter_sublevel" (change)="changeSubLevelHandler()"
            class="form-select pointer custom-select" [disabled]="dependentDetail">
            <option value="">All</option>
            <option *ngFor="let sublevel of sublevels" [value]="sublevel['id']">{{
                sublevel["name"]
                }}</option>
        </select>
    </div>
</ng-template>