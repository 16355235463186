<div fxLayout="column wrap" fxLayoutAlign="space-around center">
  <div class="card-title" fxFlex="100">Session - {{courseObj.name}}</div>

  <div fxFlex="100" class="full-width">
    <form [formGroup]="form" (ngSubmit)="submitHandler()">
      <div class="form-group">
        <!-- <mat-form-field
          appearance="outline"
          class="form-field__extend full-width"
        > -->
        <mat-label>Session Day</mat-label>
        <select formControlName="day" class="form-select pointer">
          <option *ngFor="let day of days" [value]="day['id']">{{
            day["name"]
            }}</option>
        </select>
        <!-- </mat-form-field> -->
      </div>
      <br/>
      <!-- <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Online Session URL</mat-label>
          <input matInput type="text" formControlName="url" placeholder="Zoom / Google Meet / Etc. URLs" />
        </mat-form-field>
      </div> -->
      <div class="form-group">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="mat-form-field__90">
              <mat-label>Online Session Number</mat-label>
              <input matInput type="text" formControlName="meeting_number" placeholder="You may get this in zoom link" />
            </mat-form-field>
          </div>

          <div fxFlex="50">
            <mat-form-field appearance="outline" class="mat-form-field__90">
              <mat-label>Online Session Password</mat-label>
              <input matInput type="text" formControlName="meeting_password" placeholder="You may find this in invite participant" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="mat-form-field__90">
              <mat-label>Session Start Time</mat-label>
              <input matInput formControlName="start_time" type="time" placeholder="start time"
                (change)="calculateDuration()">
            </mat-form-field>
          </div>

          <div fxFlex="50">
            <mat-form-field appearance="outline" class="mat-form-field__90">
              <mat-label>Session End Time</mat-label>
              <input matInput formControlName="end_time" type="time" placeholder="end time"
                (change)="calculateDuration()">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div fxFlex fxLayoutAlign="space-around start">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="mat-form-field__90">
              <mat-label>Session Valid From</mat-label>
              <input formControlName="start_date" matInput type="date">
              <mat-hint>MM/DD/YYYY</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="mat-form-field__90">
              <mat-label>Session Valid To</mat-label>
              <input formControlName="due_date" matInput type="date">
              <mat-hint>MM/DD/YYYY</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <br/>

      <div class="form-group">
        <button type="submit" mat-raised-button class="full-width">
          {{ session_id ? "Update" : "Create" }}
        </button>
      </div>

    </form>
  </div>
</div>