import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CartRoutingModule } from './cart-routing.module';
import { CartListComponent } from './components/cart-list/cart-list.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CartProcessComponent } from './pages/cart-process/cart-process.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdminModule } from '../../admin/admin.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgProgressModule } from 'ngx-progressbar';
import { NgxLoadingModule } from "ngx-loading";
import { DependentListComponent } from "../cart/components/dependent-list/dependent-list.component";

@NgModule({
  declarations: [
    CartListComponent, 
    CartProcessComponent, 
    DependentListComponent
  ],
  imports: [
    CommonModule,
    CartRoutingModule,
    FlexLayoutModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatDialogModule,
    SharedModule,
    MatCheckboxModule,
    MatTableModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    AdminModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    NgxLoadingModule.forRoot({})
  ],
})
export class CartModule {}
