import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from 'src/app/modules/shared/components/alert/alert.component';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { CartHandlerService } from '../../services/cart-handler.service';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
})
export class CartListComponent implements OnInit {
  public carts: any[] = [];
  public checkedCount: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ownDialogRef: MatDialogRef<CartListComponent>,
    private cartService: CartService,
    private cartHandler: CartHandlerService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.carts = data.carts;
  }

  ngOnInit(): void {

  }

  calculateTotal() {
    let sumTotal = 0;
    this.carts.forEach((cart, index) => {
      if (this.cartHandler.isItemExist(cart.id)) {
        this.carts[index].isChecked = true;
      } else {
        this.carts[index].isChecked = false;
      }
      sumTotal = sumTotal + parseFloat(cart.fee);
    });
    return sumTotal;
  }

  removeFromCart(cart_id: number) {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        message: 'Confirm remove item from cart?',
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      let userDetail = <String>localStorage.getItem('userDetail');
      let requested_by = userDetail ? JSON.parse(userDetail.toString()) : null;

      if (result.data && result.data.confirm) {
        let prepareObj = {
          cart_id: cart_id,
        };

        this.cartService.removeCart(prepareObj).subscribe((data: any) => {
          this.sharedService.showSnackBar('Item removed');
          this.cartHandler.removeSelectedItems(cart_id);

          this.carts = this.carts.filter((cart) => {
            return cart.id != cart_id;
          });
        });
      }
    });
  }

  selectItem(cartObj: any, event: any) {
    // if (event.checked) {
    //   let isExist = this.selectedItems.filter((item) => {
    //     return item.id == cartObj.id;
    //   });

    //   if (isExist.length == 0) {
    //     this.selectedItems.push(cartObj);
    //   }
    // } else {
    //   this.selectedItems = this.selectedItems.filter((item) => {
    //     return item.id != cartObj.id;
    //   });
    // }

    if (event.target.checked) {
      this.cartHandler.setSelectedItems(cartObj);
      this.checkedCount++;
    } else {
      this.cartHandler.removeSelectedItems(cartObj.id);
      this.checkedCount--;
    }
  }

  proceedCheckout() {
    this.router.navigate(['cart/cart-process']);
    this.close(true);
  }

  close(data?: any) {
    this.ownDialogRef.close();
  }
}
