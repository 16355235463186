<div class="card shadow" *ngIf="currentTab != 'file'">
    <div class="lesson_name" [ngClass]="{
        'img-min-size': fileUpload.fileType == 'image',
        'vid-min-size': fileUpload.fileType == 'video'
    }">
        <div class="card-header d-flex p-2 justify-content-center align-items-center flex-column position-relative">
            <img [src]="fileUpload.filePath" *ngIf="fileUpload.fileType == 'image'" height="315" class="img-fluid" />
            <video width='100%' *ngIf="fileUpload.fileType == 'video'" [src]='fileUpload.filePath'
                controlsList="nodownload" controls loop muted playsinline></video>

            <button *ngIf="showDelete" (click)="confirmDeleteFileUpload(fileUpload)"
                class="btn bg-danger btn-sm text-white top-0 position-absolute end-0 rounded-0" title="delete-button"><i
                    class="icofont-ui-delete me-2"></i></button>
        </div>
        <div class="card-body">
        </div>
        <br /><br /><br />
        <div class="card-footer">
            <span>
                {{ trimFileName(fileUpload.fileName) }}
                <br />
                <span class="pill-mime">{{ reFormatMime(fileUpload.fileMime) }}</span>
            </span>
        </div>
    </div>
</div>
<div class="card shadow w-100">
    <div class="card-body d-flex align-items-center" [ngClass]="{
        'deposit': fileUpload?.filePath == 'deposit',
        'cashback': fileUpload?.filePath == 'cashback'
    }">
        <div class="profile-teacher text-center image-upload">
            <i class="icofont-file-pdf file-fixed-size pdf" *ngIf="fileUpload.fileType == 'pdf'"></i>
            <i class="icofont-file-word file-fixed-size word" *ngIf="fileUpload.fileType == 'word'"></i>
            <i class="icofont-file-excel file-fixed-size excel" *ngIf="fileUpload.fileType == 'excel'"></i>
            <i class="icofont-file-powerpoint file-fixed-size powerpoint"
                *ngIf="fileUpload.fileType == 'powerpoint'"></i>
            <i class="icofont-ui-file file-fixed-size others" *ngIf="fileUpload.fileType == 'others'"></i>
            <!-- <img class="" [src]="fileUpload.filePath" *ngIf="fileUpload.fileType == 'image' && fileUpload?.filePath" height="100" width="100" /> -->
            <img class="" src="assets/images/cashback.png" *ngIf="fileUpload?.filePath == 'cashback'" height="100" width="100" />
            <img class="" src="assets/images/deposit.png" *ngIf="fileUpload?.filePath == 'deposit'" height="100" width="100" />
        </div>
        <div class="teacher-info border-start ps-xl-4 ps-md-2 ps-sm-4 ps-4 pointer text-start w-100"
            (click)="sharedService.viewImage(fileUpload.filePath, fileUpload?.fileMime)">
            <span class="pill-global pill-pending" *ngIf="fileUpload.rechargeStatus == 'PENDING APPROVAL'">Pending
                Approval</span>
            <span class="pill-global pill-approved" *ngIf="fileUpload.rechargeStatus == 'APPROVED'">Approved</span>
            <span class="pill-global pill-rejected" *ngIf="fileUpload.rechargeStatus == 'REJECTED'">Rejected</span>
            <span class="pill-global pill-pending" *ngIf="fileUpload.rechargeStatus == 'TEMPORARY APPROVED'">Temporary
                Approved</span>
            <a class="mb-0 mt-2 fw-bold d-block fs-6">{{ fileUpload?.ReceiptNumber }} - RM {{ convertAbsoluteNumber(fileUpload?.rechargeAmount) }}</a>
            <a class="mb-0 mt-2 d-block fs-6">{{ fileUpload?.rechargeName }}</a>
            <!-- <a class="mb-0 mt-2 d-block fs-6">{{ fileUpload?.rechargeEmail }}</a> -->
            <a class="mb-0 mt-2 d-block fs-6">{{ fileUpload?.rechargePhoneNo }}</a>
            <a class="mb-0 mt-2 d-block fs-6">{{ convertDateTime(fileUpload?.rechargeDate) }}</a>
            <!-- <a class="mb-0 mt-2 d-block fs-6">RM {{ fileUpload?.rechargeAmount }}</a> -->

            <button (click)="changeStatusRecharge('approved', fileUpload?.fileId, $event)"
                *ngIf="(fileUpload.rechargeStatus == 'PENDING APPROVAL' || fileUpload.rechargeStatus == 'TEMPORARY APPROVED') && (currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN')"
                class="btn bg-success btn-sm text-white top-0 position-absolute end-0" style="right: 100px !important;
                display: flex;
                width: 100px;
                height: 100%;
                font-size: 50px;
                align-items: center;
                justify-content: center;" title="approve-button"><i
                    class="icofont-check-circled me-2 fs-12"></i></button>
            <button (click)="changeStatusRecharge('rejected', fileUpload?.fileId, $event)"
                *ngIf="(fileUpload.rechargeStatus == 'PENDING APPROVAL' || fileUpload.rechargeStatus == 'TEMPORARY APPROVED') && (currentUserRole == 'ADMIN' || currentUserRole == 'SUPER ADMIN')"
                class="btn bg-danger btn-sm text-white top-0 position-absolute end-0" style="
                display: flex;
                width: 100px;
                height: 100%;
                font-size: 50px;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;" title="reject-button"><i
                    class="icofont-close-circled me-2 fs-12"></i></button>
        </div>
    </div>
</div>