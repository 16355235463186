// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAd_IxBKQFNrkWtxiiovhGIgTqf_3tDSS8",
    authDomain: "pos-online-7b10d.firebaseapp.com",
    projectId: "pos-online-7b10d",
    storageBucket: "pos-online-7b10d.appspot.com",
    messagingSenderId: "67201891000",
    appId: "1:67201891000:web:0382c9f88a62fb5f07524d"
  },
  apiURL: "https://coral-app-4dzey.ondigitalocean.app",
  port: "8081",
  day: [null, "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],

  apiKey: 'GXrP_MJTTHu1tV9rdctGMQ',
  apiSecretKey: 'yHxiVK0LBIB5xUDt8Yk4WNLpMRSY0wojQNni',

  FPXPaymentURL: 'https://toyyibpay.com/',
  baseURL: 'https://www.nahrasolution.com/',
  paymentProcessURL: 'pages/process-payment'
};
