<div fxLayout="column wrap" fxLayoutAlign="space-around start">
  <ng-progress #progressBar></ng-progress>
  <div class="card-title" fxFlex="100" *ngIf="!fromProfile">User Registration</div>
  <div class="card-title" fxFlex="100" *ngIf="fromProfile">User Profile</div>

  <div fxFlex="100">
    <form [formGroup]="form" (ngSubmit)="submitHandler()">

      <!-- <div class="d-flex justify-content-center mb-5">
        <input hidden #fileInput type="file" id="file" (change)="selectFile($event)" accept="image/*" />
        <img [src]="profileImage" (click)="fileInput.click()" alt=""
          class="avatar xl rounded-circle img-thumbnail shadow-sm pointer">
      </div> -->
      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend">
          <mat-label>First Name</mat-label>
          <input matInput type="text" autocomplete="new-firstname" formControlName="name_first"
            placeholder="First Name" />
          <mat-error *ngIf="f['name_first'].errors">First name is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field__extend">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" autocomplete="new-lastname" formControlName="name_last" placeholder="Last Name" />
          <mat-error *ngIf="f['name_last'].errors">Last name is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="form-field__extend">
          <mat-label>Email</mat-label>
          <input matInput type="email" autocomplete="new-email" formControlName="email" placeholder="Email" />
          <mat-error *ngIf="f['email'].errors">Email is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-field__extend">
          <mat-label>Phone No</mat-label>
          <input matInput type="tel" autocomplete="new-phone" formControlName="phone_no" placeholder="Phone No" />
          <mat-error *ngIf="f['phone_no'].errors">Phone number is required</mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <div style="margin-bottom: 16px;
        margin-left: 5px;">
          <mat-label>School</mat-label>
          <select formControlName="school" class="form-select pointer" aria-placeholder="Choose School">
            <option value="">Select One</option>
            <option *ngFor="let school of schools" [value]="school['school_id']">
              {{ school['school_name'] }}
            </option>
          </select>
          <mat-error *ngIf="f['school'].errors">School is required</mat-error>
        </div>
      </div>

      <div class="form-group" *ngIf="data.user == null">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Password</mat-label>
          <input matInput type="password" autocomplete="new-plainpassword" formControlName="plain_password"
            placeholder="Password" />
          <mat-error *ngIf="f['plain_password'].errors">Password is required</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="data.user == null">
        <mat-form-field appearance="outline" class="form-field__extend full-width">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" autocomplete="new-confirmpassword" formControlName="confirm_password"
            placeholder="Password" />
          <mat-error *ngIf="f['confirm_password'].errors">Confirm Password is required</mat-error>
        </mat-form-field>
      </div>
      <!-- <h4 *ngIf="(fromLogin || (this.userType && this.userType == 4))">Passcode</h4>
      <div class="form-group custom-width" *ngIf="(fromLogin || (this.userType && this.userType == 4))">
        <div class="input-code-wrapper">
          <code-input [isCodeHidden]="true" [codeLength]="6" [isFocusingOnLastByClickIfFilled]="true"
            (codeChanged)="onCodeChanged($event)" (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>
      </div>
      <mat-error
        *ngIf="(fromLogin || (data && data.user_type && data.user_type == 4)) && f['passcode'].hasError('required') && isSubmitted == true">
        Passcode is required</mat-error>
      <mat-error
        *ngIf="(fromLogin || (data && data.user_type && data.user_type == 4)) && f['passcode'].hasError('minlength') && isSubmitted == true">
        Minimum passcode length is 6</mat-error> -->
      <div class="form-group" *ngIf="!fromLogin && data.user == null">
        <label id="radio-group-label">User Type</label>
        <div class="form-check" *ngFor="let type of userTypes">
          <input class="form-check-input" type="radio" autocomplete="new-usertype" name="exampleRadios"
            (click)="getRadioValue(type.id)" [value]="type.id" [checked]="(fromLogin && type.id == 4)">
          <label class="form-check-label" for="exampleRadios1">
            {{ type.name }}
          </label>
        </div>
      </div>
      <br />
      <div class="form-group" *ngIf="!fromLogin && userType == 3 && !hideContentManager">
        <div class="">
          <input formControlName="isContentManager" class="pointer" type="checkbox" /> &nbsp;&nbsp;Content Manager
        </div>
      </div>
      <br />

      <div class="form-group">
        <button type="submit" mat-raised-button class="full-width">
          {{ user_id ? 'Update' : 'Register'}}
        </button>
      </div>
    </form>
  </div>
</div>