import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {

  public alertMsg: any = '';
  public alertType: any = 'info';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AlertComponent>
  ) {
    this.alertMsg = data.message;
    this.alertType = data.type ? data.type : 'info';
  }

  ngOnInit(): void {}

  onYes() {
    this.close({ confirm: true });
  }

  onCancel() {
    this.close({ confirm: false });
  }

  close(data?: any) {
    this.dialogRef.close({ data: data });
  }
}
