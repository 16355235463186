import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BalanceReportRoutingModule } from './balance-report-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { SharedModule } from '../shared/shared.module';
import { BalanceReportListComponent } from './pages/balance-report-list/balance-report-list.component';
import { BalanceReportTableComponent } from './components/balance-report-table/balance-report-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CodeInputModule } from 'angular-code-input';
import { NgChartsModule } from 'ng2-charts';
import { AdminModule } from '../../admin/admin.module';
import { NgProgressModule } from 'ngx-progressbar';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NgxLoadingModule } from "ngx-loading";
import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  declarations: [
    BalanceReportListComponent,
    BalanceReportTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BalanceReportRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    SharedModule,
    MatTableModule,
    MatDividerModule,
    MatRadioModule,
    HttpClientModule,
    MatSnackBarModule,
    CodeInputModule,
    NgChartsModule,
    AdminModule,
    NgProgressModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableExporterModule,
    NgxLoadingModule.forRoot({})
  ],
})
export class BalanceReportModule {}
