import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/modules/shared/services/shared.service';
import { CourseDetailComponent } from '../course-detail/course-detail.component';

@Component({
  selector: 'course-filter',
  templateUrl: './course-filter.component.html',
  styleUrls: ['./course-filter.component.sass'],
})
export class CourseFilterComponent implements OnInit {
  @Output() onAddNewCourse = new EventEmitter<any>();

  filter_type: any = null;
  filter_subtype: any = null;
  filter_search: any = null;

  types: any[] = [];
  subtypes: any[] = [];

  constructor(
    private sharedService: SharedService, 
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getCourseTypes();
  }

  addNewCourse() {
    const dialogRef = this.dialog.open(CourseDetailComponent, {
      data: { },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onAddNewCourse.emit(result);
    });
  }

  async getCourseTypes() {
    await this.sharedService.getCourseTypes().subscribe((data) => {
      if (data && data.length > 0) {
        this.types = [...data[0]];
        this.subtypes = [...data[1]];
      }
    });
  }
  changeTypeHandler(level: any): void {
    this.filter_subtype = null;
    this.filter_type = level.id;
    // this.onChangeLevel.emit({filter_type: this.filter_type, filter_subtype: this.filter_subtype});
  }

  changeSubTypeHandler(sublevel: any): void {
    this.filter_subtype = sublevel.id;
    // this.onChangeLevel.emit({filter_type: this.filter_type, filter_subtype: this.filter_subtype});
  }
}
