<div>
  <mat-grid-list cols="3">
    <mat-grid-tile colspan="3" *ngIf="!course">
      Course filter not selected! Please select a course in the filter.
    </mat-grid-tile>
    <mat-grid-tile colspan="3" *ngIf="course">
      <table mat-table [dataSource]="sessionList" class="mat-elevation-z8">
        <ng-container matColumnDef="day">
          <th mat-header-cell *matHeaderCellDef>Day</th>
          <td mat-cell *matCellDef="let element">{{ showDay(element.day) }}</td>
        </ng-container>
      
        <ng-container matColumnDef="start_time">
          <th mat-header-cell *matHeaderCellDef>Start Time</th>
          <td mat-cell *matCellDef="let element">{{ convertTime(element.start_time) }}</td>
        </ng-container>
      
        <ng-container matColumnDef="end_time">
          <th mat-header-cell *matHeaderCellDef>End Time</th>
          <td mat-cell *matCellDef="let element">{{ convertTime(element.end_time) }}</td>
        </ng-container>
      
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef>Duration</th>
          <td mat-cell *matCellDef="let element">{{ getDuration(element.duration) }}</td>
        </ng-container>
      
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{ element.status_name }}</td>
        </ng-container>
      
        <ng-container matColumnDef="start_date">
          <th mat-header-cell *matHeaderCellDef>Valid From</th>
          <td mat-cell *matCellDef="let element">{{ convertDate(element.start_date) }}</td>
        </ng-container>

        <ng-container matColumnDef="due_date">
          <th mat-header-cell *matHeaderCellDef>Valid To</th>
          <td mat-cell *matCellDef="let element">{{ convertDate(element.due_date) }}</td>
        </ng-container>
      
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button  (click)="openSession(element)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button color="warn"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </mat-grid-tile>
    <!-- <mat-grid-tile colspan="1" *ngFor="let course of courses">
      <mat-card class="course-card">
        <mat-card-content class="card-container">
          <div class="card-title">
            <span class="card-title__title">{{ course.name }}</span>
          </div>
          <div class="card-content">
            <span class="card-content__desc">{{ course.description }}</span>
            <span class="card-content__teacher">{{ course.teacher_name }}</span>
          </div>
          <div class="card-footer" (click)="viewCourse(course)">
            <span>View Details&nbsp;&nbsp;&nbsp;</span><mat-icon>arrow_right_alt</mat-icon>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile> -->
  </mat-grid-list>
</div>
