import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentManagementComponent } from './pages/content-management/content-management.component';
import { VideoContentComponent } from './pages/video-content/video-content.component';
import { 
  AuthGuardService as AuthGuard 
} from '../../auth/auth-guard.service';

const routes: Routes = [
  { path: 'announcement', component: ContentManagementComponent, canActivate: [AuthGuard]},
  { path: 'content/video-content', component: VideoContentComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
