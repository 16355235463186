import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getAllCourse(requestObj?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/course`, requestObj, this.httpOptions);
  }
  
  saveCategory(courseObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-category`, courseObj, this.httpOptions);
  }

  getAllRechargeFileOrById(requestObj?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/recharge-file`, requestObj, this.httpOptions);
  }
  
  saveRechargeFile(rechargeObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-recharge-file`, rechargeObj, this.httpOptions);
  }

  removeCourseFile(courseObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/remove-course-file`, courseObj, this.httpOptions);
  }

  getAllCourseTeacherByCourseId(requestObj?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/course-teacher`, requestObj, this.httpOptions);
  }
  
  saveCourseTeacher(courseObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-course-teacher`, courseObj, this.httpOptions);
  }

  removeCourseTeacher(courseObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/remove-course-teacher`, courseObj, this.httpOptions);
  }

  getAllCourseByCourseTeacher(requestObj?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/course-teacher-package`, requestObj, this.httpOptions);
  }

  changeRechargeStatus(rechargeObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/change-recharge-status`, rechargeObj, this.httpOptions);
  }

  getRechargeTotal(requestObj?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/recharge-total`, requestObj, this.httpOptions);
  }

  getRechargeCalculation(requestObj?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/recharge-calculation`, requestObj, this.httpOptions);
  }

  getStatistic(requestObj?: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/statistic`, requestObj, this.httpOptions);
  }

  managePublishCategory(categoryObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/publish-category`, categoryObj, this.httpOptions);
  }
}
