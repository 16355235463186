<div fxLayout="column wrap" fxLayoutAlign="space-around center">
  <div class="card-title" fxFlex="100">New Announcement</div>
  <ng-progress #progressBar></ng-progress>
  <div fxFlex="100" class="full-width">
    <form [formGroup]="form">

      <div class="container">
        <div class="row">
          <!-- <div class="col-sm">
            <div class="bg-primary d-flex justify-content-center align-items-center flex-column position-relative"
              *ngIf="typeName == 'image' || typeName == 'general' || typeName == 'event'">
              <input hidden #fileInput type="file" id="file" (change)="selectFile($event)" accept="image/*" />
              <img [src]="contentFile" (click)="fileInput.click()" alt=""
                class="img-thumbnail pointer custom-height custom-content-img br-15"
                onerror="this.onerror=null;this.src='assets/images/default-image.png';">
              <button *ngIf="this.selectedFiles && this.selectedFiles.length > 0" (click)="removeFile(0)"
                class="btn bg-danger btn-sm text-white top-0 position-absolute end-0 rounded-0 br-15"><i
                  class="icofont-ui-close fs-4"></i></button>
              <button *ngIf="this.selectedFiles && this.selectedFiles.length == 0"
                class="btn btn-secondary btn-sm text-white top-0 position-absolute end-0 rounded-0 br-15"><i
                  class="icofont-upload-alt fs-4"></i>&nbsp;&nbsp;Click anywhere to upload image</button>
            </div>
            <div class="bg-primary d-flex justify-content-center align-items-center flex-column position-relative"
              *ngIf="typeName == 'video'">
              <input hidden #fileInput type="file" id="file" (change)="selectFile($event)" accept="video/*" />
              <video [src]="contentFile" (click)="fileInput.click()" alt=""
                class="img-thumbnail pointer custom-height custom-content-img br-15"
                onerror="this.onerror=null;this.src='assets/videos/default-video.mp4';"></video>
              <button *ngIf="this.selectedFiles && this.selectedFiles.length > 0" (click)="removeFile(0)"
                class="btn bg-danger btn-sm text-white top-0 position-absolute end-0 rounded-0 br-15"><i
                  class="icofont-ui-close fs-4"></i></button>
              <button *ngIf="this.selectedFiles && this.selectedFiles.length == 0"
                class="btn btn-secondary btn-sm text-white top-0 position-absolute end-0 rounded-0 br-15"><i
                  class="icofont-upload-alt fs-4"></i>&nbsp;&nbsp;Click anywhere to upload video</button>
            </div>
          </div> -->
          <div class="col-sm">
            <div class="form-group">
              <mat-form-field appearance="outline" class="form-field__extend full-width">
                <mat-label>Title</mat-label>
                <input matInput type="text" formControlName="contentName" placeholder="Content Name" />
                <mat-error *ngIf="f['contentName'].errors">Title is required</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="outline" class="form-field__extend full-width">
                <mat-label>Description</mat-label>
                <textarea matInput type="text" formControlName="contentDesc"
                  placeholder="Content description"></textarea>
                <mat-error *ngIf="f['contentDesc'].errors">Description is required</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="outline" class="form-field__extend full-width">
                <mat-label>Date</mat-label>
                <input formControlName="contentDate" matInput type="date">
                <mat-error *ngIf="f['contentDate'].errors">Date is required</mat-error>
              </mat-form-field>
            </div>

            <div class="form-group">
              <button (click)="submitHandler()" type="submit" mat-raised-button class="full-width">
                {{ contentId ? 'Update' : 'Add'}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <ng-template #customLoadingTemplate>
    <div class="custom-class">
      <h3>Saving...</h3>
    </div>
  </ng-template>
  <ngx-loading
    [show]="loading"
    [config]="{ backdropBorderRadius: '3px' }"
    [template]="customLoadingTemplate"
  ></ngx-loading>
</div>