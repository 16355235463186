<table mat-table [dataSource]="teacherList" class="mat-elevation-z8 full-width table">

  <ng-container matColumnDef="profile-image">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <img *ngIf="!element.profile_image_path" src="assets/images/default-profile.jpg" alt="" class="avatar lg rounded-circle img-thumbnail shadow-sm pointer">
      <img *ngIf="element.profile_image_path" [src]="element.profile_image_path" alt="" class="avatar lg rounded-circle img-thumbnail shadow-sm pointer">
    </td>
  </ng-container>
  
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element" class="left-align">{{ element.name_first.concat(' ', element.name_last) }}</td>
  </ng-container>
  
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element" class="left-align">{{ element.email }}</td>
  </ng-container>
  
  <ng-container matColumnDef="phone_no">
    <th mat-header-cell *matHeaderCellDef>Phone No</th>
    <td mat-cell *matCellDef="let element" class="left-align">{{ element.phone_no }}</td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="viewTeacher(element)"><i class="icofont-edit"></i></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>