import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FileUploadService } from '../../../file-upload/services/file-upload.service';
import { Subscription } from 'rxjs';
import { ContentService } from '../../services/content.service';
import { MatDialog } from '@angular/material/dialog';
import { ContentFormComponent } from '../../components/content-form/content-form.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent implements OnInit, OnDestroy {

  @Input() contentData: any = null;

  public fileContents: any = [];
  private subscriptionRefreshContent: Subscription;
  public selectedContentData: any = null;
  public currentTab: any = 'general';
  public fileContentFilter: any = [];
  public currentFileType: any = 'others';
  public typeName: any = 'general';

  public limit: number = 10;
  public offset: number = 0;
  public pageIndex: number = 0;
  public totalResult: number = 0;
  public pageSizeOptions = [10, 20, 50];

  constructor(
    private fileUploadService: FileUploadService,
    private contentService: ContentService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
  }
  ngOnInit(): void {
    this.fileUploadService.setSubscriptionRefreshContent(false);
    this.subscriptionRefreshContent = this.fileUploadService.getSubscriptionRefreshContent().subscribe((value: any)=> {
      if( value ){
        this.getContentByType();
      }
    }, (error : any) => {

    });

    this.getContentByType();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
    this.subscriptionRefreshContent.unsubscribe();
  }

  changeTab(tab: any = 'general') {
    this.currentTab = tab;
    this.typeName = tab;
    this.getContentByType();
  }

  getContentByType() {
    const contentObject = {
      typeName: this.typeName,
      limit: this.limit,
      offset: this.offset
    };

    this.contentService.getContent(contentObject).subscribe((response) => {
      this.fileContents = response.data.result;
      this.totalResult = response.data.totalResult;
      this.filterFileContents();
    }, (error) => {

    })
  }

  filterFileContents() {
    this.fileContentFilter = [];
    if (this.currentTab == 'general') {
      for (let i = 0; i < this.fileContents.length; i++) {
        if (this.fileContents[i].contentFileMime) {
          let splitArray = this.fileContents[i].contentFileMime.split('/');
          if (splitArray[0] == "image") {
            this.fileContents[i]['fileType'] = 'image';
            this.fileContentFilter.push(this.fileContents[i]);
          }
        } else {
          this.fileContents[i]['fileType'] = 'image';
          this.fileContentFilter.push(this.fileContents[i]);
        }
      }
    } else if (this.currentTab == 'video') {
      for (let i = 0; i < this.fileContents.length; i++) {
        if (this.fileContents[i].contentFileMime) {
          let splitArray = this.fileContents[i].contentFileMime.split('/');
        if (splitArray[0] == "video") {
          this.fileContents[i]['fileType'] = 'video';
          this.fileContentFilter.push(this.fileContents[i]);
        }
        } else {
          this.fileContents[i]['fileType'] = 'video';
          this.fileContentFilter.push(this.fileContents[i]);
        }
      }
    } else if (this.currentTab == 'image') {
      for (let i = 0; i < this.fileContents.length; i++) {

        if (this.fileContents[i].contentFileMime) {
          let splitArray = this.fileContents[i].contentFileMime.split('/');
          if (splitArray[0] == "image") {
            this.fileContents[i]['fileType'] = 'image';
            this.fileContentFilter.push(this.fileContents[i]);
          }
        } else {
          this.fileContents[i]['fileType'] = 'image';
          this.fileContentFilter.push(this.fileContents[i]);
        }

      }
    } else if (this.currentTab == 'event') {
      for (let i = 0; i < this.fileContents.length; i++) {
        if (this.fileContents[i].contentFileMime) {
          let splitArray = this.fileContents[i].contentFileMime.split('/');
          if (splitArray[0] == "image") {
            this.fileContents[i]['fileType'] = 'image';
            this.fileContentFilter.push(this.fileContents[i]);
          }
        } else {
          this.fileContents[i]['fileType'] = 'image';
          this.fileContentFilter.push(this.fileContents[i]);
        }
      }
    }
  }

  openContentForm() {
    const dialogRef = this.dialog.open(ContentFormComponent, {
      data: {
        typeName: this.typeName,
      },
      panelClass: 'dialog-panel'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(
        result &&
        result.data
      ){
        this.getContentByType();
      }
    });
  }

  handlePageEvent(event: PageEvent) {
    this.limit = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.offset = event.pageIndex*this.limit;
    console.log(event)
    this.getContentByType();
  }

  onManagePublishAnnouncement(result: any){
    this.getContentByType();
  }

  onDeleteAnnouncement(result: any){
    this.getContentByType();
  }
}