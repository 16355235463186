import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) { 
    
  }

  getContent(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/content`, requestObj, this.httpOptions);
  }

  manageContent(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/save-content`, requestObj, this.httpOptions);
  }

  removeContent(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/remove-content`, requestObj, this.httpOptions);
  }

  managePublishContent(requestObj: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/publish-content`, requestObj, this.httpOptions);
  }
}