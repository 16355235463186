<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div class="body d-flex py-lg-3 py-md-2">
          <div class="container-xxl">
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around start">
              <div class="custom-card content-container" fxFlex="100">
                <div class="content-area__main">
                  <div>
                    <package-filter (onAddPackageEvent)="savePackageHandler($event)"
                      (onChangeLevel)="changeLevelFilterHandler($event)"></package-filter>
                  </div>
                  <package-list [isStore]="false" [packages]="packages"
                    (onSavePackageEvent)="savePackageHandler($event)">
                  </package-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>
</body>