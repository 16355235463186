<body [attr.data-theme]="themeDarkClass" class="{{themeltrClass}}">

  <div id="elearn-layout" class="theme-black">
    <!-- sidebar -->
    <app-leftbar id='leftbar'></app-leftbar>

    <!-- main body area -->
    <div class="main px-lg-4 px-md-4">
      <!-- Body: Header -->
      <app-header></app-header>

      <!-- Body: Body -->
      <router-outlet>
        <div class="body d-flex py-lg-3 py-md-2">
          <div class="container-xxl">
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0 mb-4 no-bg">
                  <div class="card-header py-3 px-0 d-flex align-items-center  justify-content-between border-bottom">
                    <h3 class=" fw-bold flex-fill mb-0">Teachers</h3>
                    <!-- <div class="dropdown px-2">
                      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Subject
                      </button>
                      <ul class="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#">Engineering</a></li>
                        <li><a class="dropdown-item" href="#">Power and Energy</a></li>
                        <li><a class="dropdown-item" href="#">Civil Law</a></li>
                        <li><a class="dropdown-item" href="#">Legal Advice</a></li>
                        <li><a class="dropdown-item" href="#">Health and Fitness</a></li>
                        <li><a class="dropdown-item" href="#">Hospitality</a></li>
                        <li><a class="dropdown-item" href="#">Aviation</a></li>
                        <li><a class="dropdown-item" href="#">Drawing</a></li>
                      </ul>
                    </div>
                    <div class="dropdown">
                      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Status
                      </button>
                      <ul class="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#">All</a></li>
                        <li><a class="dropdown-item" href="#">online</a></li>
                        <li><a class="dropdown-item" href="#">Popular</a></li>
                      </ul>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-3 row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-deck py-3 fixed-height-overflow">
              <div class="col" *ngFor="let teacher of teachers">
                <div class="card teacher-card shadow">
                  <div class="card-body p-4 d-flex">
                    <div class="profile-teacher pe-xl-4 pe-md-2 pe-sm-4 text-center">
                      <a>
                        <img *ngIf="teacher.profile_image_path" [src]="teacher.profile_image_path" alt=""
                          class="avatar teacher shadow-sm pointer" (click)="sharedService.viewImage(teacher.profile_image_path)">
                          <img *ngIf="!teacher.profile_image_path" src="assets/images/default-profile.jpg" alt=""
                          class="avatar teacher shadow-sm">
                      </a>
                      <!-- <div class="about-info d-flex align-items-center mt-3 justify-content-center">
                        <div class="followers me-2">
                          <i class="icofont-users color-careys-pink"></i>
                          <span class="">55k</span>
                        </div>
                        <div class="star me-2">
                          <i class="icofont-star text-warning"></i>
                          <span class="">4.5</span>
                        </div>
                        <div class="own-video">
                          <i class="icofont-video color-light-orange"></i>
                          <span class="">10</span>
                        </div>
                      </div> -->
                    </div>
                    <div class="teacher-info border-start ps-xl-4 ps-md-2 ps-sm-4 ps-4 w-100">
                      <a (click)="showProfile(teacher)" class="mb-0 mt-2 fw-bold d-block fs-6 pointer">{{
                        teacher.name_first.concat(" ",teacher.name_last) }}</a>
                      <span class="text-uppercase small text-muted ">{{ teacher.subject }}</span>

                      <div class="video-setting-icon mt-3 pt-3 border-top">
                        <!-- <div class="btn-group" role="group" aria-label="Basic mixed styles example">
                          <button *ngIf="teacher.classificationName == 'premium'" type="button" class="btn btn-warning" disabled><i class="icofont-crown color-defult-white fs-5"></i></button> -->
                          <!-- <button type="button" class="btn btn-success"><i
                              class="icofont-video-cam color-defult-white"></i></button>
                          <button type="button" class="btn btn-danger"><i
                              class="icofont-user-alt-4 color-defult-white"></i></button> -->
                        <!-- </div> -->
                      </div>
                      <ul class="social mb-0 list-inline d-flex mt-3">
                        <!-- <li><a href="#" class="avatar btn btn-link"><i class="fa fa-facebook-f"></i></a></li>
                        <li><a href="#" class="avatar btn btn-link"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#" class="avatar btn btn-link"><i class="fa fa-instagram"></i></a></li>
                        <li><a href="#" class="avatar btn btn-link"><i class="fa fa-linkedin"></i></a></li> -->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </div>
</body>